import { useState, useRef } from "react";
import classNames from "classnames/bind";
import { Link, useLocation } from "react-router-dom";
import styles from "./assets/styles.scss";
import ImgTrophy from "./assets/images/races-trophy.png";
import useClickOutside from "src/helpers/useClickOutside";
import usePageScroll from "src/helpers/usePageScroll";
import useLocationChange from "src/helpers/useLocationChange";
import { useAppSelector } from "src/redux/reducer";
import { WalletProvider } from "src/core/wallet/wallet.model";
import { walletProviders } from "src/core/wallet/wallet.const";
import { useLogout } from "src/core/auth/hooks/useLogout";
import { useWalletProvider } from "src/core/wallet/hooks/useWalletProvider";
import { useUserProfile } from "src/core/auth/hooks/useUserProfile";
import AvatarImage from "src/components/avatar-image/AvatarImage";
import { useUserStatistics } from "src/containers/Account/hooks/useUserStatistics";
import Icon from "@pg/design-system/src/components/icons/Icon";
import { Path } from "src/core/paths/paths";
import { accountRoutes } from "src/containers/Account/Account";
import { Skeleton, SkeletonVariant } from "@pg/design-system";
import { useIsPartnersPage } from "src/core/affiliate/hooks/useIsPartnersPage";
import { cn } from "@ntropy/utils/src/cn";

interface IMyAccountProps {
    reverse?: boolean;
}

const cx = classNames.bind(styles);

export default function MyAccountMenu({ reverse = false }: IMyAccountProps) {
    const { pathname } = useLocation();
    const popupRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const walletName = useAppSelector(state => state.wallets.currentWallet?.name);

    const { nickname, avatar, isLoading } = useUserProfile();
    const [isOpen, setIsOpen] = useState(false);

    const [walletProvider] = useWalletProvider();
    const [statistics] = useUserStatistics();

    useClickOutside(() => {
        setIsOpen(false);
    }, [popupRef, buttonRef]);
    usePageScroll(() => setIsOpen(false));
    useLocationChange(() => setIsOpen(false));

    const logout = useLogout();

    const isPartnersPage = useIsPartnersPage();

    return (
        <>
            {isLoading ?
                <Skeleton
                    className={cn("h-12 md:h-[52px] w-12 md:w-[180px] rounded-full md:rounded-md ml-2", {
                        "bg-brand-primary-200": isPartnersPage,
                        "bg-brand-primary": !isPartnersPage,
                    })}
                    variant={isPartnersPage ? SkeletonVariant.Light : SkeletonVariant.Dark}
                /> :
                <button
                    type="button"
                    ref={buttonRef}
                    className={cx("account-button", {
                        "text-brand-primary bg-brand-primary-200 hover:bg-brand-primary-300": isPartnersPage,
                        "bg-brand-primary-500 text-brand-primary-100 hover:bg-support-purple-200": !isPartnersPage,
                        active: isOpen,
                    })}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <span className="min-w-28 font-bold">{nickname}</span>
                    <div className={cx("avatar-container")}>
                        <AvatarImage
                            className={cx("avatar")}
                            avatar={avatar}
                            isLoading={isLoading}
                        />
                        <div className={cx("wallet-icon")}>
                            <img src={walletProviders?.[walletProvider ?? walletName ?? WalletProvider.HandCash]?.iconRound} alt="Provider logo" />
                        </div>
                    </div>
                </button>
            }

            <div
                className={cx("account-popup", { open: isOpen, reverse })}
                ref={popupRef}
            >
                {walletProvider === WalletProvider.HandCash &&
                    <div className={cx("ranking", { reverse })}>
                        <span className={cx("heading", "bold", "small")}>
                            <img src={ImgTrophy} alt="trophy" />
                            My Daily Race
                        </span>
                        <div>
                            <div>
                                <span className={cx("small")}>Rank</span>
                                <span className={cx("small")}>{statistics?.daily?.ranking || "-"}</span>
                            </div>
                            <div>
                                <span className={cx("small")}>Points</span>
                                <span className={cx("small")}>{statistics?.daily ? statistics?.daily.point : "-"}</span>
                            </div>
                        </div>
                    </div>
                }
                <div className={cx("navigation")}>
                    {accountRoutes.map(({ path, label, icon }) => (
                        <Link
                            key={label}
                            className={cx({ active: pathname === path, reverse })}
                            to={path}
                        >
                            {icon}
                            {label}
                        </Link>
                    ))}
                    <Link to={Path.Partners} className={cx({ reverse })}>
                        <Icon.Referrals />
                        Referrals
                    </Link>
                    <button type="button" className={cx({ reverse })} onClick={logout}>
                        <Icon.Logout />
                        Sign out
                    </button>
                </div>
            </div>
        </>
    );
}