import { useRef } from "react";
import { useFirstMount } from "@ntropy/hooks/src/lifecycle/useFirstMount";
import { Predicate, strictEquals } from "@ntropy/utils";

export function usePreviousDistinct<T, A extends any[] = any[]>(
    value: T,
    compare: Predicate<T, A> = strictEquals as any,
    ...compareArgs: A
): T | undefined {
    const prevRef = useRef<T>();
    const curRef = useRef<T>(value);
    const isFirstMount = useFirstMount();

    if (!isFirstMount && !compare(curRef.current, value, ...compareArgs)) {
        prevRef.current = curRef.current;
        curRef.current = value;
    }

    return prevRef.current;
}