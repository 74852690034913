import { CallbackFn } from "@ntropy/utils";
import { useCallback } from "react";
import throttle from "lodash/throttle";
// eslint-disable-next-line no-restricted-imports
import type { DebouncedFunc, DebounceSettings } from "lodash";
import { useRefValue } from "@ntropy/hooks/src/state/useRefValue";

export type ThrottleSettings = DebounceSettings;

export function useThrottled<T extends any[] = any[], R = void>(
    fn: CallbackFn<T, R> | null | undefined,
    wait: number,
    options: ThrottleSettings = { leading: false, trailing: true },
): DebouncedFunc<CallbackFn<T, R>> {
    const callbackRef = useRefValue(fn);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const callback = useCallback((...args: T) => callbackRef.current?.(...args), []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(throttle(callback, wait, options), [wait]);
}