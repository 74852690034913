import { FC } from "react";
import * as Sentry from "@sentry/react";
import Crash from "src/containers/Crash/Crash";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { CookiesProvider } from "react-cookie";
import createStore from "src/redux/create";
import { RootState } from "src/redux/reducer";
import localForage from "localforage";
import router from "src/routes";
import { wagmiAdapter, ProjectWagmiProvider } from "src/core/wagmi/wagmi-config";
import { Provider as ReduxProvider } from "react-redux";
import { compose, withHOCProvider } from "@ntropy/utils/src/react-utils/compose-utils";
import { queryClient } from "src/core/query/query-client";
import { createPortal } from "react-dom";
import { RouterProvider } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

interface IRootProps {
    preloadedState: Partial<RootState>
    online: boolean
}

const persistConfig = {
    key: "hello", storage: localForage, stateReconciler(inboundState, originalState) {
        // Ignore state from cookies, only use preloadedState from window object
        return originalState;
    }, whitelist: [],
};

const Root: FC<IRootProps> = () => {
    return (
        <>
            {createPortal(<ReactQueryDevtools />, document.body)}
            <RouterProvider router={router} />
        </>
    );
};

export default compose<IRootProps>(
    Root,
    "Root",
    withHOCProvider(CookiesProvider),
    withHOCProvider(ReduxProvider, ({ preloadedState, online }: IRootProps) => {
        const store = createStore({
            data: {
                ...preloadedState,
                ...window.__data,
                online,
            }, persistConfig,
        });

        return { store };
    }),
    withHOCProvider(QueryClientProvider, () => ({ client: queryClient })),
    withHOCProvider(ProjectWagmiProvider, () => ({ config: wagmiAdapter.wagmiConfig })),
    withHOCProvider(HelmetProvider),
    withHOCProvider(Sentry.ErrorBoundary, () => ({ fallback: <Crash /> })),
);