import { useEffect, useState, useCallback } from "react";
import { useCookies } from "react-cookie";
import ageCheck from "assets/img/age-check.png";
import { COOKIE } from "src/utils/defines";
import { DialogContent, Dialog, ButtonVariant } from "@pg/design-system";
import { Button } from "@pg/design-system";

export function useIsAdult() {
    const [cookies, setCookies] = useCookies([COOKIE._ARE_YOU_ADULT]);
    const isAdult = cookies[COOKIE._ARE_YOU_ADULT] === true;

    const setIsAdult = useCallback(() => {
        setCookies(COOKIE._ARE_YOU_ADULT, true, { path: "/", maxAge: 31536000 });
    }, [setCookies])

    return [isAdult, setIsAdult] as const;
}

const AreYouAdult = () => {
    const [isAdult, setIsAdult] = useIsAdult();
    const [on, setOn] = useState(false);

    useEffect(() => {
        if ((window as any).__PRERENDER_INJECTED && (window as any).__PRERENDER_INJECTED.injected) {
            return;
        }

        setOn(!isAdult);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickYes = () => {
        setIsAdult();
        setOn(false);
    };

    const handleClickNo = () => {
        setOn(false);
    };

    return (
        <Dialog open={on} onOpenChange={setOn}>
            <DialogContent className="overflow-y-auto z-[300]">
                <div className="flex flex-col gap-8 items-center justify-center">
                    <div className="flex w-[200px] h-[200px]">
                        <img src={ageCheck} alt="Age Check Icon" className="w-full h-auto aspect-auto" />
                    </div>

                    <h3 className="font-bold">Are you 18 or older?</h3>

                    <div className="w-full flex justify-between items-center gap-4">
                        <Button variant={ButtonVariant.GHOST} onClick={handleClickNo} className="w-full text-accent-foreground">
                            No
                        </Button>
                        <Button type="button" onClick={handleClickYes} className="w-full">
                            Yes
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
};

export default AreYouAdult;
