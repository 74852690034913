import { Breakpoint, breakpointsMin } from "@pg/design-system/src/foundation/breakpoints/breakpoints.const";

export const getBreakpointMinWidth = (breakpoint: Breakpoint) => {
    return breakpointsMin[breakpoint];
}

const minValues = Object.values(breakpointsMin);

export const getBreakpointMaxWidth = (breakpoint: Breakpoint) => {
    const minIndex = minValues.indexOf(getBreakpointMinWidth(breakpoint));
    const nextMinValue = minValues[minIndex + 1];

    if (nextMinValue) return nextMinValue - 1;

    return Infinity;
}