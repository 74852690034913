import { FC } from "react";
import { ICurrencyAccount } from "src/core/currency/currency.model";
import { useConvertToUsd } from "src/core/currency/hooks/useConvertToUSD";
import CurrencyIcon from "src/components/currency-icon/CurrencyIcon";
import { formatCrypto, getCryptoCurrencyName, formatFiat } from "src/core/currency/currency.function";
import { cn } from "@ntropy/utils/src/cn";

const CurrencyListElement: FC<ICurrencyAccount & { alignValues?: "left" | "right" }> = ({
    currencyTicker, balance, alignValues = "right",
}) => {
    const usdValue = useConvertToUsd(balance, currencyTicker);

    return (
        <li className="flex gap-2 justify-start">
            <CurrencyIcon currency={currencyTicker} className="w-8" />
            <div className="flex flex-col w-full pb-0.5">
                <div className="flex flex-row justify-between w-full">
                    <p className="text-md">{currencyTicker}</p>
                    <p className={cn("text-md font-bold", { "w-[96px]": alignValues === "left" })}>{formatCrypto(balance)}</p>
                </div>

                <div className="flex flex-row justify-between w-full">
                    <span className="text-xs">{getCryptoCurrencyName(currencyTicker)}</span>
                    <span className={cn("text-xs", { "w-[96px]": alignValues === "left" })}>${formatFiat(usdValue) || "0.00"}</span>
                </div>
            </div>
        </li>
    )
}

export default CurrencyListElement;