import { RouteObject, useLocation, Outlet } from "react-router-dom";
import { Path } from "src/core/paths/paths";
import LazyPage from "src/core/router/components/LazyPage";

export const affiliateRoutes: RouteObject[] = [
    {
        path: Path.Partners,
        element: <LazyPage loader={() => import("src/containers/Affiliate/affiliate-landing/AffiliateLanding")} />,
    },
    {
        path: Path.PartnersLogin,
        element: <LazyPage loader={() => import("src/containers/Affiliate/login/Login")} />,
    },
    {
        path: Path.PartnersTermsAndConditions,
        element: <LazyPage loader={() => import("src/containers/Affiliate/affiliate-terms-and-conditions/AffiliateTermsAndConditions")} />,
    },
    {
        path: Path.PartnersRegistration,
        element: <LazyPage loader={() => import("src/containers/Affiliate/registration/Registration")} />,
    },
    {
        path: Path.PartnersVerifyEmail,
        element: <LazyPage loader={() => import("src/containers/Affiliate/email-verification/EmailVerification")} />,
    },
    {
        path: Path.PartnersDashboard,
        element: <LazyPage loader={() => import("src/containers/Affiliate/dashboard/Dashboard")} />,
    },
    {
        path: Path.PartnersCampaigns,
        element: <LazyPage loader={() => import("src/containers/Affiliate/campaigns/campaigns")} />,
    },
    {
        path: Path.PartnersCommissionPlans,
        element: <LazyPage loader={() => import("src/containers/Affiliate/commission-plans/CommissionPlans")} />,
    },
    {
        path: Path.PartnersReferredUsers,
        element: <LazyPage loader={() => import("src/containers/Affiliate/referred-users/ReferredUsers")} />,
    },
    {
        path: Path.PartnersTransactions,
        element: <LazyPage loader={() => import("src/containers/Affiliate/transactions/Transactions")} />,
    },
    {
        path: Path.PartnersResources,
        element: <LazyPage loader={() => import("src/containers/Affiliate/resources/Resources")} />,
    },
    {
        path: Path.PartnersAccountSettings,
        element: <LazyPage loader={() => import("src/containers/Affiliate/account-settings/AccountSettings")} />,
    },
];

export function Affiliate() {
    const { pathname } = useLocation();

    if (!pathname.startsWith(Path.Partners)) {
        return null;
    }

    return <Outlet />;
}
