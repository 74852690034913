import { ChevronRight } from "lucide-react";
import { Link } from "react-router-dom";
import { Button, ButtonVariant } from "@pg/design-system";

const iconSize = 16;

export const AboutCallToAction = () => (
    <Button asChild variant={ButtonVariant.GHOST} callToAction className="gap-2">
        <Link to="/about-us" className="font-bold text-brand-primary-300 -ml-4">
            Read More
            <ChevronRight width={iconSize} height={iconSize} />
        </Link>
    </Button>
)