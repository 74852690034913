export const Path = {
    Home: "/",
    Games: "/games",
    GamesCategory: "/games/:category",
    GamesCategoryWithId: "/games/:category/:identifier",
    InHouse: "/games/inhouse",
    Originals: "/games/originals",
    Slots: "/games/slots",
    SlotsWithId: "/games/slots/:identifier",
    Crash: "/games/crash",
    Table: "/games/table",
    TableWithId: "/games/table/:identifier",
    Casual: "/games/casual",
    CasualWithId: "/games/casual/:identifier",
    Races: "/races",
    Blackjack: "/games/blackjack",
    Dice: "/games/dice",
    Wheel: "/games/wheel",
    Roulette: "/games/roulette",
    CoinFlip: "/games/coin-flip",
    Baccarat: "/games/baccarat",
    Ladder: "/games/ladder",
    TurtleRace: "/games/turtlerace",
    CoinBattles: "/games/coinbattles",
    ProvablyFair: "/provably-fair",
    Bsv: "/bitcoinsv",
    Payments: "/payments",
    ResponsibleGaming: "/responsible-gaming",
    AboutUs: "/about-us",
    FairnessCalculation: "/fairness-calculation",
    PrivacyPolicy: "/privacy-policy",
    TermsOfService: "/terms-of-service",
    Complaints: "/complaints",
    Cookies: "/cookies",
    KycPolicy: "/kyc-policy",
    Dashboard: "/dashboard",
    Account: "/account",
    AccountHistory: "/account/history",
    AccountTransactions: "/account/transactions",
    Partners: "/partners",
    PartnersLogin: "/partners/login",
    PartnersTermsAndConditions: "/partners/terms-and-conditions",
    PartnersRegistration: "/partners/registration",
    PartnersVerifyEmail: "/partners/verify-email",
    PartnersDashboard: "/partners/dashboard",
    PartnersCampaigns: "/partners/campaigns",
    PartnersCommissionPlans: "/partners/commission-plans",
    PartnersReferredUsers: "/partners/referred-users",
    PartnersTransactions: "/partners/transactions",
    PartnersResources: "/partners/resources",
    PartnersAccountSettings: "/partners/account-settings",
} as const;

export type Path = (typeof Path)[keyof typeof Path];
