import ImgTurtleYellow from "./assets/turtle-yellow.svg";
import ImgTurtleRed from "./assets/turtle-red.svg";
import ImgTurtleBlue from "./assets/turtle-blue.svg";

export const turtleOptions = {
    Y: {
        label: "Yellow",
        image: ImgTurtleYellow,
    },
    R: {
        label: "Red",
        image: ImgTurtleRed,
    },
    B: {
        label: "Blue",
        image: ImgTurtleBlue,
    },
};
