import { Dispatch, MutableRefObject, SetStateAction, useCallback, useRef, useState } from "react";

export function useStateRef<S>(initial: S | (() => S)): [S, Dispatch<SetStateAction<S>>, MutableRefObject<S>] {
    const [state, setState] = useState(initial);
    const ref = useRef(state);

    const dispatch: Dispatch<SetStateAction<S>> = useCallback((val) => {
        ref.current = typeof val === "function" ? (val as (prevState: S) => S)(ref.current) : val;

        setState(ref.current);
    }, []);

    return [state, dispatch, ref];
}
