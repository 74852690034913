import classNames from "classnames/bind";
import InfiniteScroll from "react-infinite-scroller";
import styles from "./styles.scss";
import { ReactNode, ComponentPropsWithoutRef } from "react";
import { WithRequiredKeys } from "@ntropy/utils/src/typescript-helpers";
import noop from "lodash/noop";
import { PulseLoader } from "@pg/design-system";

export interface ITableColumn {
    label: string
    hideMobile?: boolean
    hide?: boolean
    className?: string
}

interface ITableWrapperProps {
    children: ReactNode;
    columns: ITableColumn[];
    className?: string;
    isLoading: boolean;
    infiniteScrollProps?: WithRequiredKeys<ComponentPropsWithoutRef<typeof InfiniteScroll>, "loadMore" | "hasMore">
    emptyPlaceholder?: ReactNode;
}

const cx = classNames.bind(styles);
export default function TableWrapper({
    className = "",
    columns,
    isLoading = false,
    children,
    infiniteScrollProps,
    emptyPlaceholder = "",
}: ITableWrapperProps) {
    function getBodyContent() {
        if (isLoading) {
            return (
                <tr className={cx("message")}>
                    <td colSpan={12}>
                        <PulseLoader />
                    </td>
                </tr>
            );
        }
        if (emptyPlaceholder) {
            return (
                <tr className={cx("message")}>
                    <td colSpan={12}>{emptyPlaceholder}</td>
                </tr>
            );
        }

        return children;
    }

    return (
        <div className={cx("table-wrapper", className)}>
            <InfiniteScroll initialLoad={false} useWindow={false} element="table" threshold={400} loadMore={noop} {...infiniteScrollProps}>
                <thead>
                <tr className={cx("xsmall")}>
                    {columns.map(
                        ({
                            label,
                            hideMobile,
                            hide,
                            className,
                        }) => !hide && (
                            <th key={label.toLowerCase()} className={cx({ "hide-mobile": hideMobile }, className)}>
                                {label}
                            </th>
                        ),
                    )}
                </tr>
                </thead>
                <tbody>{getBodyContent()}</tbody>
            </InfiniteScroll>
        </div>
    );
}