import { isCryptoCurrency, CryptoCurrency } from "src/core/currency/currency.model";
import CurrencyIcon from "src/components/currency-icon/CurrencyIcon";
import { cn } from "@ntropy/utils/src/cn";

type AvatarAndTextVariant = "primary" | "secondary";

interface IAvatarAndTextProps {
    icon: string | CryptoCurrency;
    content: string | number;
    iconPlacement?: "left" | "right";
    variant?: AvatarAndTextVariant;
    textColor?: string;
    className?: string;
}

export const AvatarAndText = ({
    content,
    icon,
    iconPlacement = "left",
    variant = "primary",
    textColor,
    className,
}: IAvatarAndTextProps) => {
    const variantStyles: Record<AvatarAndTextVariant, string> = {
        primary: "font-light text-brand-white text-xs",
        secondary: "support-purple-100",
    }

    let image: React.ReactElement;
    const iconSize = "w-[22px] h-[22px]"

    if (isCryptoCurrency(icon)) {
        image = (
            <CurrencyIcon currency={icon} className={cn(iconSize, "shrink-0")} />
        )
    } else {

        image = (
            <div className={cn("relative rounded-full overflow-hidden shrink-0", iconSize)}>
                <img src={icon} alt={`${content} avatar`} className="absolute top-0 left-0 w-full h-auto object-cover object-center" />
            </div>
        )
    }

    return (
        <div className={cn("flex gap-2 items-center w-full", className)}>
            {iconPlacement === "left" && image}
            <p className={cn(variantStyles[variant], textColor)}>{content}</p>
            {iconPlacement === "right" && image}
        </div>
    )
}