import { useEffect } from "react";

export function useRemovePlaceholder() {
    useEffect(() => {
        if (!window.__PRERENDER_INJECTED) {
            const loadPlaceholder = document.getElementById("initial-load-placeholder");

            loadPlaceholder?.parentElement?.removeChild(loadPlaceholder);
        }
    }, []);
}