import ImgCoinPurple from "./assets/coin-purple.png";
import ImgCoinRed from "./assets/coin-red.png";

export const coinSides = {
    H: {
        label: "Heads",
        image: ImgCoinPurple,
    },
    T: {
        label: "Tails",
        image: ImgCoinRed,
    },
};
