import { useLgUp } from "src/helpers/responsive";
import { useHandleGames } from "src/containers/Landing/hooks/useHandleGames";
import { useState } from "react";
import { CasinoGameCategory } from "src/core/casino/casino.model";
import { gameSections } from "src/containers/Landing/constants/gameSections";
import { SectionHeading } from "src/containers/Landing/components/section-heading/SectionHeading";
import { ToggleGroup, ToggleGroupItem, CarouselItem } from "@pg/design-system";
import { BasicCarousel } from "src/components/basic-carousel/BasicCarousel";
import { SkeletonCard } from "src/components/skeleton-card/SkeletonCard";

export const RecommendedGames = () => {
    const isLgUp = useLgUp();
    // TODO: Use correct endpoint when it'll be ready
    const { getGamesThumbnail, isLoading } = useHandleGames();

    const [selectedCategory, setSelectedCategory] = useState(CasinoGameCategory.Originals)

    const currentGameSection = gameSections.find(({ category }) => selectedCategory === category);

    return (
        <section>
            <SectionHeading
                title="Recommended for you"
            />

            <div className="flex items-center my-4 gap-4 border-b border-brand-primary-300 py-2">
                <ToggleGroup type="single">
                    {gameSections.map(({ title, category }) => (
                        <ToggleGroupItem
                            data-state={category === selectedCategory ? "on" : "off"}
                            aria-checked={category === selectedCategory}
                            key={category}
                            value={category}
                            aria-label={`Toggle ${category}`}
                            onClick={() => setSelectedCategory(category)}
                        >
                            {title}
                        </ToggleGroupItem>
                    ))}
                </ToggleGroup>
            </div>

            <BasicCarousel seeAllLink={currentGameSection?.path}>
                {isLoading ? Array.from({ length: currentGameSection?.limitSize(isLgUp) || 10 }).map((_, i) => (
                    <CarouselItem key={i} className="min-w-[204px] sm:min-w-[230px]">
                        <SkeletonCard />
                    </CarouselItem>
                )) : getGamesThumbnail(selectedCategory)}
            </BasicCarousel>
        </section>
    )
}