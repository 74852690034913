import useAppStore from "src/useAppStore";
import { useState, useCallback } from "react";
import { usePrevious } from "@ntropy/hooks";
import { CryptoCurrency } from "src/core/currency/currency.model";

export function useSelectedOrFormCurrency() {
    const selectedCurrency = useAppStore(store => store.asset.selected);
    const [currency, setCurrency] = useState(selectedCurrency);

    const prevSelectedCurrency = usePrevious(selectedCurrency);
    const selectedCurrencyChanged = prevSelectedCurrency !== selectedCurrency && currency === prevSelectedCurrency;
    const selectedCurrencySet = !currency && prevSelectedCurrency !== selectedCurrency;

    const handleSetCurrency = useCallback((currency: CryptoCurrency) => {
        if (!currency) {
            return;
        }

        setCurrency(currency);
    }, [])

    if (selectedCurrencyChanged || selectedCurrencySet) {
        handleSetCurrency(selectedCurrency);
    }

    return [currency, handleSetCurrency] as const;
}