import {
    createStore as _createStore,
    applyMiddleware,
    compose,
    combineReducers,
    Reducer,
    Store,
    AnyAction,
} from "redux";
import { createPersistoid, PersistConfig, persistCombineReducers } from "redux-persist";
import clientMiddleware from "./middleware/clientMiddleware";
import { REGISTER } from "redux-persist/es/constants";
import createReducers from "src/redux/reducer";

// Define types for the Redux store and persistConfig
interface CreateStoreParams {
    data?: Record<string, any>;  // You may want to refine this type based on your specific data structure
    persistConfig?: PersistConfig<any>; // Type the persistConfig with appropriate state shape
}

export interface ReduxStore extends Store {
    asyncReducers: Record<string, Reducer<any, AnyAction>>;
    inject: (reducers: Record<string, Reducer<any, AnyAction>>) => void;
}

const combine = (reducers: Record<string, Reducer<any, AnyAction>>, persistConfig?: PersistConfig<any>) => (
    persistConfig ? persistCombineReducers(persistConfig, reducers) : combineReducers(reducers)
);

const injectReducers = (store: ReduxStore, reducers: Record<string, Reducer<any, AnyAction>>, persistConfig?: PersistConfig<any>) => {
    Object.entries(reducers).forEach(([name, reducer]) => {
        if (!store.asyncReducers[name]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            store.asyncReducers[name] = reducer.__esModule ? reducer.default : reducer;
        }
    });
    store.replaceReducer(combine(createReducers(store.asyncReducers), persistConfig));
};

const getNoopReducers = (reducers: Record<string, Reducer<any, AnyAction>>, data?: Record<string, any>) => (
    data ? Object.keys(data).reduce((acc, key) => {
        if (!reducers[key]) {
            acc[key] = (state = data[key]) => state;
        }
        return acc;
    }, {} as Record<string, Reducer<any, AnyAction>>) : {}
);

export default function createStore({ data, persistConfig }: CreateStoreParams): ReduxStore {
    const middleware = [clientMiddleware()];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (typeof __CLIENT__ !== "undefined" && __CLIENT__ && typeof __DEVELOPMENT__ !== "undefined" && __DEVELOPMENT__) {
        const logger = require("redux-logger").createLogger({ collapsed: true });
        middleware.push(logger.__esModule ? logger.default : logger);
    }

    const enhancers = [applyMiddleware(...middleware)];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (typeof __CLIENT__ !== "undefined" && __CLIENT__ && typeof __DEVTOOLS__ !== "undefined" && __DEVTOOLS__ && window.__REDUX_DEVTOOLS_EXTENSION__) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const finalCreateStore = compose(...enhancers)(_createStore);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const reducers = createReducers();
    const noopReducers = getNoopReducers(reducers, data);
    const rootReducer = combine({ ...noopReducers, ...reducers }, persistConfig);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const store = finalCreateStore(rootReducer, data) as ReduxStore;

    store.asyncReducers = {};
    store.inject = reducers => injectReducers(store, reducers, persistConfig);

    if (persistConfig) {
        const persistoid = createPersistoid(persistConfig);
        store.subscribe(() => persistoid.update(store.getState()));
        store.dispatch({ type: REGISTER });
    }

    return store;
}
