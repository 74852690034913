import { useSignMessage, useAccount } from "wagmi";
import { useState, useRef } from "react";
import { useGetChallenge } from "src/core/wallet/hooks/useGetChallenge";
import { useVerifyChallenge } from "src/core/wallet/hooks/useVerifyChallenge";
import { useAsync, useValue } from "@ntropy/hooks";
import { WalletProvider } from "src/core/wallet/wallet.model";
import { useSessionToken } from "src/core/auth/hooks/useSessionToken";
import { useAuthorizedState } from "src/core/auth/hooks/useAuthorizedState";

interface IUseAuthorizeWalletConnectInput {
    onAuthorizeStart(): void
    onAuthorizeFinish(): void
}

export function useAuthorizeWalletConnect({
    onAuthorizeStart,
    onAuthorizeFinish,
}: IUseAuthorizeWalletConnectInput) {
    const [isFlowError, setFlowError] = useState(false);

    const {isAuthorizing} = useAuthorizedState(WalletProvider.WalletConnect);

    // Local storage
    const [_, setToken] = useSessionToken();

    // Wagmi
    const { address } = useAccount();
    const { signMessageAsync, isError: isHashedWalletConnectKeyError, error } = useSignMessage();

    // Cashier
    const { challenge, isError: isChallengeError } = useGetChallenge(address, {
        enabled: isAuthorizing && !!address,
        onGetChallengeStart: () => {
            setFlowError(false);
            onAuthorizeStart();
        },
    });
    const { verifyChallenge, isError: isVerifyChallengeError } = useVerifyChallenge()

    const isError = isHashedWalletConnectKeyError || isVerifyChallengeError || isFlowError || isChallengeError;

    const authInProgress = useRef(false);

    const handleCashierAuthentication = async () => {
        if (!address || !challenge || authInProgress.current) {
            console.error("No address or challenge", { address, challenge });
            // throw an error that sth is wrong
            return
        }

        authInProgress.current = true;

        let hashedWalletConnectKey: `0x${string}`;

        try {
            hashedWalletConnectKey = await signMessageAsync({ message: challenge })

        } catch (e) {
            console.error("Error while signing message", e)
            authInProgress.current = false;
            // setFlowError(true);
            return;
        }

        try {
            const { token } = await verifyChallenge({ challengeSignature: hashedWalletConnectKey, walletAddress: address })
            setToken(token);
            onAuthorizeFinish();
        } catch (e) {
            console.error("Error while verifying address", e)
            authInProgress.current = false;
            setFlowError(true);
        }

        authInProgress.current = false;
    }

    useAsync(async () => {
        if (isAuthorizing && !!address && !!challenge) {
            await handleCashierAuthentication()
        }
    }, [address, challenge, isAuthorizing])

    if (isError) {
        console.error("Error in useAuthorizeWalletConnect", {
            isHashedWalletConnectKeyError, isVerifyChallengeError, isFlowError, isChallengeError,
            signError: error,
        })
    }

    return {
        isError,
        message: useValue(() => {
            if (isChallengeError) {
                return "Problem when receiving data."
            }

            if (isHashedWalletConnectKeyError) {
                return "Could not receive your signature."
            }

            if (isVerifyChallengeError) {
                return "Could not verify your signature."
            }

            if (isFlowError) {
                return "Something went wrong."
            }
        }),
    }
}