import { CryptoCurrency, CryptoCurrencyName } from "src/core/currency/currency.model";
import BigNumber from "bignumber.js";
import { isSet } from "@ntropy/utils/src/type-utils";
import { CRYPTO_DECIMAL, FIAT_DECIMAL } from "src/core/currency/currency.const";

export const getCryptoCurrencyName = (currency: CryptoCurrency): CryptoCurrencyName => {
    return CryptoCurrencyName[currency];
}

export const formatCrypto = (value: BigNumber | number | null | undefined) => {
    if (!isSet(value)) {
        return "";
    }

    return value.toFixed(CRYPTO_DECIMAL);
}

export const formatFiat = (value: BigNumber | number | null | undefined, decimal: boolean = true) => {
    if (!isSet(value)) {
        return "";
    }

    return value.toFixed(decimal ? FIAT_DECIMAL : 0);
}

export function roundCrypto(value: BigNumber | number): number;
export function roundCrypto(value: BigNumber | number | null | undefined): number | null;
export function roundCrypto(value: BigNumber | number | null | undefined): number | null {
    if (!isSet(value)) {
        return null;
    }

    return Number(value.toFixed(CRYPTO_DECIMAL));
}

export function roundFiat(value: BigNumber | number, decimal?: boolean): number;
export function roundFiat(value: BigNumber | number | null | undefined, decimal?: boolean): number | null;
export function roundFiat(value: BigNumber | number | null | undefined, decimal: boolean = true): number | null {
    if (!isSet(value)) {
        return null;
    }

    return Number(value.toFixed(decimal ? FIAT_DECIMAL : 0));
}