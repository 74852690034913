import { Headset } from "lucide-react";
import { Button, ButtonVariant } from "@pg/design-system";

const iconSize = 16;

export const SupportCallToAction = () => (
    <Button asChild variant={ButtonVariant.OUTLINE} className="text-brand-white gap-2" callToAction>
        <a
            href="https://help.peergame.com/"
            className="w-full md:w-52 border-input whitespace-nowrap text-sm font-bold ring-offset-background"
        >
            <Headset width={iconSize} height={iconSize} />
            Help Center
        </a>
    </Button>
)