import React, { useRef, useState, useEffect, useCallback } from "react";
import classNames from "classnames/bind";
import styles from "src/components/games-list/assets/styles.scss";

const cx = classNames.bind(styles);

interface IVirtualizedGridProps<T> {
    items: T[];
    renderItem: (item: T) => React.ReactNode;
    batchSize?: number;
    isFetchingMore?: boolean;
    getSkeletonsCallback?: () => React.ReactNode;
}

const VirtualizedGrid = <T, >({ items, renderItem, batchSize = 20, isFetchingMore, getSkeletonsCallback }: IVirtualizedGridProps<T>) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const loaderRef = useRef<HTMLDivElement | null>(null);

    const [visibleItems, setVisibleItems] = useState(batchSize);

    const handleObserver = useCallback(
        (entries: IntersectionObserverEntry[]) => {
            const target = entries[0];
            if (target.isIntersecting) {
                setVisibleItems(prev => Math.min(prev + batchSize, items.length));
            }
        },
        [batchSize, items.length]
    );

    useEffect(() => {
        const observer = new IntersectionObserver(handleObserver, {
            root: null,
            rootMargin: "0px",
            threshold: 1.0,
        });

        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => {
            if (loaderRef.current) {
                observer.unobserve(loaderRef.current);
            }
        };
    }, [handleObserver]);

    return (
        <div
            ref={containerRef}
            className={cx("games")}
        >
            {items.slice(0, visibleItems).map((item, index) => (
                <div key={index} className="h-auto">
                    {renderItem(item)}
                </div>
            ))}

            {isFetchingMore && getSkeletonsCallback && getSkeletonsCallback()}

            <div ref={loaderRef} className="h-5" />
        </div>
    );
};

export default VirtualizedGrid;