import { FC } from "react";
import { CryptoCurrency } from "src/core/currency/currency.model";
import { icons } from "src/components/currency-icon/currency-icon.const";

interface ICurrencyIconProps {
    currency: CryptoCurrency
    className?: string
}

const CurrencyIcon: FC<ICurrencyIconProps> = ({
    currency,
    className,
}) => {
    if (!currency) {
        return null;
    }

    if (!icons[currency]) {
        console.error("No icon for currency", currency);
        return null;
    }

    return icons[currency]({ className });
};

export default CurrencyIcon;