import { useCallback, MouseEvent } from "react";
import { useStateAsync } from "@ntropy/hooks/src/state/useStateAsync";
import { useRefValue } from "@ntropy/hooks/src/state/useRefValue";
import { delayFor } from "@ntropy/utils/src/promise-utils";
import { isSet } from "@ntropy/utils/src/type-utils";

interface IUseClipboardCopyInput {
    buttonOnClick?(event?: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLDivElement>): void,
    value?: string,
    copyLockDelay?: number
    onCopy?(value: string): void | Promise<void>
}

interface IUseClipboardCopyOutput {
    isCopyingLocked: boolean,
    handleCopy(event: string): Promise<void>
    handleCopy(event: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLDivElement>): Promise<void>
}

async function copyToClipboard(textToCopy: string) {
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard) {
        await navigator.clipboard.writeText(textToCopy);
    } else {
        const polyfill = await import(/* webpackChunkName: 'clipboard-polyfill' */ "clipboard-polyfill");

        await polyfill.writeText(textToCopy);
    }
}

export const useClipboardCopy = ({
    buttonOnClick,
    value,
    onCopy,
    copyLockDelay = 3000,
}: IUseClipboardCopyInput = {}): IUseClipboardCopyOutput => {
    const [isCopyingLocked, setIsCopyingLocked] = useStateAsync(false);

    const onCopyRef = useRefValue(onCopy)

    const handleCopy = useCallback(async (eventOrValue: string | MouseEvent<HTMLButtonElement> | MouseEvent<HTMLDivElement>) => {
        const event = typeof eventOrValue === "string" ? undefined : eventOrValue;
        const valueToUse = typeof eventOrValue === "string" ? eventOrValue : value;

        event?.stopPropagation();

        if (isCopyingLocked || !isSet(valueToUse)) {
            return;
        }

        if (buttonOnClick) {
            buttonOnClick(event);
        }

        await setIsCopyingLocked(true);
        await copyToClipboard(valueToUse);
        await onCopyRef.current?.(valueToUse);
        await delayFor(copyLockDelay);
        await setIsCopyingLocked(false)
    }, [isCopyingLocked, buttonOnClick, setIsCopyingLocked, value, onCopyRef, copyLockDelay]);

    return {
        handleCopy,
        isCopyingLocked,
    }
}