import useAppStore from "src/useAppStore";
import { useLocalStorage } from "src/core/local-storage/hooks/useLocalStorage";
import { LocalStorageKey } from "src/core/local-storage/local-storage.model";
import { useEffect } from "react";

export function useCurrencyAssetState() {
    const { selected, balance, isLoading } = useAppStore(state => state.asset);
    const [savedAsset, setSavedAsset] = useLocalStorage(LocalStorageKey.CurrencyAsset, selected);

    useEffect(() => {
        if (savedAsset === selected) {
            return;
        }

        useAppStore.getState().setSelectedAsset(savedAsset)

        // if (!ableToSet) {
        //     setSavedAsset(selected);
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedAsset, selected]);

    return {
        selectedAsset: selected,
        balance,
        isLoading,
        setSelectedAsset: setSavedAsset,
    }
}