import { createPortal } from "react-dom";
import classNames from "classnames/bind";
import styles from "./styles.scss";

interface IBackdropProps {
    visible: boolean,
    onClick: () => void
}

const cx = classNames.bind(styles);

export default function Backdrop({ visible, onClick }: IBackdropProps) {
    return createPortal(
        <div className={cx("backdrop", { visible })} onClick={onClick} />,
        document.getElementsByTagName("body")[0],
    );
}
