import { useRef, useEffect } from "react";
import { useForceUpdate } from "@ntropy/hooks/src/lifecycle/useForceUpdate";

export function useFirstMount(forceRender?: boolean): boolean {
    const isFirst = useRef(true);

    const forceUpdate = useForceUpdate();

    useEffect(() => {
        if (forceRender) forceUpdate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (isFirst.current) {
        isFirst.current = false;

        return true;
    }

    return isFirst.current;
}

export function useToggledAfterMount(toggle: boolean): boolean {
    const isFirst = useRef(true);

    if (isFirst.current && toggle) {
        isFirst.current = false;

        return true;
    }

    return isFirst.current;
}