import { ICurrencyAccount } from "src/core/currency/currency.model";
import { useExchangeRates } from "src/core/currency/hooks/useExchangeRates";
import isEmpty from "lodash/isEmpty";
import { useMemo } from "react";
import { isSet } from "@ntropy/utils/src/type-utils";
import { roundFiat } from "src/core/currency/currency.function";

interface IUseSumToUSDInput {
    accounts: ICurrencyAccount[]
    enabled?: boolean
}

export function useSumToUsd({
    accounts,
    enabled = true,
}: IUseSumToUSDInput) {
    const [rates] = useExchangeRates({ enabled });

    return useMemo(() => {
        if (isEmpty(rates)) {
            return null;
        }

        const rawSum = accounts.reduce((acc, {currencyTicker, balance}) => {
            if (!isSet(rates[currencyTicker])) {
                console.error(`Missing rates for ${currencyTicker}`);
            }

            return acc + balance * (rates[currencyTicker] ?? 0)
        }, 0);

        return roundFiat(rawSum);
    }, [accounts, rates])
}