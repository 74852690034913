import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
    CardFooter,
} from "src/components/card/Card";
import { cn } from "@ntropy/utils/src/cn";
import { Link } from "react-router-dom";
import { ContentTags } from "@ntropy/utils/src/html-utils";
import { Button, ButtonVariant } from "@pg/design-system";

interface ICardWithImageProps {
    imageSrc: string;
    title: string;
    titleTag?: ContentTags & `h${number}`
    description: string;
    buttonText: string;
    anchorHref?: string;
    cardFooterContent?: React.ReactElement;
    variant?: "primary" | "secondary";
    imageClassname?: string;
    gradientDirection?: "to-right" | "to-bottom" | "none";
    imagePlacement?: "top" | "bottom";
    buttonVariant?: ButtonVariant;
    onClickCallback?: () => void;
}

const primaryVariant = {
    image: "w-full h-auto md:w-[180px] lg:w-[300px] md:h-[200px] lg:h-[348px] xl:w-[353px] xl:h-[363px] md:absolute md:top-1/2 md:-translate-y-1/2 right-0",
    cardTitle: "w-full text-[28px] leading-[36px] lg:text-[38px] lg:leading-[46px] lg:w-[20ch] xl:text-[60px] xl:leading-[78px] md:w-[25ch]",
    cardDescription: "text-brand-primary-100 w-full text-[14px] leading-[28px] lg:text-lg md:w-[50ch] lg:w-[45ch]",
    link: "py-5",
    cardHeader: "pt-0 gap-2",
    cardWrapper: "lg:p-16",
} as const;

const secondaryVariant = {
    image: "max-w-full w-[356px] h-full lg:w-[452px] xl:w-[532px] md:h-[400px] md:absolute top-0 bottom-0 right-0 xl:-right-[80px]",
    cardTitle: "w-full text-2xl lg:text-3xl md:w-[15ch] lg:w-[20ch] xl:text-4xl",
    cardDescription: "text-brand-primary-300 w-full text-sm xl:text-lg md:w-[30ch] lg:w-[40ch] xl:w-[50ch]",
    link: "",
    cardHeader: "gap-4",
    cardWrapper: "lg:p-10",
} as const;

export const CardWithImage = ({
    imageSrc,
    anchorHref,
    buttonText,
    title,
    titleTag = "h2",
    description,
    imageClassname,
    cardFooterContent,
    variant = "primary",
    gradientDirection = "none",
    imagePlacement = "top",
    buttonVariant = ButtonVariant.BRAND_SECONDARY_200,
    onClickCallback,
}: ICardWithImageProps) => {
    const isPrimaryVariant = variant === "primary";
    const variantStyles = isPrimaryVariant ? primaryVariant : secondaryVariant;

    const imageElement = (
        <div className={cn(variantStyles.image, imageClassname)}>
            <img
                src={imageSrc}
                alt="Card content image"
                className="relative md:absolute top-0 right-0 w-full h-full object-contain object-center"
            />
        </div>
    );

    return (
        <Card
            className={cn(
                "flex flex-col justify-center items-center md:justify-start md:items-start w-full relative p-8 min-h-[270px] border-0 text-card overflow-hidden bg-brand-primary",
                variantStyles.cardWrapper,
                {
                    "bg-brand-primary": gradientDirection === "none",
                    "from-brand-primary from-40% lg:from-46% to-support-linear-gradient to-100%": gradientDirection !== "none",
                    "bg-gradient-to-r": gradientDirection === "to-right",
                    "bg-gradient-to-b": gradientDirection === "to-bottom",
                },
            )}
        >
            {imagePlacement === "top" && imageElement}

            <CardHeader className={cn("max-w-[70ch] p-0", variantStyles.cardHeader)}>
                <CardTitle className={variantStyles.cardTitle} titleTag={titleTag}>{title}</CardTitle>
                <CardDescription className={cn(variantStyles.cardDescription)}>{description}</CardDescription>
            </CardHeader>
            <CardContent className="w-full p-0 mt-8">
                <Button
                    variant={buttonVariant}
                    className={cn("w-full md:w-52 p-3", variantStyles.link)}
                    asChild={!!anchorHref}
                    onClick={!anchorHref && !!onClickCallback ? onClickCallback : undefined}
                    callToAction
                >
                    {anchorHref ? <Link to={anchorHref}>{buttonText}</Link> : buttonText}
                </Button>


            </CardContent>
            {cardFooterContent && <CardFooter className="mt-8">{cardFooterContent}</CardFooter>}

            {imagePlacement === "bottom" && imageElement}
        </Card>
    )
}