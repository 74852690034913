import { SectionHeading } from "src/containers/Landing/components/section-heading/SectionHeading";
import { BasicCarousel } from "src/components/basic-carousel/BasicCarousel";
import { CarouselItem } from "@pg/design-system";
import { SkeletonCard } from "src/components/skeleton-card/SkeletonCard";
import { CasinoGameCategory } from "src/core/casino/casino.model";
import { GameCardCarouselItem } from "src/components/game-card-carousel-item/GameCardCarouselItem";
import useGamesList from "src/games/hooks/useGamesList";

interface ICarouselWithSingleCategoryProps {
    headingTitle: string;
    category: CasinoGameCategory;
    seeAllLink?: string
}

export const CarouselWithSingleCategory = ({ category, headingTitle, seeAllLink }: ICarouselWithSingleCategoryProps) => {
    const { games, isLoading } = useGamesList(category, 10);

    return (
        <section>
            <SectionHeading
                title={headingTitle}
            />

            <BasicCarousel arrowsClassName="mt-4" seeAllLink={seeAllLink}>
                {isLoading ? Array.from({ length: 10 }).map((_, i) => (
                    <CarouselItem key={i} className="min-w-[204px] sm:min-w-[230px]">
                        <SkeletonCard />
                    </CarouselItem>
                )) : games?.map(({
                    title, thumbnailSrc, url, provider, isDisabled,
                }) => {
                    return (
                        <GameCardCarouselItem
                            key={title}
                            url={url}
                            provider={provider}
                            thumbnailSrc={thumbnailSrc}
                            title={title}
                            isDisabled={isDisabled}
                        />
                    );
                })}
            </BasicCarousel>
        </section>
    )
}