import { useQuery } from "@tanstack/react-query";
import { useAuthorizedState } from "src/core/auth/hooks/useAuthorizedState";
import { WalletProvider, isSupportedWalletProvider } from "src/core/wallet/wallet.model";
import { axiosInstance } from "src/core/http/axios-instance";
import { IAuthMeV2Response, IUserInfo } from "src/core/auth/auth.model";
import { Endpoint } from "src/core/http/endpoint";
import isEmpty from "lodash/isEmpty";

interface IUseUserProfileInput {
    walletProvider?: WalletProvider
}

export function useUserProfile({ walletProvider: walletProviderToLoad }: IUseUserProfileInput = {}) {
    const { isAuthorized, walletProvider } = useAuthorizedState();

    const isCurrentWalletProvider = !walletProviderToLoad || walletProviderToLoad === walletProvider;

    const { data, isLoading } = useQuery({
        queryKey: ["user-info", walletProvider],
        initialData: {} as Partial<IUserInfo>,
        queryFn: async () => {
            if (!isSupportedWalletProvider(walletProvider)) {
                return null;
            }

            let userInfo: IUserInfo;

            try {
                switch (walletProvider) {
                    case WalletProvider.WalletConnect:
                        userInfo = await axiosInstance.get<IUserInfo>(Endpoint.UserInfo);
                        break;
                    case WalletProvider.HandCash:
                        const { data } = await axiosInstance.get<{ data: IAuthMeV2Response }>(Endpoint.AuthMeV2);
                        userInfo = { nickname: data.nickname, avatar: data.profile_image, publicId: data.hashedId };
                        break;
                }
            } catch (e) {
                throw e;
            }

            return userInfo ?? null;
        },
    });

    if (!isAuthorized || !isCurrentWalletProvider) {
        return { ...{} as Partial<IUserInfo>, isLoading: false, isUserLoaded: false };
    }

    return { ...data ?? {}, isLoading, isUserLoaded: !isEmpty(data) };
}