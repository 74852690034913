import { DialogHeader, DialogTitle, SquareLoader } from "@pg/design-system";
import { CopyButton } from "src/components/copy-button/CopyButton";
import { useState } from "react";
import QRCode from "react-qr-code";
import { CurrencyAndChainSelect } from "src/feature/wallet/components/currency-and-chain-select/CurrencyAndChainSelect";
import { useGetDepositAddress } from "src/feature/wallet/hooks/useGetDepositAddress";
import useAppStore from "src/useAppStore";
import { Chain } from "src/core/currency/currency.model";
import { usePrevious } from "@ntropy/hooks";

export const DepositView = () => {
    const selectedCurrency = useAppStore(store => store.asset.selected);
    const [currency, setCurrency] = useState(selectedCurrency)
    const [chain, setChain] = useState<Chain | null>(null);

    const prevSelectedCurrency = usePrevious(selectedCurrency);

    if (prevSelectedCurrency !== selectedCurrency && currency === prevSelectedCurrency) {
        setCurrency(selectedCurrency);
    }

    const [address, isLoading, isError] = useGetDepositAddress(currency, chain)

    return (
        <>
            <DialogHeader>
                <DialogTitle className="text-left">
                    Deposit Money
                </DialogTitle>
            </DialogHeader>

            <section className="mt-4 flex flex-col gap-4">
                <CurrencyAndChainSelect
                    currency={currency}
                    onCurrencyChange={setCurrency}
                    onChainChange={setChain}
                />

                <div>
                    <h3 className="text-sm mb-2">Your Personal address for {currency}</h3>
                    <CopyButton valueToCopy={address || ""} disabled={!address} message="Deposit address copied to clipboard" />
                </div>

                <div className="flex flex-col gap-4 items-center mt-4">
                    {!address ?
                        <div className="flex items-center justify-center border-2 border-brand-primary-300 rounded-lg w-[104px] h-[104px]">
                            {isLoading && <SquareLoader />}
                        </div>
                        :
                        <QRCode value={address || ""} size={104} />
                    }
                    <p className="text-sm text-center">At this address send only {currency}, it needs authentication</p>
                </div>
            </section>
        </>
    )
}