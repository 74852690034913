import "src/assets/css/main.css";
import "src/assets/scss/style.scss";

import { FC, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { getAccessAvailable } from "src/redux/modules/info";
import Toast from "src/components/toast/Toast";
import config from "src/config";
import Maintenance from "src/static/Maintenance";
import { useAppDispatch } from "src/redux/reducer";
import { useMaintenance } from "src/core/maintenance/hooks/useMaintenance";
import { useAsync } from "@ntropy/hooks";
import { useMaintenanceSocket } from "src/core/maintenance/hooks/useMaintenanceSocket";
import { useSleepModeCheck } from "src/containers/App/hooks/useSleepModeCheck";
import { useScrollTo } from "src/containers/App/hooks/useScrollTo";
import { useAffiliateCampaignSend } from "src/core/affiliate/hooks/useAffiliateCampaignSend";
import { useLoadHandcashUserWithAddons } from "src/core/auth/hooks/useLoadHandcashUserWithAddons";
import ModalsLayer from "src/components/modal/ModalsLayer";
import { withSignalRClientProvider } from "src/core/signalr/hooks/useSignalRClient";
import { useRemovePlaceholder } from "src/containers/App/hooks/useRemovePlaceholder";
import { Outlet } from "react-router-dom";
import RemoveTrailingSlash from "src/core/router/components/RemoveTrailingSlash";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { QueryParamProvider } from "use-query-params";
import { loadYolaScript } from "src/core/yola/loadYolaScript";

const App: FC = () => {
    const maintenance = useMaintenance();
    const dispatch = useAppDispatch();

    useRemovePlaceholder();
    useMaintenanceSocket();
    useScrollTo();
    useSleepModeCheck({ reload: false });
    useAffiliateCampaignSend();

    useLoadHandcashUserWithAddons();

    useEffect(() => {
        window.setTimeout(() => {
            window.prerenderReady = true;
        }, 1000);
    }, []);

    useAsync(async () => {
        try {
            await loadYolaScript(config.yolaId);
        } catch (e) {
            console.error("Yola feature could not be loaded properly", e)
        }
    }, [])

    useAsync(async () => {
        try {
            await dispatch(getAccessAvailable());
        } catch (e) {
            console.error("Error when getAccessAvailable", e);
        }
    }, [])

    if (maintenance) {
        return <Maintenance site />;
    }

    return (
        <div>
            <Helmet {...config.app.head} />
            <RemoveTrailingSlash />
            <QueryParamProvider adapter={ReactRouter6Adapter}>
                <Outlet />
            </QueryParamProvider>
            <ModalsLayer />
            <Toast />
        </div>
    );
}

export default withSignalRClientProvider(App);