export const possibleValues = {
    min: 0,
    max: 9999,
    count: 10000,
};

export function getWinningProbability({ roll, side }) {
    const possibilities = side === "UP" ? possibleValues.max - roll : roll - possibleValues.min;
    return possibilities / possibleValues.count;
}

export function getRollFromProbability(winProbability, side) {
    const possibilities = winProbability * possibleValues.count;
    const number = side === "UP" ? possibleValues.max - possibilities : possibilities - possibleValues.min;
    return Math.round(number);
}

export function getMultiplierFromBet(bet) {
    const multiplier = (1 / getWinningProbability(bet)) * 0.99;
    return +multiplier.toFixed(4);
}
