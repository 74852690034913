import classNames from "classnames/bind";
import { useLocation, Outlet, RouteObject, Link } from "react-router-dom";
import styles from "./assets/styles.scss";
import PageContainer from "src/containers/PageContainer/PageContainer";
import { useMobileOnly } from "src/helpers/responsive";
import { Path } from "src/core/paths/paths";
import { ReactElement } from "react";
import Icon from "@pg/design-system/src/components/icons/Icon";
import LazyPage from "src/core/router/components/LazyPage";
import AuthorizeRoute from "src/core/router/components/AuthorizeRoute";

const cx = classNames.bind(styles);

type IAccountRoute = RouteObject & {
    path: Path
    label: string;
    icon: ReactElement;
}

export const accountRoutes: IAccountRoute[] = [
    {
        label: "My Account",
        path: Path.Account,
        element: <LazyPage loader={() => import("src/containers/Account/MyAccount/MyAccount")} />,
        icon: <Icon.Myaccount />,
    },
    {
        label: "Game History",
        path: Path.AccountHistory,
        element: <LazyPage loader={() => import("src/containers/Account/History/History")} />,
        icon: <Icon.History />,
    },
    {
        label: "Transactions",
        path: Path.AccountTransactions,
        element: <LazyPage loader={() => import("src/containers/Account/Transactions/Transactions")} />,
        icon: <Icon.Transactions />,
    },
];

export const Account = () => {
    const { pathname } = useLocation();
    const isMobileOnly = useMobileOnly();

    if (!pathname.startsWith(Path.Account)) {
        return null
    }

    return (
        <PageContainer
            showFooter
            showHeader
            dark
            pageTitle={accountRoutes.find(({ path }) => pathname === path)?.label || ""}
            contentClass={cx("account")}
        >
            <AuthorizeRoute>
                {!isMobileOnly ? (
                    <div className={cx("navigation")}>
                        {accountRoutes.map(({ label, path, icon }) => (
                            <Link key={path} to={path} className={cx({ active: pathname === path })}>
                                {icon}
                                {label}
                            </Link>
                        ))}
                    </div>
                ) : null}
                <Outlet />
            </AuthorizeRoute>
        </PageContainer>
    );
}