export const sectionTypes = {
    P: {label: "Purple"},
    R: {label: "Red"},
    G: {label: "Green"},
    Y: {label: "Yellow"},
    B: {label: "Bonus", unselectable: true},
};
// the first item is the one in center top
export const wheelSections = [
    "Y",
    "G",
    "P",
    "R",
    "P",
    "R",
    "P",
    "R",
    "P",
    "G",
    "P",
    "G",
    "P",
    "B",
    "P",
    "R",
    "P",
    "R",
    "P",
    "G",
    "P",
    "G",
    "P",
    "R",
    "P",
    "R",
    "B",
    "R",
    "P",
    "R",
    "P",
    "R",
    "P",
    "G",
    "P",
    "G",
    "P",
    "R",
    "P",
    "R",
    "B",
    "R",
    "P",
    "G",
    "P",
    "G",
    "P",
    "R",
    "P",
    "R",
    "P",
    "R",
    "P",
    "G",
];
