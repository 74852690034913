import { useEffect } from "react";
import { useAppDispatch } from "src/redux/reducer";
import { setMaintenance } from "src/redux/modules/info";
import { useAppSocket } from "src/core/application/hooks/useAppSocket";

export function useMaintenanceSocket() {
    const dispatch = useAppDispatch();
    const socket = useAppSocket();

    useEffect(() => {
        const updateMaintenance = (data: string) => {
            dispatch(setMaintenance(data));
        };

        socket.on("MAINTENANCE", updateMaintenance);

        return () => {
            socket.off("MAINTENANCE", updateMaintenance)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}