import { FC } from "react";
import { Button, ButtonVariant } from "@pg/design-system";

import { useTriggerAuthModal } from "src/core/router/components/NavigateToLogin";

interface IErrorButtonsProps {
    onClose(): void
}

const ErrorButtons: FC<IErrorButtonsProps> = ({
    onClose,
}) => {
    const setModalOpen = useTriggerAuthModal();

    const handleTryAgain = () => {
        onClose();
        setModalOpen();
    }

    return (
        <div className="flex flex-col gap-1.5 mt-auto -mb-2">
            <Button className="w-full text-md font-bold" onClick={handleTryAgain}>Try again</Button>
            <a href="mailto:contact@peergame.com" target="_blank" rel="noreferrer noopener">
                <Button className="w-full text-xs font-bold" variant={ButtonVariant.GHOST}>Contact support</Button>
            </a>
        </div>
    );
};

export default ErrorButtons;