import { cn } from "@ntropy/utils/src/cn";

interface ISectionHeadingProps {
    title: string;
    description?: string;
    className?: string;
    mainHeader?: boolean;
}

export const SectionHeading = ({ description, title, className, mainHeader }: ISectionHeadingProps) => {
    const headingClassname = "text-[24px] leading-5 text-white"

    const headingElement = mainHeader ? <h1 className={headingClassname}>{title}</h1> :
        <h2 className={headingClassname}>{title}</h2>;

    return (
        <div className={cn("flex flex-col gap-4", className)}>
            {headingElement}
            {!!description && <h3 className="text-brand-primary-300 text-lg max-w-[50ch] font-normal">{description}</h3>}
        </div>
    )
}