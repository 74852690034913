import React, { FC } from "react";
import { logos } from "@pg/design-system/src/components/logo/logo.const";

interface ILogoProps extends React.HTMLAttributes<SVGSVGElement> {
    dark?: boolean
}

const Logo: FC<ILogoProps> = ({ dark, ...props }) => {
    const LogoComponent = dark ? logos.LogoDark : logos.LogoLight;
    return <LogoComponent role="img" aria-label="logo" {...props}  />;
};

export default Logo;