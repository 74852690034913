import { axiosInstance } from "src/core/http/axios-instance";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Endpoint } from "src/core/http/endpoint";
import { CasinoGameCategory, isExternalGameCategory } from "src/core/casino/casino.model";
import { games, isOriginalGameConfig } from "src/games/config";
import { useCurrentUsedApi } from "src/core/application/hooks/useCurrentUsedApi";
import { useAuthorizedState } from "src/core/auth/hooks/useAuthorizedState";
import config from "src/config";

interface IGameOldCashier {
    title: string;
    game_id: string;
    identifier: string;
    provider: string;
    client_type: string[];
    category: CasinoGameCategory;
}

interface IGameNewCashier {
    title: string;
    gameId: string;
    identifier: string;
    provider: string;
    clientType: string[];
    category: CasinoGameCategory;
    thumbnailSrc: string;
}

interface IGetHandcashSoftswissGames {
    data: IGameOldCashier[];
}

interface IGetCashierSoftswissGames {
    games: IGameNewCashier[];
}

export interface IMappedGame {
    title: string;
    thumbnailSrc: string;
    url: string;
    provider: string;
    category: CasinoGameCategory;
    isDisabled: boolean
    identifier?: string
    gameId?: string
}

async function getHandcashSoftswissGames(category: CasinoGameCategory | "", pageSize?: number): Promise<IMappedGame[]> {
    const params = { category };

    return (await axiosInstance.get<IGetHandcashSoftswissGames>(Endpoint.HandcashGamesList, { params })).data.slice(0, pageSize || -1).map(game => ({
        gameId: game.game_id,
        identifier: game.identifier.toLowerCase(),
        title: game.title,
        thumbnailSrc: `https://cdn.softswiss.net/i/s3/${game.provider}/${game.identifier}.png`,
        url: `/games/${game.category}/${game.identifier.toLowerCase()}`,
        provider: "BGaming",
        category: game.category,
        isDisabled: false,
    }));
}

interface IGetCashierSoftswissGamesParams {
    category: CasinoGameCategory | "";
    page?: number;
    pageSize?: number;
}

async function getCashierSoftswissGames({ category, page = 0, pageSize = 100 }: IGetCashierSoftswissGamesParams): Promise<IMappedGame[]> {
    const params = { category, page, pageSize: pageSize };

    const data = await axiosInstance.get<IGetCashierSoftswissGames>(Endpoint.CashierGamesList, { params });

    return data.games.map(game => ({
        gameId: game.gameId,
        identifier: game.identifier.toLowerCase(),
        title: game.title,
        thumbnailSrc: game.thumbnailSrc,
        url: `/games/${game.category}/${game.identifier.toLowerCase()}`,
        provider: game.provider,
        category: game.category,
        isDisabled: false,
    }))
}

const ensureThumbnailSrcAsString = thumbnail => {
    if (!!thumbnail && typeof thumbnail === "object") {
        return thumbnail.src ?? thumbnail;
    }

    return thumbnail;
};

export default function useGamesList(category: CasinoGameCategory | "", pageSize?: number) {
    const { shouldUseHandcash } = useCurrentUsedApi()
    const { walletProvider, isAuthorized } = useAuthorizedState()
    const isWalletConnectEnabled = config.enableWalletConnect
    const shouldOriginalGamesBeDisabled = walletProvider === "walletconnect" && isAuthorized && isWalletConnectEnabled;

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading,
    } = useInfiniteQuery({
        queryKey: [category, "gamelist", shouldUseHandcash, isWalletConnectEnabled, pageSize],
        queryFn: param => shouldUseHandcash || !isWalletConnectEnabled ? getHandcashSoftswissGames(category, pageSize) : getCashierSoftswissGames({
            category,
            pageSize,
            page: param.pageParam,
        }),
        initialPageParam: 0,
        getNextPageParam: (lastPage, _, lastPageParam) => {
            if (lastPage.length === 0) {
                return null
            }
            return lastPageParam + 1
        },
        enabled: category !== CasinoGameCategory.Originals,
    })

    const softswissGames = data?.pages.flat() || []

    let gamesList: IMappedGame[] = [];

    if ((!category || category === CasinoGameCategory.Originals) && !isLoading) {
        const originalGames: IMappedGame[] = Object.values(games)
            .filter(isOriginalGameConfig)
            .map(({ thumbnail, label, path }) => ({
                title: label,
                thumbnailSrc: ensureThumbnailSrcAsString(thumbnail),
                url: path,
                provider: "Peergame",
                category: CasinoGameCategory.Originals,
                isDisabled: shouldOriginalGamesBeDisabled,
            }));
        gamesList = gamesList.concat(originalGames);
    }
    if ((!category || isExternalGameCategory(category)) && !isLoading) {
        gamesList = shouldUseHandcash || !isWalletConnectEnabled ? [...gamesList, ...softswissGames] : [...softswissGames, ...gamesList];
    }

    return { games: gamesList, isLoading, fetchNextPage, error, hasNextPage, isFetchingNextPage };
}