import { CryptoCurrency } from "src/core/currency/currency.model";

import IconADA from "./currency/ada.svg?inline";
import IconBCH from "./currency/bch.svg?inline";
import IconBNB from "./currency/bnb.svg?inline";
import IconBTC from "./currency/btc.svg?inline";
import IconBTG from "./currency/btg.svg?inline";
import IconDASH from "./currency/dash.svg?inline";
import IconDOGE from "./currency/doge.svg?inline";
import IconETH from "./currency/eth.svg?inline";
import IconLSK from "./currency/lsk.svg?inline";
import IconLTC from "./currency/ltc.svg?inline";
import IconTRX from "./currency/trx.svg?inline";
import IconXRP from "./currency/xrp.svg?inline";
import IconUSDT from "./currency/usdt.erc20.svg?inline";
import IconZEC from "./currency/zec.svg?inline";
import IconUSDC from "./currency/usdc.svg?inline";
import IconBUSD from "./currency/busd.svg?inline";
import IconBSV from "./currency/bsv.svg?inline";
import IconPGP from "./currency/pgp.svg?inline";

export const icons: Record<CryptoCurrency, any> = {
    [CryptoCurrency.ADA]: IconADA,
    [CryptoCurrency.BCH]: IconBCH,
    [CryptoCurrency.BNB]: IconBNB,
    [CryptoCurrency.BTC]: IconBTC,
    [CryptoCurrency.BTG]: IconBTG,
    [CryptoCurrency.DASH]: IconDASH,
    [CryptoCurrency.DOGE]: IconDOGE,
    [CryptoCurrency.ETH]: IconETH,
    [CryptoCurrency.LSK]: IconLSK,
    [CryptoCurrency.LTC]: IconLTC,
    [CryptoCurrency.TRX]: IconTRX,
    [CryptoCurrency.XRP]: IconXRP,
    [CryptoCurrency.USDT]: IconUSDT,
    [CryptoCurrency.ZEC]: IconZEC,
    [CryptoCurrency.USDC]: IconUSDC,
    [CryptoCurrency.BUSD]: IconBUSD,
    [CryptoCurrency.BSV]: IconBSV,
    [CryptoCurrency.PGP]: IconPGP,
}