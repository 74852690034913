import { Slot, Slottable } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"
import { forwardRef, useEffect } from "react";
import { cn } from "@ntropy/utils/src/cn";
import { Loader2 } from "lucide-react";

enum ButtonVariant {
    DEFAULT = "default",
    DESTRUCTIVE = "destructive",
    OUTLINE = "outline",
    SECONDARY = "secondary",
    GHOST = "ghost",
    LINK = "link",
    FILLED = "filled",
    BRAND_300 = "brand-300",
    BRAND_SECONDARY_200 = "brand-secondary-200",
}

const buttonVariants = cva(
    "relative inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-all duration-150 ease-in-out focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:shadow-md",
    {
        variants: {
            variant: {
                [ButtonVariant.DEFAULT]: "bg-primary text-primary-foreground hover:bg-primary/70",
                [ButtonVariant.DESTRUCTIVE]: "bg-destructive text-destructive-foreground hover:bg-destructive/70",
                [ButtonVariant.OUTLINE]: "border border-input bg-background bg-transparent hover:bg-accent hover:text-accent-foreground",
                [ButtonVariant.SECONDARY]: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
                [ButtonVariant.GHOST]: "text-white hover:bg-accent hover:text-accent-foreground",
                [ButtonVariant.LINK]: "text-primary underline-offset-4 hover:underline",
                [ButtonVariant.FILLED]: "text-brand-primary border border-input bg-background hover:bg-background/80 hover:border-input/80",
                [ButtonVariant.BRAND_SECONDARY_200]: "bg-brand-second-200 border-brand-second-200 text-brand-white hover:bg-brand-second-200/80 hover:border-brand-second-200/80",
                [ButtonVariant.BRAND_300]: "bg-brand-primary-300 text-brand-primary hover:bg-brand-primary-300/70",
            },
            size: {
                default: "h-10 px-4 py-2",
                sm: "h-9 rounded-md px-3",
                lg: "h-11 rounded-md px-8",
                icon: "h-10 w-10",
            },
        },
        defaultVariants: {
            variant: ButtonVariant.DEFAULT,
            size: "default",
        },
    }
);

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean;
    callToAction?: boolean;
    isLoading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, variant, size, asChild = false, callToAction = false, isLoading, disabled, ...props }, ref) => {
        const Composition = asChild ? Slot : "button"

        useEffect(() => {
            if (asChild && isLoading !== undefined) {
                console.error("Cannot use loader in Button with asChild=true");
            }
        }, [asChild, isLoading])

        return (
            <Composition
                className={cn(buttonVariants({ variant, size, className }), {
                    "hover:scale-[1.1]": callToAction,
                })}
                ref={ref}
                disabled={isLoading || disabled}
                type="button"
                {...props}
            >
                {isLoading && (
                    <Loader2 className="h-5 w-5 animate-spin text-muted" />
                )}
                <Slottable>{!isLoading && props.children}</Slottable>
            </Composition>
        )
    }
)
Button.displayName = "Button"

export { Button, buttonVariants, ButtonVariant }