import memoizeFormatConstructor from "intl-format-cache";
import { isSet } from "@ntropy/utils/src/type-utils";
import isNaN from "lodash/isNaN";
import floor from "lodash/floor";
import ceil from "lodash/ceil";
import lodashRound from "lodash/round";

const ONE_M = 1000000;
const ONE_K = 1000;

const getNumberFormatter = memoizeFormatConstructor(Intl.NumberFormat);

interface IFormatNumberOptions {
    locale?: string
    minimumIntegerDigits?: number
    minimumFractionDigits?: number
    maximumFractionDigits?: number
    minimumSignificantDigits?: number
    maximumSignificantDigits?: number
    asCurrency?: boolean
    style?: "decimal" | "percent"
    forceThousandsSeparator?: " " | "" | null
    round?: "floor" | "ceil" | "round"
}

const getCurrentBrowsersLanguage = (() => {
    // const nav = navigator as any;
    // // detect the language only once at startup:
    // let language = (nav.languages && nav.languages[0]) || nav.language || nav.userLanguage;

    // HARDCODING LOCALE TO en-US
    // UNTIL NUMBER INPUT FORMATTING is done
    const language = "en-US";

    // return chached response:
    return () => "en-US";
})();

const incompleteDecimalsRegexes: Record<number, RegExp> = {};

const getIncompleteDecimalsRegex = (maximumFractionDigits: number) => {
    return incompleteDecimalsRegexes[maximumFractionDigits] ?? (incompleteDecimalsRegexes[maximumFractionDigits] = new RegExp(`\\.(\\d{1,${maximumFractionDigits - 1}})$`))
}

export function formatNumber(value: number | null | undefined, options: IFormatNumberOptions = {}): string {
    if (!isSet(value)) {
        return "0";
    }

    if (Intl === undefined) {
        return (value ?? "").toString();
    }

    let { locale, round, asCurrency, forceThousandsSeparator, ...numberFormatOptions } = options;

    if (locale === undefined) {
        locale = getCurrentBrowsersLanguage();
    }

    const numberFormatter: Intl.NumberFormat = getNumberFormatter(locale, numberFormatOptions);

    if (numberFormatter === null) {
        return (value ?? "").toString();
    }

    let valueToUse = value ?? null;

    if (round && isSet(options.maximumFractionDigits)) {
        switch (round) {
            case "floor": valueToUse = floor(valueToUse, options.maximumFractionDigits); break;
            case "ceil": valueToUse = ceil(valueToUse, options.maximumFractionDigits); break;
            case "round": valueToUse = lodashRound(valueToUse, options.maximumFractionDigits); break;
        }
    }

    let formatted = numberFormatter.format(valueToUse);

    if (isSet(forceThousandsSeparator)) {
        formatted = formatted?.replace(/,/g, forceThousandsSeparator) ?? "";
    }

    if (asCurrency && isSet(options.maximumFractionDigits)) {
        const incompleteDecimalsRegex = getIncompleteDecimalsRegex(options.maximumFractionDigits)

        formatted = formatted?.replace(incompleteDecimalsRegex, (_, match: string) => {
            return `.${match.padEnd(options.maximumFractionDigits!, "0")}`
        })
    }

    return formatted;
}

export const numberSequence = (
    sequenceStart: number,
    sequenceEnd: number,
    increment = 1,
): number[] => {
    if (sequenceStart > sequenceEnd) {
        throw new Error("sequenceStart can not be larger than sequence end");
    }

    const sequence: number[] = [];

    for (let i = sequenceStart; i < sequenceEnd; i += increment) {
        sequence.push(i);
    }

    return sequence;
};

export const getNumValue = (value: string | number | null | undefined) => {
    if (typeof value !== "string") {
        return value ?? null;
    }

    if (value === "") {
        return null;
    }

    const num = Number(value.replace(",", ".").replace(/[^0-9.,-]/g, ""));

    if (isNaN(num)) {
        return null;
    }

    return num;
};

export const getOrdinal = (value: number | null | undefined): string => {
    if (!isSet(value)) {
        return "";
    }

    const j = value % 10;
    const k = value % 100;

    if (j === 1 && k !== 11) {
        return value + "st";
    }

    if (j === 2 && k !== 12) {
        return value + "nd";
    }

    if (j === 3 && k !== 13) {
        return value + "rd";
    }

    return value + "th";
}

export type IShortenValueOptions = { prefix?: string };

export const shortenValue = (val: number, options: IShortenValueOptions = {}) => {
    const { prefix = "" } = options;
    if (val === null) {
        return null;
    }

    if (val >= ONE_M) {
        return `${prefix}${formatNumber(val / ONE_M, { maximumFractionDigits: 2, round: "floor" })}M`;
    } else if (val >= ONE_K) {
        return `${prefix}${formatNumber(val / ONE_K, { maximumFractionDigits: 2, round: "floor" })}K`;
    }

    return `${prefix}${val}`;
}

export const range = (start: number, end: number) => {
    const result: number[] = [];

    for (let i = start; i < end; i++) {
        result.push(i);
    }

    return result;
};