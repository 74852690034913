import { SetStateAction, createContext } from "react";
import isFunction from "lodash/isFunction";
import { strictEquals, Predicate } from "@ntropy/utils";
import pickBy from "lodash/pickBy";

export const handleStateAction = <S>(action: SetStateAction<S>, currentState: S): S => {
    return isFunction(action) ? (action as (prevState: S) => S)(currentState) : action as S;
}

export const handleSetStateInRecord = <S extends object = object, K extends keyof S = keyof S, T extends S[K] = S[K]>(
    record: S | null | undefined,
    key: K,
    value: SetStateAction<T | undefined | null>,
    defaultAsEmpty: T | null | undefined = null as T,
    equalityFn: Predicate<T | null | undefined> = strictEquals,
): S => {
    const newValue = handleStateAction(value, record?.[key] as T ?? defaultAsEmpty!);

    if (equalityFn(newValue, defaultAsEmpty)) {
        return pickBy(record, (_, k) => k !== key.toString()) as S;
    }

    return ({...record ?? {} as S, [key]: newValue });
}

export const EmptyContext = createContext<any>({});