import { isSet } from "./type-utils";
import { getNumValue } from "@ntropy/utils/src/number-utils";
import maxBy from "lodash/maxBy";
import minBy from "lodash/minBy";

function greatestCommonDivisor(a: number, b: number): number {
    let t = 0;

    while (b !== 0) {
        t = b;
        b = a % b;
        a = t;
    }

    return a;
}

export function simplifyFraction(numerator: number, denominator: number): {
    numerator: number
    denominator: number
} {

    const gcd = greatestCommonDivisor(numerator, denominator);

    return {
        numerator: numerator / gcd,
        denominator: denominator / gcd,
    }
}

export const clampValue = (min: number, value: number, max: number, priorityToMin?: boolean) => {
    return priorityToMin ? Math.max(min, Math.min(value, max)) : Math.min(max, Math.max(value, min));
};
export const clampToMax = (value: number, max: number) => clampValue(0, value, max);

export function isBetweenValues(min: number, value: number, max: number, inclusive: boolean): boolean;
export function isBetweenValues(min: number, value: number, max: number, lowerInclusive?: boolean, upperInclusive?: boolean): boolean;
export function isBetweenValues(min: number, value: number, max: number, lowerInclusive: boolean = true, upperInclusive: boolean = lowerInclusive) {
    const isOverLower = lowerInclusive ? value >= min : value > min;
    const isBelowUpper = upperInclusive ? value <= max : value < max;

    return isOverLower && isBelowUpper;
};

export const round = (value: number, decimalPlaces = 0) => {
    const multiplier = Math.pow(10, decimalPlaces);

    return Math.round(value * multiplier) / multiplier;
};

export const roundToOrder = (value: number, order = 1) => {
    return Math.round(value / order) * order;
};

export const roundToEven = (x: number | null | undefined) => {
    if (!x) {
        return x ?? null;
    }

    return 2 * Math.round(x / 2);
};

export const floor = (value: number, decimalPlaces = 0) => {
    const multiplier = Math.pow(10, decimalPlaces);

    return Math.floor(value * multiplier) / multiplier;
};

export const floorWithMaximumDecimalPlaces = (val: number | string, maximumFractionDigits: number) => {
    const numericValue = (typeof val === "string" ? getNumValue(val) : val) ?? 0;
    return (floor(numericValue, maximumFractionDigits) ?? 0).toString();
}

export const randomize = (min = 0, max = 100) => min + Math.random() * (max - min);

export const randomInt = (min: number, max: number): number => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
};

export const isNumeric = (x: number | string | null | undefined): x is (number | `${number}`) => {
    return (typeof x === "number" || typeof x === "string") && x !== "" && !isNaN(Number(x));
};

export function isNumber(value: any): value is number {
    const p = parseInt(value, 10);
    return p === +p && p === (p | 0);
}

export function isInt(value: any): value is number {
    const n = Number(value);
    return n % 1 === 0;
}
export const asNumberOrNull = (x: number | string | null | undefined) => {
    if (x === "") {
        return null;
    }

    return isNumeric(x) ? Number(x) : null;
};

export const isNonZeroNumber = (value: any): value is Exclude<number, 0> => {
    return isSet(value) && isNumber(value) && value !== 0;
}

export const normalizeNumbers = (arr: (number | null | undefined)[], min: number, max: number) => {
    if (arr.length === 1) {
        return [min + (max - min) / 2];
    }

    const minInArr = Math.min(...arr as number[]);
    const maxInArr = Math.max(...arr as number[]);
    const diffInArr = maxInArr - minInArr;
    const normalizedDiff = max - min;

    return arr.map(value => {
        if (!isSet(value)) {
            return value;
        }

        const valueToMin = value - minInArr;

        return min + (valueToMin / diffInArr) * normalizedDiff;
    })
}

type ResolvePick<T extends Record<string | number, number> | number[], K extends keyof T = keyof T> = T extends number[] ? [number] : K extends infer U ? {
    [I in (U & keyof T)]: number
} : number

export const pickMin = <T extends Record<string | number, number> | number[]>(obj: T): ResolvePick<T> => {
    const key: any = minBy(Object.keys(obj), (v: any) => obj[v]);

    return { [key!]: obj[key!] } as ResolvePick<T>;
}

export const pickMax = <T extends Record<string | number, number> | number[]>(obj: T): ResolvePick<T> => {
    const key: any = maxBy(Object.keys(obj), (v: any) => obj[v]);

    return { [key!]: obj[key!] } as ResolvePick<T>;
}

export const getOrderOfMagnitude = (value: number) => {
    const stringValue = String(value);

    return Math.pow(10, (stringValue.length - 1));
}

export const getDecimalPlaces = (value: number) => {
    const [match, decimal] = String(value).match(/\.(\d*)$/) ?? [];

    if (!match) {
        return 0;
    }

    return decimal.length;
}

export const equalsWithPrecision = (value1: number, value2: number, precision = 1) => {
    return Math.abs(value1 - value2) < precision;
}

export const greaterThenWithPrecision = (value1: number, value2: number, precision = 1) => {
    return value1 > value2 && !equalsWithPrecision(value1, value2, precision);
}

export const lesserThenWithPrecision = (value1: number, value2: number, precision = 1) => {
    return value1 < value2 && !equalsWithPrecision(value1, value2, precision);
}

export const getNumberSequences = (arr: number[]): number[][] => {
    const sequence: number[][] = []
    let temp: number[] = []

    for (let i = 0; i < arr.length; i++) {
        if (i === 0 || ((arr[i+1] - arr[i]) === 1)) {
            temp.push(arr[i])
        } else {
            if (temp.length) {
                sequence.push([...temp,arr[i]])
                temp = []
            }
        }
    }
    if(temp.length) sequence.push(temp)
    return sequence
}

export const isValidNth = (value: number, nTimes: number, modifier = 0) => {
    const which = whichNth(value, nTimes, modifier);
    return which >= 0 && which % 1 === 0;
}

export const whichNth = (value: number, nTimes: number, modifier = 0) => {
    return (value - modifier) / nTimes;
}