import { isNumeric } from "@ntropy/utils/src/math-utils";
import { pascalCase } from "@ntropy/utils/src/text-utils";
import { isSet } from "@ntropy/utils/src/type-utils";
import findKey from "lodash/findKey";
import { CallbackFn, UnionToArray } from "@ntropy/utils/src/typescript-helpers";
import startCase from "lodash/startCase";
import { asDictionaryOf } from "@ntropy/utils/src/array-utils";


const stringIsNotNumeric = (value: any) => !isNumeric(value);
const stringKeyIsPascalCase: EnumFilter = (value, key) => typeof key === "string" && pascalCase(key as string) === key;
export const stringValueIsLowerCase = (value: any) => typeof value === "string" && value.toLowerCase() === value;

export const stringKeyIsUpperCase: EnumFilter = (value, key) =>
    typeof key === "string" && (key as string).toUpperCase() === key;

export type EnumValue<T> = T[keyof T] & (string | number);
export type PreFilteredValue<T = any> = T[keyof T] extends string ? `${T[keyof T] & string}` : never;
export type EnumFilter<T = any> = (value: PreFilteredValue<T>, key: string) => boolean;

export const enumToValues = <T>(enumeration: T, additionalFilter: EnumFilter<T> | null = stringKeyIsPascalCase): UnionToArray<T[keyof T]> => {
    let filteredKeys = Object.keys(enumeration as any).filter(stringIsNotNumeric);

    const hasNumbers = Object.values(enumeration as any).some((v) => typeof v === "number");

    if (!hasNumbers && additionalFilter) {
        filteredKeys = filteredKeys.filter((key) => {
            const value = enumeration[key as keyof T] as PreFilteredValue<T>;

            return additionalFilter(value, key);
        });
    }

    return filteredKeys.map((key) => enumeration[key as keyof T]) as any;
};

export const enumToKeys = <T>(enumeration: T, additionalFilter: EnumFilter | null = stringKeyIsPascalCase): (keyof T)[] => {
    let filteredKeys = Object.keys(enumeration as any).filter(stringIsNotNumeric);
    const hasNumbers = Object.values(enumeration as any).some((v) => typeof v === "number");

    if (!hasNumbers && additionalFilter) {
        filteredKeys = filteredKeys.filter((key) => {
            const value = enumeration[key as keyof T] as PreFilteredValue<T>;

            return additionalFilter(value, key);
        });
    }

    return filteredKeys as (keyof T)[];
};

export const valueIsContainedInEnum = <T>(value: any, enumeration: T): value is T[keyof T] => {
    return enumToValues(enumeration as any).some(enumValue => enumValue === value);
}

export function getEnumKey<T>(value: EnumValue<T>, enumeration: T): keyof T & string;
export function getEnumKey<T>(value: EnumValue<T> | any, enumeration: T): (keyof T & string) | undefined;
export function getEnumKey<T>(value: any, enumeration: T): undefined;
export function getEnumKey<T>(value: EnumValue<T> | any, enumeration: T): (keyof T & string) | undefined {
    const standardEnumKey = (enumeration as any)?.[value as any] as (keyof T & string) | null | undefined;

    if (isSet(standardEnumKey)) {
        if (typeof value === "string" && typeof standardEnumKey === "number") {
            return value as keyof T & string;
        }

        return standardEnumKey ?? undefined;
    } else {
        return findKey(enumeration, (v) => v === value) as (keyof T & string) | undefined;
    }
}

export function getEnumPropName<T>(value: EnumValue<T> | any, enumeration: T): string {
    return startCase(getEnumKey(value, enumeration) ?? "");
}

export const toDictionaryOf = <T, V>(value: V, enumeration: T): Record<T[keyof T] & (string | number), V> => {
    return asDictionaryOf<T[keyof T] & (string | number), V>(enumToValues(enumeration) as any, value);
};

export const filterEnum = <T>(
    enumeration: T,
    filter: CallbackFn<[T[keyof T], keyof T], boolean>,
    additionalFilter: EnumFilter | null = stringKeyIsPascalCase,
): Partial<T> => {
    const filteredKeys = enumToKeys(enumeration, additionalFilter);

    return filteredKeys.reduce((acc, key) => {
        if (filter(enumeration[key as keyof T], key)) {
            return {...acc, [key]: enumeration[key as keyof T]}
        }

        return acc;
    }, {} as Partial<T>);
}

export const reduceEnum = <V, T>(
    enumeration: T,
    reducer: CallbackFn<[V, T[keyof T], keyof T], V>,
    accumulator: V,
    additionalFilter: EnumFilter | null = stringKeyIsPascalCase,
): V => {
    const filteredKeys = enumToKeys(enumeration, additionalFilter);

    return filteredKeys.reduce((acc, key) => reducer(acc, enumeration[key as keyof T], key), accumulator);
}

export const mapEnum = <V extends Record<keyof T, any>, T>(
    enumeration: T,
    mapper: CallbackFn<[T[keyof T], keyof T], V[keyof T]>,
    additionalFilter: EnumFilter | null = stringKeyIsPascalCase,
): V => {
    return reduceEnum(enumeration, (acc, v, k) => {
        return {...acc, [k]: mapper(v, k)};
    }, {} as V, additionalFilter)
}

export const getKeyValueEnumObject = <V extends Record<keyof T, any>, T>(enumeration: T) => mapEnum(enumeration as any, (v) => v, null) as V;
