import ImgLangEn from "src/components/header/assets/images/english.png";
import ImgLangCn from "src/components/header/assets/images/chinese.png";
import ImgLangJa from "src/components/header/assets/images/japanese.png";
import ImgLangEs from "src/components/header/assets/images/spanish.png";
import ImgLangRu from "src/components/header/assets/images/russian.png";
import ImgLangNl from "src/components/header/assets/images/dutch.png";

export interface ILanguages {
    [key: string]: {
        icon: string;
        name: string
    };
}

export const languages: ILanguages = {
    en: {
        icon: ImgLangEn,
        name: "English",
    },
    zh_cn: {
        icon: ImgLangCn,
        name: "中文",
    },
    ja: {
        icon: ImgLangJa,
        name: "日本語",
    },
    es: {
        icon: ImgLangEs,
        name: "Español",
    },
    ru: {
        icon: ImgLangRu,
        name: "Русский",
    },
    nl: {
        icon: ImgLangNl,
        name: "Nederlands",
    },
};