import { SetStateAction, useCallback, useEffect, useState, MutableRefObject } from "react";
import { useStateRef } from "@ntropy/hooks/src/state/useStateRef";
import { DispatchWithPromise } from "@ntropy/utils";

export function useStateRendered<S = void>(state?: S) {
    const [resolver, setResolver] = useState<null | ((value?: S | PromiseLike<S>) => void)>(
        null,
    );

    useEffect(() => {
        if (resolver) {
            resolver(state);
            setResolver(null);
        }
    }, [resolver, state]);

    return useCallback(async (callback?: (state?: S | PromiseLike<S>) => void) => new Promise<S | undefined>((resolve) => {
        const resolver = (value?: S | PromiseLike<S>) => {
            callback?.(value);
            resolve(value);
        };

        setResolver(resolver);
    }), []);
}

export function useStateAsync<S>(
    initialState: S | (() => S),
): [S, DispatchWithPromise<SetStateAction<S>>, MutableRefObject<S>] {
    const [state, setState, stateRef] = useStateRef(initialState);
    const stateRendered = useStateRendered(state);

    const handleSetState = useCallback(
        (stateAction: SetStateAction<S>) => {
            setState(stateAction);

            return stateRendered()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    ) as DispatchWithPromise<SetStateAction<S>>;

    return [state, handleSetState, stateRef];
}
