import { cn } from "@ntropy/utils/src/cn";

enum SkeletonVariant {
    Dark = "dark",
    Light = "light",
}

interface ISkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
    variant?: SkeletonVariant;
}

const variants = {
    [SkeletonVariant.Dark]: "from-[#22096C] via-[#311C6D] to-[#22096C]",
    [SkeletonVariant.Light]: "from-[#f0ecff] via-[#9893cb] to-[#f0ecff]"
}

function Skeleton({
    className,
    variant = SkeletonVariant.Dark,
    ...props
}: ISkeletonProps) {
    return (
        <div
            className={cn("relative rounded-md bg-brand-primary overflow-hidden aspect-square", className)}
            {...props}
        >
            <div
                className={cn("absolute h-full w-[200px] top-0 left-0 bg-gradient-to-r from-0% via-50% to-100% animate-skeleton-loading", variants[variant])}
            />
        </div>
    )
}

export { Skeleton, SkeletonVariant }