import { useEffect, useRef } from "react";
import { useRefFactory } from "@ntropy/hooks";

interface IUseSleepModeInput {
    reload?: boolean
}

export function useSleepModeCheck({reload}: IUseSleepModeInput) {
    const last = useRefFactory(() => new Date());
    const sleepMode = useRef(false);
    const checkSleepModeInterval = useRef<number | undefined>();

    useEffect(() => {
        document.onvisibilitychange = () => {
            if (document.visibilityState === "visible" && sleepMode.current) {
                console.log("reload!");

                if (reload) window.location.reload();
            }
        };

        checkSleepModeInterval.current = window.setInterval(() => {
            const now = new Date();

            if (now.getTime() - last.current.getTime() > 3000) {
                sleepMode.current = true;
                clearInterval(checkSleepModeInterval.current);
            }

            last.current = now;
        }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}