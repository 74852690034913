import { useRef, MutableRefObject } from "react";

export function useRefFactory<T>(factoryFn: () => Exclude<T, null>): MutableRefObject<T> {
    const value = useRef<T | null>(null);

    if (value.current === null) {
        value.current = factoryFn();
    }

    return value as MutableRefObject<T>;
}