import { FC, ReactNode } from "react";
import { useAuthorizedState } from "src/core/auth/hooks/useAuthorizedState";
import { useUserProfile } from "src/core/auth/hooks/useUserProfile";
import { useAppSelector } from "src/redux/reducer";
import { NavigateToLogin } from "src/core/router/components/NavigateToLogin";
import { Loader } from "@pg/design-system";

interface IAuthorizeRouteProps {
    children: ReactNode;
    affiliate?: boolean
}

const AuthorizeRoute: FC<IAuthorizeRouteProps> = ({ children, affiliate }) => {
    const { isAuthorized, isAuthorizing } = useAuthorizedState();
    const { isLoading: isUserLoading } = useUserProfile();
    const isAffiliateLoaded = useAppSelector(({ affiliate }) => affiliate.loaded);

    if (isAuthorizing || isUserLoading) {
        return <Loader />;
    }

    if (affiliate && isAuthorized && !isAffiliateLoaded) {
        return <Loader />;
    }

    if (!isAuthorized) {
        return <NavigateToLogin affiliate={affiliate} />;
    }

    return children;
};

export default AuthorizeRoute;