import { SyntheticEvent, RefObject, MutableRefObject, KeyboardEvent } from "react";
import isPlainObject from "lodash/isPlainObject";
import { isHTMLElement } from "./children-utils";

export const stopPropagationAndPreventDefault = (e?: Event | SyntheticEvent<any> | null) => {
    e?.stopPropagation();
    e?.preventDefault();
};

export const stopPropagation = (e?: Event | SyntheticEvent<any> | null) => {
    e?.stopPropagation();
};

export const preventDefault = (e?: Event | SyntheticEvent<any> | null) => {
    e?.preventDefault();
};


export const isEventInsideElement = (
    event: Event | SyntheticEvent | null | undefined,
    refOrElement: RefObject<HTMLElement | undefined> | MutableRefObject<HTMLElement | undefined> | HTMLElement,
): boolean => {
    const element = isPlainObject(refOrElement) && "current" in refOrElement ? refOrElement.current : refOrElement;
    return !!event && isHTMLElement(event.target) && !!element && "contains" in element && element.contains(event.target);
};

export enum Keys {
    Left = "Left",
    ArrowLeft = "ArrowLeft",
    Right = "Right",
    ArrowRight = "ArrowRight",
    Down = "Down",
    ArrowDown = "ArrowDown",
    Tab = "Tab",
    Up = "Up",
    ArrowUp = "ArrowUp",
    Delete = "Delete",
    Backspace = "Backspace",
    Enter = "Enter",
    Escape = "Escape",
    Esc = "Esc",
}

export const isCharKeyPressed = (event: KeyboardEvent<HTMLElement>) => {
    return event.key.length === 1;
};