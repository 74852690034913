import { useContext, useState, ReactElement, ReactNode } from "react";
import classNames from "classnames/bind";
import { useGameStore } from "src/components/game/hooks/useGameStore";
import styles from "./assets/styles.scss";
import Races from "src/components/stats-table/Races/Races";
import AllBetsHighRollersRow from "src/components/stats-table/AllBetsHighRollersRow";
import AuthButton from "src/feature/auth/AuthButton";
import MyBetsRow from "src/components/stats-table/MyBetsRow";
import GameCodeContext from "src/containers/Games/GameCodeContext";
import TableWrapper, { ITableColumn } from "src/components/table-wrapper/TableWrapper";
import { useUserProfile } from "src/core/auth/hooks/useUserProfile";
import { useMyGameHistoryBets } from "src/containers/Account/hooks/useGameHistory/useMyGameHistoryBets";
import { enumToKeys } from "@ntropy/utils/src/enum-utils";
import startCase from "lodash/startCase";

const cx = classNames.bind(styles);

enum StatsTab {
    MyBets = 0,
    AllBets = 1,
    HighRollers = 2,
    Races = 3,
}

const isBetsTab = (tab: StatsTab) => tab < StatsTab.Races;
const isAllUsersBetsTab = (tab: StatsTab) => isBetsTab(tab) && tab !== StatsTab.MyBets;

const tabs = enumToKeys(StatsTab).map(v => startCase(v).toUpperCase())

/** @deprecated */
export default function DeprecatedStatsTable() {
    const gameCode = useContext(GameCodeContext);
    const { isUserLoaded } = useUserProfile();
    const [selectedTab, setSelectedTab] = useState<StatsTab>(StatsTab.AllBets);
    const [myBets, myBetsLoading] = useMyGameHistoryBets({
        gameCode,
        enabled: selectedTab === StatsTab.MyBets && isUserLoaded,
    });

    const allBets = useGameStore(state => state.allBets);
    const highRollers = useGameStore(state => state.highRollers);

    function getContent() {
        if (isBetsTab(selectedTab)) {
            let tableRows: ReactElement[] = [];
            let columns: ITableColumn[];
            let emptyPlaceholder: ReactNode;

            if (isAllUsersBetsTab(selectedTab)) {
                tableRows = (selectedTab === StatsTab.AllBets ? allBets : highRollers).map((result, index) => (
                    <AllBetsHighRollersRow
                        result={result}
                        key={`${selectedTab}${result.asset}${result.game}${result.player_id}`}
                        className={index % 2 === 0 ? "even" : "odd"}
                    />
                ));

                columns = [
                    { label: "Game" },
                    { label: "User" },
                    { label: "Bet", hideMobile: true },
                    { label: "Multiplier", hideMobile: true },
                    { label: "Pick", hideMobile: true },
                    { label: "Result", hideMobile: true },
                    { label: "Payout (BSV)" },
                ];
            } else {
                columns = [
                    { label: "Round ID" },
                    { label: "Bet" },
                    { label: "Multiplier", hideMobile: true },
                    { label: "My Pick", hideMobile: true },
                    { label: "Result", hideMobile: true },
                    { label: "Payout (BSV)" },
                ];

                if (!isUserLoaded) {
                    emptyPlaceholder = <AuthButton />;
                } else if (!myBets.length) {
                    emptyPlaceholder = "You do not have any history";
                } else {
                    tableRows = myBets.map((result, index) => (
                        <MyBetsRow
                            key={`${result.asset}${result.game}${result.player_id}`}
                            result={result}
                            gameCode={gameCode}
                            className={index % 2 === 0 ? "even" : "odd"}
                        />
                    ));
                }
            }

            return (
                <TableWrapper
                    columns={columns}
                    isLoading={selectedTab === StatsTab.MyBets && myBetsLoading}
                    className={cx("table-wrapper", {
                        "all-bets": isAllUsersBetsTab(selectedTab),
                    })}
                    emptyPlaceholder={emptyPlaceholder}
                >
                    {tableRows}
                </TableWrapper>
            );
        }

        return <Races />;
    }

    return (
        <div className={cx("stats-table")}>
            <div className={cx("header")}>
                {tabs.map((label, index) => (
                    <button
                        key={label}
                        type="button"
                        className={cx({ active: selectedTab === index })}
                        onClick={() => setSelectedTab(index)}
                    >
                        {label}
                    </button>
                ))}
            </div>
            {getContent()}
        </div>
    );
}
