import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "src/core/http/axios-instance";
import { Endpoint } from "src/core/http/endpoint";
import { CryptoCurrency, Chain } from "src/core/currency/currency.model";
import { errorToast } from "src/helpers/toast";

export const useGetDepositAddress = (currency: CryptoCurrency | null | undefined, chain: Chain | null | undefined) => {
    const { data, isLoading, isError, error } = useQuery({
        queryKey: ["deposit-address", currency, chain],
        queryFn: () => axiosInstance.get<string>(Endpoint.DepositAddress(currency!), {
            params: {
                chain: chain,
            },
        }),
        enabled: !!currency && !!chain,
    });

    if (isError) {
        errorToast("Error when loading deposit address.");
        console.error("useGetDepositAddress ERROR", error)
    }

    return [data, isLoading, isError] as const;
}