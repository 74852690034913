import { successToast, errorToast } from "src/helpers/toast";

export default function copyToClipboard(text: string): void {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const successful = document.execCommand("copy");
        if (successful) {
            successToast("Copied to clipboard");
        } else {
            throw new Error("Copy command unsuccessful");
        }
    } catch (err) {
        errorToast("Error copying to clipboard");
    }

    document.body.removeChild(textArea);
}