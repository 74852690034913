// Generated by generate-icons script. DO NOT edit manually as it will be overwritten

import { lazy } from "react";
import IconWrapper, {IIconWrapperProps} from "./IconWrapper";

export type IIconProps = Omit<IIconWrapperProps, "icon" | "iconName">;


export const icons = {
    Alert: lazy(() => import("./components/Alert")),
    ArrowBack: lazy(() => import("./components/ArrowBack")),
    ArrowDown: lazy(() => import("./components/ArrowDown")),
    ArrowRight: lazy(() => import("./components/ArrowRight")),
    ArrowSmoothRight: lazy(() => import("./components/ArrowSmoothRight")),
    Baccarat: lazy(() => import("./components/Baccarat")),
    BitcoinTalk: lazy(() => import("./components/BitcoinTalk")),
    Blackjack: lazy(() => import("./components/Blackjack")),
    Camera: lazy(() => import("./components/Camera")),
    Casual: lazy(() => import("./components/Casual")),
    Chart: lazy(() => import("./components/Chart")),
    Checkmark: lazy(() => import("./components/Checkmark")),
    CheckShield: lazy(() => import("./components/CheckShield")),
    ChevronLeft: lazy(() => import("./components/ChevronLeft")),
    Clear: lazy(() => import("./components/Clear")),
    Close: lazy(() => import("./components/Close")),
    CloseSquare: lazy(() => import("./components/CloseSquare")),
    Coinflip: lazy(() => import("./components/Coinflip")),
    ConnectWallet: lazy(() => import("./components/ConnectWallet")),
    Copy: lazy(() => import("./components/Copy")),
    Cross: lazy(() => import("./components/Cross")),
    Dice: lazy(() => import("./components/Dice")),
    Discord: lazy(() => import("./components/Discord")),
    DiscordBubble: lazy(() => import("./components/DiscordBubble")),
    Fullscreen: lazy(() => import("./components/Fullscreen")),
    Games: lazy(() => import("./components/Games")),
    History: lazy(() => import("./components/History")),
    Info: lazy(() => import("./components/Info")),
    Inhouse: lazy(() => import("./components/Inhouse")),
    Ladder: lazy(() => import("./components/Ladder")),
    Link: lazy(() => import("./components/Link")),
    Logout: lazy(() => import("./components/Logout")),
    Minus: lazy(() => import("./components/Minus")),
    Myaccount: lazy(() => import("./components/Myaccount")),
    PlayCircle: lazy(() => import("./components/PlayCircle")),
    PlayNowWallet: lazy(() => import("./components/PlayNowWallet")),
    Plus: lazy(() => import("./components/Plus")),
    PlusSquare: lazy(() => import("./components/PlusSquare")),
    Races: lazy(() => import("./components/Races")),
    Referrals: lazy(() => import("./components/Referrals")),
    Refresh: lazy(() => import("./components/Refresh")),
    Reset: lazy(() => import("./components/Reset")),
    Roulette: lazy(() => import("./components/Roulette")),
    Selected: lazy(() => import("./components/Selected")),
    SideNav: lazy(() => import("./components/SideNav")),
    Slots: lazy(() => import("./components/Slots")),
    Sort: lazy(() => import("./components/Sort")),
    SoundOff: lazy(() => import("./components/SoundOff")),
    SoundOn: lazy(() => import("./components/SoundOn")),
    Success: lazy(() => import("./components/Success")),
    Swap: lazy(() => import("./components/Swap")),
    TableGames: lazy(() => import("./components/TableGames")),
    Telegram: lazy(() => import("./components/Telegram")),
    Transactions: lazy(() => import("./components/Transactions")),
    Trash: lazy(() => import("./components/Trash")),
    Turtlerace: lazy(() => import("./components/Turtlerace")),
    TwitterX: lazy(() => import("./components/TwitterX")),
    Undo: lazy(() => import("./components/Undo")),
    Update: lazy(() => import("./components/Update")),
    Wallet: lazy(() => import("./components/Wallet")),
    Wheel: lazy(() => import("./components/Wheel")),
} as const;

export type IconName = keyof typeof icons;
export const allIcons = Object.keys(icons) as IconName[];

class Icon {
    static Alert = (props: IIconProps) => <IconWrapper {...props} iconName="Alert" icon={icons.Alert} />;
    static ArrowBack = (props: IIconProps) => <IconWrapper {...props} iconName="ArrowBack" icon={icons.ArrowBack} />;
    static ArrowDown = (props: IIconProps) => <IconWrapper {...props} iconName="ArrowDown" icon={icons.ArrowDown} />;
    static ArrowRight = (props: IIconProps) => <IconWrapper {...props} iconName="ArrowRight" icon={icons.ArrowRight} />;
    static ArrowSmoothRight = (props: IIconProps) => <IconWrapper {...props} iconName="ArrowSmoothRight" icon={icons.ArrowSmoothRight} />;
    static Baccarat = (props: IIconProps) => <IconWrapper {...props} iconName="Baccarat" icon={icons.Baccarat} />;
    static BitcoinTalk = (props: IIconProps) => <IconWrapper {...props} iconName="BitcoinTalk" icon={icons.BitcoinTalk} />;
    static Blackjack = (props: IIconProps) => <IconWrapper {...props} iconName="Blackjack" icon={icons.Blackjack} />;
    static Camera = (props: IIconProps) => <IconWrapper {...props} iconName="Camera" icon={icons.Camera} />;
    static Casual = (props: IIconProps) => <IconWrapper {...props} iconName="Casual" icon={icons.Casual} />;
    static Chart = (props: IIconProps) => <IconWrapper {...props} iconName="Chart" icon={icons.Chart} />;
    static Checkmark = (props: IIconProps) => <IconWrapper {...props} iconName="Checkmark" icon={icons.Checkmark} />;
    static CheckShield = (props: IIconProps) => <IconWrapper {...props} iconName="CheckShield" icon={icons.CheckShield} />;
    static ChevronLeft = (props: IIconProps) => <IconWrapper {...props} iconName="ChevronLeft" icon={icons.ChevronLeft} />;
    static Clear = (props: IIconProps) => <IconWrapper {...props} iconName="Clear" icon={icons.Clear} />;
    static Close = (props: IIconProps) => <IconWrapper {...props} iconName="Close" icon={icons.Close} />;
    static CloseSquare = (props: IIconProps) => <IconWrapper {...props} iconName="CloseSquare" icon={icons.CloseSquare} />;
    static Coinflip = (props: IIconProps) => <IconWrapper {...props} iconName="Coinflip" icon={icons.Coinflip} />;
    static ConnectWallet = (props: IIconProps) => <IconWrapper {...props} iconName="ConnectWallet" icon={icons.ConnectWallet} />;
    static Copy = (props: IIconProps) => <IconWrapper {...props} iconName="Copy" icon={icons.Copy} />;
    static Cross = (props: IIconProps) => <IconWrapper {...props} iconName="Cross" icon={icons.Cross} />;
    static Dice = (props: IIconProps) => <IconWrapper {...props} iconName="Dice" icon={icons.Dice} />;
    static Discord = (props: IIconProps) => <IconWrapper {...props} iconName="Discord" icon={icons.Discord} />;
    static DiscordBubble = (props: IIconProps) => <IconWrapper {...props} iconName="DiscordBubble" icon={icons.DiscordBubble} />;
    static Fullscreen = (props: IIconProps) => <IconWrapper {...props} iconName="Fullscreen" icon={icons.Fullscreen} />;
    static Games = (props: IIconProps) => <IconWrapper {...props} iconName="Games" icon={icons.Games} />;
    static History = (props: IIconProps) => <IconWrapper {...props} iconName="History" icon={icons.History} />;
    static Info = (props: IIconProps) => <IconWrapper {...props} iconName="Info" icon={icons.Info} />;
    static Inhouse = (props: IIconProps) => <IconWrapper {...props} iconName="Inhouse" icon={icons.Inhouse} />;
    static Ladder = (props: IIconProps) => <IconWrapper {...props} iconName="Ladder" icon={icons.Ladder} />;
    static Link = (props: IIconProps) => <IconWrapper {...props} iconName="Link" icon={icons.Link} />;
    static Logout = (props: IIconProps) => <IconWrapper {...props} iconName="Logout" icon={icons.Logout} />;
    static Minus = (props: IIconProps) => <IconWrapper {...props} iconName="Minus" icon={icons.Minus} />;
    static Myaccount = (props: IIconProps) => <IconWrapper {...props} iconName="Myaccount" icon={icons.Myaccount} />;
    static PlayCircle = (props: IIconProps) => <IconWrapper {...props} iconName="PlayCircle" icon={icons.PlayCircle} />;
    static PlayNowWallet = (props: IIconProps) => <IconWrapper {...props} iconName="PlayNowWallet" icon={icons.PlayNowWallet} />;
    static Plus = (props: IIconProps) => <IconWrapper {...props} iconName="Plus" icon={icons.Plus} />;
    static PlusSquare = (props: IIconProps) => <IconWrapper {...props} iconName="PlusSquare" icon={icons.PlusSquare} />;
    static Races = (props: IIconProps) => <IconWrapper {...props} iconName="Races" icon={icons.Races} />;
    static Referrals = (props: IIconProps) => <IconWrapper {...props} iconName="Referrals" icon={icons.Referrals} />;
    static Refresh = (props: IIconProps) => <IconWrapper {...props} iconName="Refresh" icon={icons.Refresh} />;
    static Reset = (props: IIconProps) => <IconWrapper {...props} iconName="Reset" icon={icons.Reset} />;
    static Roulette = (props: IIconProps) => <IconWrapper {...props} iconName="Roulette" icon={icons.Roulette} />;
    static Selected = (props: IIconProps) => <IconWrapper {...props} iconName="Selected" icon={icons.Selected} />;
    static SideNav = (props: IIconProps) => <IconWrapper {...props} iconName="SideNav" icon={icons.SideNav} />;
    static Slots = (props: IIconProps) => <IconWrapper {...props} iconName="Slots" icon={icons.Slots} />;
    static Sort = (props: IIconProps) => <IconWrapper {...props} iconName="Sort" icon={icons.Sort} />;
    static SoundOff = (props: IIconProps) => <IconWrapper {...props} iconName="SoundOff" icon={icons.SoundOff} />;
    static SoundOn = (props: IIconProps) => <IconWrapper {...props} iconName="SoundOn" icon={icons.SoundOn} />;
    static Success = (props: IIconProps) => <IconWrapper {...props} iconName="Success" icon={icons.Success} />;
    static Swap = (props: IIconProps) => <IconWrapper {...props} iconName="Swap" icon={icons.Swap} />;
    static TableGames = (props: IIconProps) => <IconWrapper {...props} iconName="TableGames" icon={icons.TableGames} />;
    static Telegram = (props: IIconProps) => <IconWrapper {...props} iconName="Telegram" icon={icons.Telegram} />;
    static Transactions = (props: IIconProps) => <IconWrapper {...props} iconName="Transactions" icon={icons.Transactions} />;
    static Trash = (props: IIconProps) => <IconWrapper {...props} iconName="Trash" icon={icons.Trash} />;
    static Turtlerace = (props: IIconProps) => <IconWrapper {...props} iconName="Turtlerace" icon={icons.Turtlerace} />;
    static TwitterX = (props: IIconProps) => <IconWrapper {...props} iconName="TwitterX" icon={icons.TwitterX} />;
    static Undo = (props: IIconProps) => <IconWrapper {...props} iconName="Undo" icon={icons.Undo} />;
    static Update = (props: IIconProps) => <IconWrapper {...props} iconName="Update" icon={icons.Update} />;
    static Wallet = (props: IIconProps) => <IconWrapper {...props} iconName="Wallet" icon={icons.Wallet} />;
    static Wheel = (props: IIconProps) => <IconWrapper {...props} iconName="Wheel" icon={icons.Wheel} />;
}

export default Icon;
