import { ICurrencyAccount } from "src/core/currency/currency.model";
import CurrencyListElement from "src/feature/wallet/components/currency-list-element/CurrencyListElement";

interface IWalletListOfCurrenciesProps {
    currencies: ICurrencyAccount[]
}

export const WalletListOfCurrencies = ({ currencies }: IWalletListOfCurrenciesProps) => {
    return (
        <ul className="flex flex-col gap-[20px] sm:max-h-[330px] py-2 pg-scrollbar overflow-y-auto">
            {currencies.map(account => (
                <CurrencyListElement key={account.currencyTicker} alignValues="left" {...account} />
            ))}
        </ul>
    )
}