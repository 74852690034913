import { Predicate, strictEqualWithoutFirst } from "@ntropy/utils";
import { usePrevious } from "@ntropy/hooks/src/changes/usePrevious";
import { useFirstMount } from "@ntropy/hooks/src/lifecycle/useFirstMount";
import { useMemo, useRef } from "react";

export function useChanged<T>(value: T, compare: Predicate<T, [boolean?]> | Predicate<T, [boolean]> = strictEqualWithoutFirst) {
    const firstMount = useFirstMount();
    const prevValue = usePrevious(value);

    return useMemo(() => !compare(prevValue, value, firstMount), [firstMount, compare, value, prevValue]);
}

export function useChangeCounter<T>(value: T, compare: Predicate<T, [boolean?]> | Predicate<T, [boolean]> = strictEqualWithoutFirst) {
    const changed = useChanged(value, compare);

    const changeCounter = useRef(0);

    if (changed) {
        changeCounter.current++;
    }

    return changeCounter.current;
}