import { useEffect, useRef } from "react";
import { Howler } from "howler";

const getMuted = () => {
    return "_muted" in Howler ? Howler._muted as boolean : false;
}

export function useMuteOutsidePage() {
    const prevSoundState = useRef(getMuted());

    useEffect(() => {
        function visibilityListener() {
            if (document.visibilityState === "visible") {
                Howler.mute(prevSoundState.current);
            } else {
                prevSoundState.current = getMuted();
                Howler.mute(true);
            }
        }

        visibilityListener();

        document.addEventListener("visibilitychange", visibilityListener);
        return () => document.removeEventListener("visibilitychange", visibilityListener);
    }, []);
}