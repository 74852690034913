import { useAsync } from "@ntropy/hooks";
import { axiosInstance } from "src/core/http/axios-instance";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { PgCampaignCookie } from "../affiliate.conts";

const CampaignParam = "c";

export function useAffiliateCampaignSend() {
    const location = useLocation();
    const [cookies, setCookies] = useCookies([PgCampaignCookie]);

    useAsync(async () => {
        const params = new URLSearchParams(location?.search).get(CampaignParam);

        if (!params || params === cookies[PgCampaignCookie]) {
            return;
        }

        setCookies(PgCampaignCookie, params, { maxAge: 60 * 60 * 24 * 30 });

        try {
            const response = await axiosInstance.post("/affiliate/visit", {
                campaign_url: `${window.origin}/?c=${params}`,
            });

            console.log(response);
        } catch (e) {
            console.error("Error when sending affiliate token", e);
        }
    }, [])
}