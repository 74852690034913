import { FC } from "react";
import PageContainer from "src/containers/PageContainer/PageContainer";
import { defaultSeoTitle, defaultSeoMetaDescription } from "src/containers/Games/constants/seoContent";
import GamesList from "src/components/games-list/GamesList";
import classNames from "classnames/bind";
import styles from "src/containers/Games/styles.scss";

const cx = classNames.bind(styles);

const GamesHomepage: FC = () => {
    return (
        <PageContainer
            showFooter showHeader dark pageTitle={defaultSeoTitle} pageDescription={defaultSeoMetaDescription} contentClass={cx("games-page")}
        >
            <GamesList />
        </PageContainer>
    );
};

export default GamesHomepage;