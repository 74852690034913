import { FC } from "react";
import { DialogContentType } from "src/feature/wallet/hooks/useWalletDialogContentParam";
import Icon from "@pg/design-system/src/components/icons/Icon";
import upperFirst from "lodash/upperFirst";

interface IBreadcrumbsProps {
    walletDialog: DialogContentType | null | undefined
    setWalletDialog(walletDialog: DialogContentType): void
}

const Breadcrumbs: FC<IBreadcrumbsProps> = ({ walletDialog, setWalletDialog }) => {
    if (!walletDialog || walletDialog === DialogContentType.Wallet) {
        return null;
    }

    return (
        <div className="flex flex-row items-center gap-1.5 h-9 pr-8">
            <Icon.ChevronLeft className="mr-2.5" />
            <span className="text-xs text-brand-primary-300 cursor-pointer" onClick={() => setWalletDialog(DialogContentType.Wallet)}>Wallet</span>
            <span className="text-xs text-brand-primary">/ {upperFirst(walletDialog)}</span>
        </div>
    );
};

export default Breadcrumbs;