interface IBottomFooterLinksProps {
    text: string;
    to?: string;
    href?: string;
    mailTo?: string;
}

export const bottomFooterLinks: IBottomFooterLinksProps[] = [
    { text: "About Peergame", to: "/about-us" },
    { text: "Games", to: "/games" },
    { text: "Peergame Partners", to: "/partners" },
    { text: "Provably Fair", to: "/provably-fair" },
    { text: "Bitcoin SV", to: "/bitcoinsv" },
    { text: "Payments", to: "/payments" },
    { text: "Responsible Gaming", to: "/responsible-gaming" },
    { text: "AML/KYC Policy", to: "/kyc-policy" },
    { text: "Complaints", to: "/complaints" },
    { text: "Privacy Policy", to: "/privacy-policy" },
    { text: "Terms of Service", to: "/terms-of-service" },
    { text: "Cookies", to: "/cookies" },
    { text: "Help Center", href: "https://help.peergame.com/" },
    { text: "Contact Us", mailTo: "mailto:contact@peergame.com" },
];