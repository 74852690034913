import { useMediaQuery } from "react-responsive";
import { getBreakpointMinWidth, getBreakpointMaxWidth } from "@pg/design-system/src/foundation/breakpoints/breakpoints.functions";
import { Breakpoint } from "@pg/design-system/src/foundation/breakpoints/breakpoints.const";

const useBreakpointUp = (breakpoint: Breakpoint) => useMediaQuery({minWidth: getBreakpointMinWidth(breakpoint)})
const useBreakpointDown = (breakpoint: Breakpoint) => useMediaQuery({maxWidth: getBreakpointMaxWidth(breakpoint)})
const useBreakpointOnly = (breakpoint: Breakpoint) => useMediaQuery({minWidth: getBreakpointMinWidth(breakpoint), maxWidth: getBreakpointMaxWidth(breakpoint)})

export const useMobileOnly = () => useBreakpointDown(Breakpoint.Sm);

export const useXsOnly = () => useBreakpointDown(Breakpoint.Xs)
export const useSmUp = () => useBreakpointUp(Breakpoint.Sm);
export const useMdUp = () => useBreakpointUp(Breakpoint.Md);
export const useMdOnly = () => useBreakpointOnly(Breakpoint.Md);
export const useLgUp = () => useBreakpointUp(Breakpoint.Lg);
export const useXlUp = () => useBreakpointUp(Breakpoint.Xl);
