import { useRef, useState, useEffect } from "react";
import classNames from "classnames/bind";
import { useMobileOnly } from "helpers/responsive";
import BottomDrawer from "src/components/bottom-drawer/BottomDrawer";
import Modal from "src/components/modal/Modal";
import useClickOutside from "helpers/useClickOutside";
import styles from "./styles.scss";
import useTableDeposit from "../GameBalance/useTableDeposit";
import useCashout from "./useCashout";

import Icon from "@pg/design-system/src/components/icons/Icon";
import { CryptoCurrency } from "src/core/currency/currency.model";
import CurrencyIcon from "src/components/currency-icon/CurrencyIcon";
import { useAuthorizedState } from "src/core/auth/hooks/useAuthorizedState";
import { WalletProvider } from "src/core/wallet/wallet.model";
import AuthButton from "src/feature/auth/AuthButton";
import { Button } from "@pg/design-system";
import { useIsPartnersPage } from "src/core/affiliate/hooks/useIsPartnersPage";

const cx = classNames.bind(styles);

export default function Cashout() {
    const isMobileOnly = useMobileOnly();
    const drawerRef = useRef(null);
    const buttonRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const { depositBalance } = useTableDeposit();
    const {
        pendingCashout,
        holdingCashout,
        cashout,
        isCashingOut,
        cancelHolding,
        isCancellingHolding,
        refetchHolding,
    } = useCashout();

    const { isAuthorized: isAuthorizedWithHandcash } = useAuthorizedState(WalletProvider.HandCash);

    const isPartnersPage = useIsPartnersPage();

    useEffect(() => {
        if (isOpen) {
            refetchHolding();
        }
    }, [isOpen]);

    function onCashout() {
        cashout().then(() => {
            setIsOpen(false);
        });
    }

    function onCancelCashout() {
        cancelHolding().then(() => {
            setIsOpen(false);
        });
    }

    useClickOutside(() => {
        setIsOpen(false);
    }, [drawerRef, buttonRef]);

    function getButtons() {
        const cancelButton = (
            <Button onClick={() => setIsOpen(false)} disabled={isCashingOut || isCancellingHolding}>
                Cancel
            </Button>
        );

        if (isAuthorizedWithHandcash) {
            if (!holdingCashout?.isZero()) {
                return (
                    <Button className={cx("bold", "cancel-cashout")} isLoading={isCancellingHolding} onClick={onCancelCashout}>
                        Cancel Pending Cashout
                    </Button>
                );
            }

            return (
                <>
                    {cancelButton}
                    <Button
                        className={cx("bold", "cashout", "flex items-center justify-center")}
                        isLoading={isCashingOut}
                        onClick={onCashout}
                        disabled={depositBalance?.isZero()}
                    >
                        Cashout
                    </Button>
                </>
            );
        }
        return (
            <>
                {cancelButton}
                <AuthButton
                    className={cx("bold", "connect-wallet")}
                    onClick={() => setIsOpen(false)}
                />
            </>
        );
    }

    const content = (
        <div className={cx("content")}>
            <p>Your game balance will be sent directly to your wallet.</p>
            <span className={cx("xsmall")}>GAME BALANCE</span>
            <div className={cx("balance")}>
                <CurrencyIcon currency={CryptoCurrency.BSV} />
                <span className={cx("bold", "large")}>{depositBalance?.toNumber() || "0.0000"}</span>
            </div>
            {pendingCashout && !pendingCashout.isZero() && (
                <div className={cx("pending-cashout")}>
                    <span className={cx("label", "xsmall")}>PENDING CASHOUT</span>
                    <CurrencyIcon currency={CryptoCurrency.BSV} />
                    <span className={cx("amount", "bold")}>{pendingCashout.toString()}</span>
                </div>
            )}
            <div className={cx("buttons")}>{getButtons()}</div>
        </div>
    );

    return (
        <>
            <button
                type="button"
                className={cx("cashout-button", "small", "flex items-center gap-2 p-2 rounded-sm", {
                    "text-brand-primary bg-brand-primary-200 hover:bg-brand-primary-300": isPartnersPage,
                })}
                onClick={() => setIsOpen(!isOpen)}
                ref={buttonRef}
            >
                <Icon.Logout />
                Cashout
            </button>
            {isMobileOnly ? (
                <BottomDrawer isOpen={isOpen} className={cx("mobile-drawer")} ref={drawerRef}>
                    <div className={cx("header", "cashout-header")}>
                        <h4>Cashout</h4>
                        <button type="button" onClick={() => setIsOpen(false)}>
                            <Icon.Close />
                        </button>
                    </div>
                    {content}
                </BottomDrawer>
            ) : (
                <Modal
                    isOpen={isOpen}
                    onClose={() => !isCashingOut && setIsOpen(false)}
                    header="Cashout"
                    modalClassName={cx("modal")}
                >
                    {content}
                </Modal>
            )}
        </>
    );
}