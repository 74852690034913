import { FC, ReactNode } from "react";
import { Button, ButtonVariant } from "@pg/design-system";
import { cn } from "@ntropy/utils/src/cn";

const WalletButton: FC<{ children?: ReactNode, className?: string }> = ({ children = "Wallet", className, ...props } = {}) => {
    return (
        <Button
            // empty props required by DialogTrigger
            {...props}
            variant={ButtonVariant.OUTLINE}
            className={cn(
                "text-md bg-brand-primary-500 rounded-r-[4px] rounded-l-none text-brand-primary-200 hover:text-brand-primary-200 hover:bg-support-purple-300 min-h-full ml-0.5 h-auto self-stretch",
                className,
            )}
        >
            {children}
        </Button>
    );
};

export default WalletButton;