import { forwardRef, ReactNode } from "react";
import { useTransition, animated } from "@react-spring/web";
import classNames from "classnames/bind";
import styles from "./styles.scss";
import { getBottomDrawerAnimation } from "src/components/bottom-drawer/bottom-drawer.animation";

interface IBottomDrawerProps {
    isOpen: boolean;
    children: ReactNode;
    className?: string;
    unmountOnClose?: boolean;
}

const cx = classNames.bind(styles);

const BottomDrawer = forwardRef<HTMLDivElement, IBottomDrawerProps>(({
    isOpen, children, className = "", unmountOnClose = false,
}, ref) => {
    const transition = useTransition(isOpen, getBottomDrawerAnimation(unmountOnClose));

    return transition(
        (style, item) => item && (
            <animated.div ref={ref} className={cx("drawer", className)} style={style}>
                {children}
            </animated.div>
        )
    );
});

BottomDrawer.displayName = "BottomDrawer";

export default BottomDrawer;

