import { ReactElement } from "react";
import Animated1ChipSVG from "src/games/components/AnimatedChips/Animated1ChipSVG";
import Animated01ChipSVG from "../components/AnimatedChips/Animated01ChipSVG";
import Animated001ChipSVG from "../components/AnimatedChips/Animated001ChipSVG";
import Animated005ChipSVG from "../components/AnimatedChips/Animated005ChipSVG";

export const baccaratOptions = {
    P: {
        label: "Player",
    },
    B: {
        label: "Banker",
    },
    T: {
        label: "Tie",
    },
    PP: {
        label: "Player Pair",
    },
    BP: {
        label: "Banker Pair",
    },
};

type Chip = {
    [key: number]: ReactElement;
}

let chips: Chip = {
    1: <Animated1ChipSVG applyRotation={undefined} />,
    0.1: <Animated01ChipSVG applyRotation={undefined} />,
    0.01: <Animated001ChipSVG applyRotation={undefined} />,
    0.05: <Animated005ChipSVG applyRotation={undefined} />,
};
if (process.env.REDUCED_BETS === "true") {
    const newChips = {};
    Object.entries(chips).forEach(([value, chip]) => {
        newChips[Number(value) / 100] = chip;
    });
    chips = newChips;
}

export { chips };
