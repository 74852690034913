import AreYouAdult from "src/components/Popup/AreYouAdult";
import { RouteObject, Outlet, useLocation, Navigate } from "react-router-dom";
import { useAppSelector } from "src/redux/reducer";
import CountryRestriction from "src/static/Restriction/country_restriction";
import { Path } from "src/core/paths/paths";
import GamesPagesCrossroad from "src/containers/Games/pages/GamesPagesCrossroad";

interface RootState {
    info: {
        accessAvailable: boolean;
        maintenance: {
            code: string;
            game: { name: string; is_maintenance: string }[];
        };
    };
}

export const Games = () => {
    const { pathname } = useLocation();
    const accessAvailable = useAppSelector((state: RootState) => state.info?.accessAvailable);

    if (pathname === Path.InHouse) {
        return <Navigate to={Path.Originals} replace />;
    }

    if (!pathname.startsWith(Path.Games)) {
        return null;
    }

    if (!accessAvailable) {
        return <CountryRestriction />;
    }

    return (
        <>
            <AreYouAdult />
            <Outlet />
        </>
    );
};

export const gamesRoutes: RouteObject[] = [
    {
        path: Path.Games,
        element: <GamesPagesCrossroad />,
    },
    {
        path: Path.GamesCategory,
        element: <GamesPagesCrossroad />,
    },
    {
        path: Path.GamesCategoryWithId,
        element: <GamesPagesCrossroad />,
    },
];