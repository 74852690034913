import { ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import Header from "src/components/header/Header";
import Footer from "src/components/footer/Footer";
import { cn } from "@ntropy/utils/src/cn";

const defaultPageDescription = "Bitcoin SV game platform - No Registration · Instant Payouts · Provable Fairness - "
    + "In-house games Powered by BSV blockchain.";

interface IPageContainerProps {
    showHeader?: boolean;
    showFooter?: boolean;
    children: ReactNode;
    pageTitle?: string;
    pageDescription?: string;
    dark?: boolean;
    contentClass?: string;
}

const PageContainer = ({
    showHeader = true,
    showFooter = true,
    children,
    pageTitle,
    pageDescription = defaultPageDescription,
    dark = false,
    contentClass,
}: IPageContainerProps) => {
    const defaultTitle = "Casino | Secure & Fast Payouts | Peergame"
    const title = pageTitle || defaultTitle;

    return (
        <>
            <Helmet defer={false} bodyAttributes={{ ["style" as string]: dark && "background: #12033d;" }}>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta name="description" content={pageDescription} />
            </Helmet>
            {showHeader && <Header classes={dark ? ["transparent"] : []} />}
            <div className={cn(contentClass, "max-w-[1448px] mx-auto")}>{children}</div>
            {showFooter && <Footer />}
        </>
    );
};

export default PageContainer;
