import { useHandleWalletConnectModal } from "src/feature/auth/components/wallet-connect-button/hooks/useHandleWalletConnect";
import { WalletProvider } from "src/core/wallet/wallet.model";
import AuthActionButton from "src/feature/auth/components/AuthActionButton";

interface IWalletConnectButtonProps {
    closeBaseAuthModal?: () => void
}

export const WalletConnectButton = ({ closeBaseAuthModal }: IWalletConnectButtonProps) => {
    const { openWalletConnectModal } = useHandleWalletConnectModal(closeBaseAuthModal);

    return (
        <AuthActionButton walletProvider={WalletProvider.WalletConnect} onClick={openWalletConnectModal} />
    );
}

