import { FC } from "react";
import { CryptoCurrency } from "src/core/currency/currency.model";
import { formatCrypto, formatFiat } from "src/core/currency/currency.function";
import CurrencyIcon from "src/components/currency-icon/CurrencyIcon";
import { cn } from "@ntropy/utils/src/cn";
import { useConvertToUsd } from "src/core/currency/hooks/useConvertToUSD";
import BigNumber from "bignumber.js";
import { useIsPartnersPage } from "src/core/affiliate/hooks/useIsPartnersPage";

interface IWalletMenuElementProps {
    className?: string
    asset: CryptoCurrency
    setSelectedAsset(asset: CryptoCurrency): void
    balance: BigNumber | undefined
    showUsd: boolean
    selected?: boolean
}

const WalletMenuElement: FC<IWalletMenuElementProps> = ({
    className,
    asset,
    balance,
    setSelectedAsset,
    showUsd,
    selected,
}) => {
    const usdValue = useConvertToUsd(balance, asset, { enabled: showUsd });

    const isPartnersPage = useIsPartnersPage();

    return (
        <button
            type="button"
            className={cn(
                "flex items-center gap-2 justify-start w-full py-2 px-4 rounded font-bold text-brand-primary-200 hover:bg-support-purple-200",
                className,
                {
                    "mb-2": asset === CryptoCurrency.BSV,
                    ":not(:hover):support-purple-300": selected,
                    "text-brand-primary bg-brand-primary-200 hover:bg-brand-primary-300": isPartnersPage,
                },
            )}
            onClick={() => setSelectedAsset(asset)}
            title={`${formatCrypto(balance)} ${asset}`}
        >
            <span className="inline-flex mr-auto">
              {showUsd ? `$ ${formatFiat(usdValue) || "0.00"}` : formatCrypto(balance)}
            </span>
            <CurrencyIcon currency={asset} className="w-6" />
            <span className="w-[40px] text-left">{asset}</span>
        </button>
    );
};

export default WalletMenuElement;