export const loadYolaScript = (id: string | undefined): Promise<void> => {
    window.protoSettings = {
        id: id ?? "",
    };

    return new Promise((resolve, reject) => {
        if (!id) {
            reject();
            return;
        }

        const prs = document.createElement("script");
        prs.src = "https://embed.yola.ai/index.umd.js";
        prs.type = "text/javascript";
        prs.async = true;
        prs.onload = () => {
            window.proto.init(window.protoSettings);
            resolve();
        }
        prs.onerror = reject;
        document.head.appendChild(prs);
    });
};