import { FC } from "react";
import PageContainer from "src/containers/PageContainer/PageContainer";
import GamesList from "src/components/games-list/GamesList";
import { useGamesLocation } from "src/containers/Games/hooks/useGamesLocation";
import { useMaintenance } from "src/core/maintenance/hooks/useMaintenance";
import Maintenance from "src/static/Maintenance";
import { CasinoGameCategory, isCasinoGameCategory } from "src/core/casino/casino.model";
import { slotsSeoMetaDescription, defaultSeoMetaDescription, slotsSeoTitle, defaultSeoTitle } from "src/containers/Games/constants/seoContent";
import classNames from "classnames/bind";
import styles from "src/containers/Games/styles.scss";
import { useGameSelectedAssetCheck } from "src/containers/Games/hooks/useGameSelectedAssetCheck";

const cx = classNames.bind(styles);

const GamesCategoryPage: FC = () => {
    const { gameCode: category } = useGamesLocation<CasinoGameCategory>();
    const isMaintenance = useMaintenance({ forGame: category });

    useGameSelectedAssetCheck();

    if (isMaintenance) {
        return <Maintenance />;
    }

    if (!isCasinoGameCategory(category)) {
        return null;
    }

    const isSlotsCategory = category.toLowerCase() === CasinoGameCategory.Slots;
    const seoMetaDescription = isSlotsCategory ? slotsSeoMetaDescription : defaultSeoMetaDescription
    const seoTitle = isSlotsCategory ? slotsSeoTitle : defaultSeoTitle

    return (
        <PageContainer showFooter showHeader dark pageTitle={seoTitle} pageDescription={seoMetaDescription} contentClass={cx("games-page")}>
            <GamesList />
        </PageContainer>
    );
};

export default GamesCategoryPage;