import { useEffect, useState } from "react";
import useAppStore, { IAsset } from "src/useAppStore";

interface IUseHandcashWalletBalanceInput {
    enabled: boolean
}


export interface IColorChange {
    color: string | null;
    asset: string | null;
}

export function useHandcashWalletBalance({ enabled }: IUseHandcashWalletBalanceInput) {
    const [colorChange, setColorChange] = useState<IColorChange>({ color: null, asset: null });
    useEffect(() => {
        if (!enabled) {
            return;
        }

        useAppStore.getState().loadAssetBalance();
        const intervalId = setInterval(() => {
            useAppStore.getState().loadAssetBalance();
        }, 1000 * 60);

        const unsubscribe = useAppStore.subscribe(
            (asset: IAsset, prevAsset: IAsset) => {
                if (!prevAsset.isLoading) {
                    const { balance: newBalance } = asset;
                    const { balance: prevBalance } = prevAsset;
                    if (newBalance.BSV && prevBalance.BSV && !newBalance.BSV?.isEqualTo(prevBalance.BSV)) {
                        setColorChange({ color: newBalance.BSV.isGreaterThan(prevBalance.BSV!) ? "green" : "red", asset: "BSV" });
                    } else if (newBalance.PGP && prevBalance.PGP && !newBalance.PGP.isEqualTo(prevBalance.PGP)) {
                        setColorChange({ color: newBalance.PGP.isGreaterThan(prevBalance.PGP) ? "green" : "red", asset: "PGP" });
                    }
                }
            },
            state => state.asset,
        );

        return () => {
            unsubscribe();
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (colorChange.color) {
            timeoutId = setTimeout(() => setColorChange({ color: null, asset: null }), 1500);
        }
        return () => clearTimeout(timeoutId);
    }, [colorChange]);

    return { colorChange };
}