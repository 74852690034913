import { FC, useEffect, useCallback } from "react";
import { Navigate } from "react-router-dom";
import { Path } from "src/core/paths/paths";
import { warningToast } from "src/helpers/toast";
import { useAuthModalState } from "src/feature/auth/hooks/useAuthModalState";

export const navigateToLoginWarning = () => warningToast("Login to access all features");

export const useTriggerAuthModal = ({ warn = false }: { warn?: boolean } = {}) => {
    const [_, setModalOpen] = useAuthModalState();

    return useCallback(() => {
        if (warn) navigateToLoginWarning();

        setModalOpen(true)
    }, [setModalOpen, warn]);
}

export const NavigateToLogin: FC<{ affiliate?: boolean }> = ({
    affiliate,
}) => {
    const setModalOpen = useTriggerAuthModal();

    useEffect(() => {
        navigateToLoginWarning();

        if (!affiliate) setModalOpen();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (affiliate) {
        return <Navigate to={Path.PartnersLogin} />;
    } else {
        return <Navigate to={Path.Home} />;
    }
}