import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useRef,
} from "react";
import isEqual from "react-fast-compare";
import localStorageService, {LocalStorageType} from "../local-storage.service";
import {useForceUpdate} from "@ntropy/hooks/src/lifecycle/useForceUpdate";
import { LocalStorageKey } from "src/core/local-storage/local-storage.model";

export function useLocalStorage<T extends LocalStorageType>(key: LocalStorageKey, defaultValue: T): [T, Dispatch<SetStateAction<T>>] {
    const stateRef = useRef<T>(localStorageService.get<T>(key) ?? defaultValue);
    const forceUpdate = useForceUpdate();

    useEffect(() => {
        if (window.localStorage.getItem(key) === null) {
            localStorageService.set<T>(key, defaultValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSetState = useCallback(
        (setter: SetStateAction<T>) => {
            stateRef.current = typeof setter === "function" ? setter(stateRef.current) : setter;
            forceUpdate();
            localStorageService.set<T>(key, stateRef.current);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [stateRef],
    );

    const handleStateChange = useCallback((newState: T) => {
        if (isEqual(stateRef.current, newState)) {
            return;
        }

        stateRef.current = newState;
        forceUpdate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        localStorageService.on(key, handleStateChange);

        return () => {
            localStorageService.off(key, handleStateChange);
        };
    }, [handleStateChange, key]);

    return [stateRef.current, handleSetState];
}
