import { strictEquals } from "@ntropy/utils/src/compare-utils";

export const areEqual = <T>(...values: T[]) => {
    for (const v1 of values) {
        if (!values.every(v2 => strictEquals(v1, v2))) {
            return false;
        }
    }

    return true;
}

export enum SortDirection {
    Ascending = "asc",
    Descending = "desc",
    None = "none",
}

export type SetSortDirection = SortDirection.Ascending | SortDirection.Descending;

export const getOtherSortDirection = (direction?: SortDirection | null) => {
    switch (direction) {
        case SortDirection.Ascending:
            return SortDirection.Descending;
        case SortDirection.Descending:
            return SortDirection.Ascending;
    }

    return null;
}

export enum ComparisonResult {
    GreaterThan = 1,
    LessThan = -1,
    Equal = 0,
}

export type SortOrder = "descend" | "ascend" | null;
export type ICompareFn<T, P = undefined> = P extends undefined ? ((a: T, b: T) => ComparisonResult) : ((a: T, b: T, p: P) => ComparisonResult)

export const compareBy = <T, P = undefined>(
    additionalPropsOrComparator: ICompareFn<T> | P,
    ...comparators: ICompareFn<T, P>[]
) => {
    const isFirstArgAComparator = typeof additionalPropsOrComparator === "function";

    return function composedComparator(a: T, b: T) {
        const comparatorsToIterate: ICompareFn<T, P>[] = isFirstArgAComparator ?
            [additionalPropsOrComparator as ICompareFn<T, P>, ...comparators]
            : comparators;

        return comparatorsToIterate.reduce((comparisonResult, comparerFunction) => {
            if (comparisonResult !== ComparisonResult.Equal) {
                return comparisonResult;
            }

            return comparerFunction(a, b, (isFirstArgAComparator ? undefined : additionalPropsOrComparator) as P);
        }, ComparisonResult.Equal)
    }
};

export const sortByIndexInArray = <T>(valueA: T, valueB: T, array: T[], lastIfNotIncluded = true): ComparisonResult => {
    let indexA = array.indexOf(valueA);
    let indexB = array.indexOf(valueB);

    const aNotFound = indexA === -1;
    const bNotFound = indexB === -1;

    if (aNotFound && bNotFound) return 0;

    if (lastIfNotIncluded && aNotFound) indexA = Infinity;
    if (lastIfNotIncluded && bNotFound) indexB = Infinity;

    return indexA - indexB;
};

export const standardSort = (a: any, b: any) => a > b ? ComparisonResult.GreaterThan : b > a ? ComparisonResult.LessThan : ComparisonResult.Equal;
export const booleanSort = (a: boolean, b: boolean) => ((a && b) || (!a && !b) ? ComparisonResult.Equal : a ? ComparisonResult.LessThan : ComparisonResult.GreaterThan);
export const nullCheckSort = (a: any, b: any) => (a === null && b === null ? ComparisonResult.Equal : a === null ? ComparisonResult.LessThan : ComparisonResult.GreaterThan);

export const nullSafeStandardSort = (a: any, b: any) => {
    const isASet = !(a === null || a === undefined);
    const isBSet = !(b === null || b === undefined);

    if (!isASet && !isBSet) return ComparisonResult.Equal;

    if (!isASet) return ComparisonResult.LessThan;

    if (!isBSet) return ComparisonResult.GreaterThan;

    if (a > b) {
        return ComparisonResult.GreaterThan
    }
    return (b > a) ? ComparisonResult.LessThan : ComparisonResult.Equal
};

export const getStandardSort = <T extends object, K extends keyof T>(
    propToSortBy: K,
    getFn?: (objToGetFrom: T[K], record: T) => any,
    caseInsensitive?: undefined | boolean,
    withNullsAtBottom = false,
) => {
    return (a: T, b: T, sortOrder?: SortOrder) => {
        let valueA; let valueB;

        if (getFn) {
            valueA = getFn(a[propToSortBy], a);
            valueB = getFn(b[propToSortBy], b);
        } else {
            valueA = a[propToSortBy];
            valueB = b[propToSortBy];
        }

        if (caseInsensitive !== false && typeof valueA === "string" && typeof valueB === "string") {
            valueA = valueA.toLowerCase();
            valueB = valueB.toLowerCase();
        }

        if (withNullsAtBottom && (valueA === null || valueB === null)) {
            const sign = sortOrder === "ascend" ? -1 : 1;
            return sign * nullCheckSort(valueA, valueB);
        }

        return standardSort(valueA, valueB);
    };
};

export const getStandardSortWithNullCheck = <T extends object | null, K extends keyof T>(propToSortBy: K) => {
    return (a: T, b: T) =>
        a === null || b === null
            ? -booleanSort(a as any, b as any)
            : a![propToSortBy] === null || b![propToSortBy] === null
                ? -booleanSort(a![propToSortBy] as any, b![propToSortBy] as any)
                : standardSort(a![propToSortBy], b![propToSortBy]);
};

export const getBooleanSort = <T extends object, K extends keyof T>(propToSortBy: K, getFn?: (objToGetFrom: T[K], record: T) => any) => {
    return (a: T, b: T) =>
        getFn ? booleanSort(getFn(a[propToSortBy], a) as any, getFn(b[propToSortBy], b) as any) : booleanSort(a[propToSortBy] as any, b[propToSortBy] as any);
};

export const getBooleanSortWithNullCheck = <T extends object, K extends keyof T>(propToSortBy: K, getFn?: (objToGetFrom: T[K], record: T) => any) => {
    return (a: T, b: T) => {
        const aToCheck = getFn ? getFn(a[propToSortBy], a) : a[propToSortBy];
        const bToCheck = getFn ? getFn(b[propToSortBy], b) : b[propToSortBy];

        return aToCheck === null || bToCheck === null ? nullCheckSort(aToCheck as any, bToCheck as any) : booleanSort(aToCheck as any, bToCheck as any);
    };
};