/* eslint-disable max-len*/
import * as React from "react";
import { ComponentType, ReactElement } from "react";
import { unwrapName } from "./component-utils";
import { CallbackFn, WithOptionalKeys } from "@ntropy/utils/src/typescript-helpers";

type HigherOrderComponentFactoryFunction = (InnerComponent: ComponentType<any>, ...parameters: any[]) => ComponentType<any>;

const isDisplayNameSet = (firstFactoryFnOrDisplayName: HigherOrderComponentFactoryFunction | string): firstFactoryFnOrDisplayName is string => typeof firstFactoryFnOrDisplayName === "string";

export function compose<P = {}>(
    InnerComponent: React.ComponentType<any>,
    displayName: string,
    ...hocFactoryFunctions: HigherOrderComponentFactoryFunction[]
): React.ComponentType<P>;
export function compose<P = {}>(
    InnerComponent: React.ComponentType<any>,
    ...hocFactoryFunctions: HigherOrderComponentFactoryFunction[]
): React.ComponentType<P>;
export function compose<P = {}>(
    InnerComponent: React.ComponentType<P>,
    firstFactoryFunctionOrDisplayName: HigherOrderComponentFactoryFunction | string,
    ...hocFactoryFunctions: HigherOrderComponentFactoryFunction[]
): React.ComponentType<P> {
    const factoryFunctions = isDisplayNameSet(firstFactoryFunctionOrDisplayName) ?
        hocFactoryFunctions : [firstFactoryFunctionOrDisplayName, ...hocFactoryFunctions];

    const ComposedInnerComponent = factoryFunctions.reduce((extendedInnerComponent, hocFactoryFunction) => {
        return hocFactoryFunction(extendedInnerComponent);
    }, InnerComponent);

    if (isDisplayNameSet(firstFactoryFunctionOrDisplayName)) {
        ComposedInnerComponent.displayName = `Compose(${firstFactoryFunctionOrDisplayName})`;
    } else {
        ComposedInnerComponent.displayName = `Compose(${unwrapName(InnerComponent.displayName)})`;
    }

    return ComposedInnerComponent;
}

type ComposedComponent<TProps, TAdditionalProps = {}> = (InnerComponent: ComponentType<TProps & TAdditionalProps>) => (props: TProps) => ReactElement<TProps & TAdditionalProps>;

export function withHOCProvider<TProviderProps extends {
    children?: any
}, TComponentProps = {}>(Provider: ComponentType<TProviderProps>, hookProvidingProps: CallbackFn<[TComponentProps], WithOptionalKeys<TProviderProps, "children">>, providerPropsToInner: true): ComposedComponent<TComponentProps, TProviderProps>
export function withHOCProvider<TProviderProps extends {
    children?: any
}, TComponentProps = {}>(Provider: ComponentType<TProviderProps>, hookProvidingProps?: CallbackFn<[TComponentProps], WithOptionalKeys<TProviderProps, "children">>, providerPropsToInner?: false): ComposedComponent<TComponentProps>
export function withHOCProvider<TProviderProps extends { children?: any }, TComponentProps = {}>(
    Provider: ComponentType<TProviderProps>,
    hookProvidingProps: CallbackFn<[TComponentProps], WithOptionalKeys<TProviderProps, "children">> = () => ({} as TProviderProps),
    providerPropsToInner?: boolean,
) {
    return (InnerComponent: ComponentType<TComponentProps>) => {
        const useHook = hookProvidingProps;
        return (props: TComponentProps) => {
            const providerProps = useHook(props);
            const innerProps = providerPropsToInner ? { ...providerProps, ...props } : props;

            return (
                <Provider {...providerProps as TProviderProps}>
                    <InnerComponent key={undefined} {...innerProps as TComponentProps} />
                </Provider>
            );
        }
    };
}