import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const trailingSlashRegExp = /(\/+)$/;

const trailingSlashCheck = (pathname: string) => {
    const hasTrailingSlash = trailingSlashRegExp.test(pathname);
    const finalPathname = hasTrailingSlash ? pathname.replace(trailingSlashRegExp, "") : pathname;

    return [hasTrailingSlash, finalPathname] as const;
}

export function useRemoveTrailingSlash() {
    const { pathname } = useLocation();
    const [hasTrailingSlash, finalPathname] = useMemo(() => trailingSlashCheck(pathname), [pathname]);

    return {
        hasTrailingSlash,
        finalPathname,
    }
}