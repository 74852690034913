import { DependencyList, useEffect } from "react";

export const useAsync = (
    callback: () => Promise<void | (() => void | undefined)>,
    deps?: DependencyList,
) => {
    useEffect(() => {
        const returnedPromise = callback();

        return () => {
            (async () => {
                const promiseValue = await returnedPromise;

                if (promiseValue && typeof promiseValue === "function") {
                    promiseValue();
                }
            })();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};
