import classNames from "classnames/bind";
import { useQueryClient } from "@tanstack/react-query";
import styles from "./assets/styles.scss";
import useCountdown from "src/helpers/use-countdown";

interface ICountdownTimerProps {
    endsAt: string;
    raceType: string;
}

const cx = classNames.bind(styles);

export default function CountdownTimer({ endsAt = "", raceType = "" }: ICountdownTimerProps) {
    const queryClient = useQueryClient();
    const {
        days, hours, minutes, seconds,
    } = useCountdown(endsAt, () => queryClient.invalidateQueries({
        queryKey: ["settings", "races",
        ],
    }));

    let timeComponent;

    if (raceType === "weekly") {
        timeComponent = `${days[1]}d ${hours}:${minutes}:${seconds}`;
    } else {
        timeComponent = `${hours}:${minutes}:${seconds}`;
    }

    return (
        <div className={cx("countdown-timer")}>
            <div className={cx("timer-row")}>
                <h6>{timeComponent}</h6>
            </div>
        </div>
    );
}

