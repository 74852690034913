import { useAppKit } from "@reown/appkit/react"

export const useHandleWalletConnectModal = (onModalClose?: () => void) => {
    const { open, close } = useAppKit()

    const openWalletConnectModal = async () => {
        await open({ view: "Connect" })
        onModalClose?.()
    }

    const closeWalletConnectModal = async () => {
        await close()
    }

    return {
        openWalletConnectModal,
        closeWalletConnectModal,
    }
}