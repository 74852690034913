import { useContext, useRef, useState, useMemo } from "react";
import classNames from "classnames/bind";
import styles from "./styles.scss";
import GameBalance from "./GameBalance/GameBalance";
import Cashout from "./Cashout/Cashout";
import useTableDeposit from "./GameBalance/useTableDeposit";
import GameCodeContext from "src/containers/Games/GameCodeContext";
import useClickOutside from "src/helpers/useClickOutside";
import useLocationChange from "src/helpers/useLocationChange";
import { useHandcashWalletBalance } from "src/components/header/hooks/useHandcashWalletBalance";
import { useMaintenance } from "src/core/maintenance/hooks/useMaintenance";
import { CryptoCurrency } from "src/core/currency/currency.model";
import map from "lodash/map";
import { Switch, Skeleton, SkeletonVariant } from "@pg/design-system";
import { useLocalStorage } from "src/core/local-storage/hooks/useLocalStorage";
import { LocalStorageKey } from "src/core/local-storage/local-storage.model";
import { formatCrypto } from "src/core/currency/currency.function";
import WalletMenuElement from "src/components/header/WalletMenu/WalletMenuElement";
import WalletMenuTrigger from "src/components/header/WalletMenu/WalletMenuTrigger";
import { useCurrencyAssetState } from "src/components/header/hooks/useCurrencyAssetState";
import { useWalletAccounts } from "src/core/wallet/hooks/useWalletAccounts/useWalletAccounts";
import { useWalletAccountsSubscription } from "src/core/wallet/hooks/useWalletAccounts/useWalletAccountsSubscription";
import { useIsPartnersPage } from "src/core/affiliate/hooks/useIsPartnersPage";
import { cn } from "@ntropy/utils/src/cn";

const cx = classNames.bind(styles);

interface IMyPointsProps {
    reverse?: boolean;
    forWalletConnect?: boolean;
}

function WalletMenu({ reverse = false, forWalletConnect = false }: IMyPointsProps) {
    const popupRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const gameCode = useContext(GameCodeContext);

    const [showUsd, setShowUsd] = useLocalStorage<boolean>(LocalStorageKey.WalletCurrencyUsd, false);
    const toggleShowUsd = () => setShowUsd(x => !x);

    const showUsdToUse = forWalletConnect && showUsd;

    const [hideZeros, setHideZeros] = useLocalStorage<boolean>(LocalStorageKey.WalletHideZeros, false);
    const toggleHideZeros = () => setHideZeros(x => !x);

    const hideZerosToUse = forWalletConnect && hideZeros;

    const [isOpen, setIsOpen] = useState(false);

    const {
        selectedAsset,
        setSelectedAsset,
        balance: balanceForHandcash,
        isLoading,
    } = useCurrencyAssetState();

    const maintenance = useMaintenance({ forWallet: "deposit" });
    const { depositBalance } = useTableDeposit();

    const accounts = useWalletAccounts({
        enabled: forWalletConnect,
    });

    useWalletAccountsSubscription();

    const balanceForWalletConnect = useMemo(() => {
        let accountsToUse = accounts;

        if (hideZerosToUse) {
            accountsToUse = accountsToUse.filter(a => !!a.balance);
        }

        return accountsToUse.reduce((acc, account) => ({
            ...acc,
            [account.currencyTicker]: account.balance,
        }), {} as typeof balanceForHandcash)
    }, [accounts, hideZerosToUse]);

    const balance = forWalletConnect ? balanceForWalletConnect : balanceForHandcash;

    useClickOutside(() => {
        setIsOpen(false);
    }, [popupRef, buttonRef]);
    useLocationChange(() => setIsOpen(false));

    const { colorChange } = useHandcashWalletBalance({
        enabled: !forWalletConnect,
    });

    function setAsset(asset: CryptoCurrency) {
        setSelectedAsset(asset);
        setIsOpen(false);
    }

    const isPartnersPage = useIsPartnersPage();

    return (
        <div className="relative flex">
            {isLoading ?
                <Skeleton
                    className={cn("h-[52px] w-[180px]", {
                        "bg-brand-primary-200": isPartnersPage,
                        "bg-brand-primary": !isPartnersPage,
                    })}
                    variant={isPartnersPage ? SkeletonVariant.Light : SkeletonVariant.Dark}
                /> :
                <WalletMenuTrigger
                    ref={buttonRef}
                    withWalletButton={forWalletConnect}
                    isMenuOpen={isOpen}
                    onClick={() => setIsOpen(!isOpen)}
                    asset={selectedAsset}
                    balance={balance?.[selectedAsset]}
                    depositBalance={depositBalance}
                    colorChange={colorChange}
                    gameCode={gameCode}
                    showUsd={showUsdToUse}
                />
            }


            <div
                className={cx(
                    "wallet-menu-popup",
                    {
                        open: isOpen,
                        reverse,
                    }
                )}
                ref={popupRef}
                title={formatCrypto(balance.BSV)}
            >
                <div className={cx("popup-list", "py-2 pg-scrollbar overflow-y-auto")}>
                    {map(balance, (balance, asset: CryptoCurrency) => (
                        <WalletMenuElement
                            key={asset}
                            className={cx({
                                selected: asset === selectedAsset,
                            })}
                            balance={balance}
                            asset={asset}
                            setSelectedAsset={setAsset}
                            showUsd={showUsdToUse}
                        />
                    ))}

                    {!forWalletConnect &&
                        <div className="flex flex-col gap-2">
                            <span className={cx("small", "label")}>Game Balance</span>
                            {maintenance ? (
                                <span className={cx("maintenance")}>Game Balance feature is under maintenance. Please try again later.</span>
                            ) : (
                                <>
                                    <GameBalance />
                                    <Cashout />
                                </>
                            )}
                        </div>
                    }
                </div>

                {forWalletConnect &&
                    <>
                        <div
                            className="flex items-center justify-start gap-4 mx-3 py-3 min-h-10 border-t-brand-primary-300 border-t-[1px]"
                            onClick={toggleShowUsd}
                        >
                            <Switch checked={showUsd} />
                            <span className="text-brand-primary-200 font-bold">Display in USD</span>
                        </div>
                        <div
                            className="flex items-center justify-start gap-4 mx-3 pb-3 min-h-10"
                            onClick={toggleHideZeros}
                        >
                            <Switch checked={hideZeros} />
                            <span className="text-brand-primary-200 font-bold">Hide zero balances</span>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default WalletMenu;