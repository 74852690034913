import { Carousel, CarouselContent, CarouselApi, ButtonVariant } from "@pg/design-system";
import { useState } from "react";
import { ChevronRight, ChevronLeft, ArrowRight } from "lucide-react";
import { Button } from "@pg/design-system";
import { cn } from "@ntropy/utils/src/cn";
import { Link } from "react-router-dom";

interface IBasicCarouselProps {
    children?: React.ReactNode;
    displayArrows?: boolean;
    arrowsClassName?: string;
    seeAllLink?: string
}

export const BasicCarousel = ({ children, displayArrows = true, arrowsClassName, seeAllLink }: IBasicCarouselProps) => {
    const [api, setApi] = useState<CarouselApi>()

    const arrowButtonStyles = "flex items-center justify-center p-2"

    return (
        < >
            {displayArrows &&
                <div className={cn("flex gap-1 items-center mb-4 text-white", arrowsClassName)}>
                    <Button onClick={() => api?.scrollPrev()} variant={ButtonVariant.GHOST} className={arrowButtonStyles} aria-label="Scroll left">
                        <ChevronLeft className="w-5 h-5" />
                    </Button>

                    <Button onClick={() => api?.scrollNext()} variant={ButtonVariant.GHOST} className={arrowButtonStyles} aria-label="Scroll right">
                        <ChevronRight className="w-5 h-5" />
                    </Button>
                    {!!seeAllLink &&
                        <Link to={seeAllLink} className="ml-auto inline-flex items-center flex-nowrap gap-4 text-sm hover:font-bold transition-all p-2 pr-0">
                            See all <ArrowRight className="size-4" />
                        </Link>
                    }
                </div>
            }

            <Carousel className="w-full" setApi={setApi} opts={{ loop: true, align: "start", slidesToScroll: "auto" }}>
                <CarouselContent>
                    {children}
                </CarouselContent>
            </Carousel>
        </>

    )
}