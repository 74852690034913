import Logo from "@pg/design-system/src/components/logo/Logo";
import classNames from "classnames/bind";

const styles = require("./assets/styles.scss").default;

const cx = classNames.bind(styles);

const description = "Our website is currently undergoing maintenance.";

const SiteMaintenance = () => (
    <>
        <div className={cx("site_maintenance")}>
            <div>
                <div className={cx("logo")}>
                    <Logo className={cx("peergame-logo")} dark />
                </div>
                <div className={cx("title")}>WE'LL BE BACK SHORTLY</div>
                <div className={cx("description")}>
                    <div>
                        {description} <br />
                        Thank you for your patience.
                    </div>
                </div>
                <div className={cx("contact")}>
                    <p>Contact us &nbsp;</p>
                    <p className={cx("email-address")}>contact@peergame.com</p>
                </div>
            </div>
        </div>
    </>
);

export default SiteMaintenance;
