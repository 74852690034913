import { FC } from "react";
import { useGamesLocation } from "src/containers/Games/hooks/useGamesLocation";
import { isOriginalGame, isCasinoGameCategory } from "src/core/casino/casino.model";
import GamesHomepage from "src/containers/Games/pages/GamesHomepage";
import GamePage from "src/containers/Games/pages/GamePage";
import GamesCategoryPage from "src/containers/Games/pages/GamesCategoryPage";

const GamesPagesCrossroad: FC = () => {
    const { gameCode, identifier } = useGamesLocation();

    if (identifier || isOriginalGame(gameCode)) {
        return <GamePage />
    }

    if (isCasinoGameCategory(gameCode)) {
        return <GamesCategoryPage />
    }

    return <GamesHomepage />
};

export default GamesPagesCrossroad;