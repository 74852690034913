export interface IClientMiddlewareAction<TData = any> {
    promise: () => Promise<TData>
    types: [string, string, string]
}

const isClientMiddlewareAction = (action: any): action is IClientMiddlewareAction => {
    return !!action && typeof action === "object" && "promise" in action && "types" in action;
}

export default function clientMiddleware() {
    return ({dispatch, getState}) => next => action => {
        if (typeof action === "function") {
            return action(dispatch, getState);
        }

        if (!isClientMiddlewareAction(action)) {
            return next(action);
        }

        const {promise, types, ...rest} = action;
        const [REQUEST, SUCCESS, FAILURE] = types;

        next({...rest, type: REQUEST});

        const actionPromise = promise();
        actionPromise
            .then(result => next({...rest, result, type: SUCCESS}), error => next({...rest, error, type: FAILURE}))
            .catch(error => {
                console.error("MIDDLEWARE ERROR:", error);
                next({...rest, error, type: FAILURE});
            });

        return actionPromise;
    };
}
