// Generated by generate-icons script. DO NOT edit manually as it will be overwritten

import * as React from "react"

export interface ILogoDarkProps extends React.HTMLAttributes<SVGSVGElement> {}

const LogoDark = React.forwardRef<
    SVGSVGElement,
    ILogoDarkProps
>((props, ref) => (
    <svg ref={ref} xmlns="http://www.w3.org/2000/svg" fill="none" aria-hidden="true" viewBox="0 0 68 48" data-slot="icon" width="1em" height="1em" {...props}>
        <path
            fill="#EAEBF9"
            fillRule="evenodd"
            d="m0 15.9485.1635 27.4362a.863.863 0 0 0 1.0622.8345l31.9849-7.6003a.1556.1556 0 0 1 .1915.1492l.1511 10.3814a.864.864 0 0 0 .3356.6707.863.863 0 0 0 .7336.1547l32.2318-7.9463a.863.863 0 0 0 .6564-.838V24.7785a.86.86 0 0 0-.0448-.2745l-1.817-5.4199a.156.156 0 0 1 .0115-.1252l.5194-.9333a10.544 10.544 0 0 0 1.3309-5.1278V3.7495A3.75 3.75 0 0 0 66.0816.804a3.747 3.747 0 0 0-3.1972-.6996L.6607 15.1043A.863.863 0 0 0 0 15.9485m.7072-.0043a.156.156 0 0 1 .1191-.1522L63.0501.7922a3.04 3.04 0 0 1 2.5939.5675 3.042 3.042 0 0 1 1.1594 2.3898v9.1483a9.836 9.836 0 0 1-1.2415 4.7836l-.5194.9333a.863.863 0 0 0-.0642.6943l1.817 5.42a.16.16 0 0 1 .0081.0495v14.4121a.1556.1556 0 0 1-.1183.1511L34.4532 47.288a.155.155 0 0 1-.1323-.0279.156.156 0 0 1-.0606-.1209l-.1511-10.3814a.8627.8627 0 0 0-1.062-.8272l-31.985 7.6003a.1555.1555 0 0 1-.1915-.1505z"
            clipRule="evenodd"
        />
        <path
            fill="url(#logo_dark_paint0_linear)"
            d="M7.853 39.1729a.509.509 0 0 1-.3907.4954l-3.7978.9091a.509.509 0 0 1-.6276-.4954V17.77a.509.509 0 0 1 .3906-.4954l10.8179-2.5894q3.3859-.8105 3.3858 2.8395v5.8399q0 6.5699-7.2971 8.3165l-2.0904.5004a.509.509 0 0 0-.3907.4954zm17.4954-12.5456a.509.509 0 0 0-.3907.4954v3.3324a.5094.5094 0 0 0 .6276.4954l6.3338-1.5161a.509.509 0 0 1 .6276.4954v3.3324a.509.509 0 0 1-.3906.4954l-11.3869 2.7256a.509.509 0 0 1-.6277-.4954V13.6757a.509.509 0 0 1 .3907-.4953l11.3869-2.7256a.509.509 0 0 1 .6276.4954v3.3324a.509.509 0 0 1-.3906.4954l-6.8077 1.6295a.509.509 0 0 0-.3907.4954v4.7924a.509.509 0 0 0 .6276.4953l5.6041-1.3413a.509.509 0 0 1 .6276.4953v3.3325a.5094.5094 0 0 1-.3907.4954zm14.9301-3.5737a.509.509 0 0 0-.3907.4954v3.3324a.5092.5092 0 0 0 .6276.4954l6.3338-1.5161a.509.509 0 0 1 .6276.4954v3.3324a.509.509 0 0 1-.3907.4954l-11.3868 2.7256a.509.509 0 0 1-.6277-.4954v-22.312a.5094.5094 0 0 1 .3907-.4954L46.8492 6.881a.509.509 0 0 1 .6276.4954v3.3324a.509.509 0 0 1-.3907.4954l-6.8076 1.6295a.509.509 0 0 0-.3907.4954v4.7924a.509.509 0 0 0 .6276.4954l5.6041-1.3415a.5094.5094 0 0 1 .6276.4954v3.3325a.5094.5094 0 0 1-.3907.4954zm24.1358 2.4089a.509.509 0 0 1-.3632.6606l-3.9822.9532a.509.509 0 0 1-.5999-.3296l-2.0197-5.8699a.509.509 0 0 0-.5999-.3297l-1.6409.3928a.509.509 0 0 0-.3906.4954v6.4961a.5094.5094 0 0 1-.3907.4953l-3.7978.9091a.509.509 0 0 1-.6277-.4954V6.5284a.509.509 0 0 1 .3906-.4954l10.8179-2.5894q3.3859-.8103 3.3859 2.8395v5.8399q0 3.7633-2.3899 5.9457a.508.508 0 0 0-.1401.5423c.4083 1.1939 1.8388 5.366 2.3482 6.8515m-54.0802 1.8387q2.481-.5939 2.481-2.7838v-4.4643a.5093.5093 0 0 0-.6277-.4954l-3.9437.944a.5094.5094 0 0 0-.3907.4954v6.2523a.5088.5088 0 0 0 .6277.4954zm46.9648-11.2416q2.4811-.5938 2.4811-2.7838V8.8115a.5096.5096 0 0 0-.6278-.4954l-3.9437.944a.509.509 0 0 0-.3906.4954v6.2524a.509.509 0 0 0 .6276.4953z"
        />
        <path
            fill="#EAEBF9"
            d="m40.8033 44.2761-3.2856.8267q-1.422.3579-1.422-1.1751v-6.7451q0-1.5329 1.422-1.8908l3.2856-.8267q1.422-.3579 1.4221 1.1751v1.4006a.17.17 0 0 1-.1283.1647l-1.6834.4235a.17.17 0 0 1-.2111-.1646v-.7908a.1696.1696 0 0 0-.2111-.1647l-1.7447.439a.17.17 0 0 0-.1283.1647v5.7818c0 .0523.0241.1017.0654.1338a.17.17 0 0 0 .1457.0308l1.7446-.439a.17.17 0 0 0 .1284-.1647v-1.4894a.1698.1698 0 0 0-.2111-.1646l-.6198.1559a.1696.1696 0 0 1-.2112-.1646v-1.4895a.17.17 0 0 1 .1283-.1646l2.7254-.6859a.17.17 0 0 1 .2112.1647v3.768q0 1.533-1.4221 1.8908m6.5426-3.2997a.1695.1695 0 0 0-.2083-.1338l-1.9606.4934a.17.17 0 0 0-.126.1363l-.2878 1.7027a.17.17 0 0 1-.126.1364l-1.7146.4314a.1697.1697 0 0 1-.2084-.1952c.2728-1.4911 1.6347-8.9337 1.8146-9.9175a.17.17 0 0 1 .1256-.1341l3.007-.7567a.1694.1694 0 0 1 .2078.1311l1.8147 9.0043a.17.17 0 0 1-.125.1982l-1.7456.4393a.17.17 0 0 1-.2083-.1338zm6.5608-1.1748a.17.17 0 0 1-.205-.1193l-1.0629-3.8365a.1697.1697 0 0 0-.3333.0454v5.4073a.17.17 0 0 1-.1283.1646l-1.5178.382a.17.17 0 0 1-.1458-.0308.17.17 0 0 1-.0654-.1338v-9.461a.17.17 0 0 1 .1283-.1646l2.4074-.6058a.1696.1696 0 0 1 .2051.12l1.1918 4.3757a.1697.1697 0 0 0 .3288-.0051l1.2314-5.1407a.17.17 0 0 1 .1237-.1251l2.3862-.6005a.1697.1697 0 0 1 .2111.1647v9.4609a.17.17 0 0 1-.1283.1647l-1.5179.3819a.17.17 0 0 1-.2111-.1647v-5.1538a.1697.1697 0 0 0-.3346-.0402l-1.0816 4.4484a.17.17 0 0 1-.1236.1245zm7.6748-4.4669a.17.17 0 0 0-.1283.1646v1.4894a.1698.1698 0 0 0 .2112.1647l2.7651-.6958a.1696.1696 0 0 1 .2112.1646v1.4894a.17.17 0 0 1-.1284.1647l-4.8707 1.2257a.17.17 0 0 1-.2111-.1647v-9.4609a.17.17 0 0 1 .1282-.1646l4.8708-1.2257a.1695.1695 0 0 1 .2112.1647v1.4894a.17.17 0 0 1-.1284.1647l-2.9308.7374a.17.17 0 0 0-.1283.1647v2.1026a.17.17 0 0 0 .2112.1647l2.4586-.6188a.17.17 0 0 1 .2112.1647v1.4894a.17.17 0 0 1-.1283.1647zm-14.7476 3.7448a.17.17 0 0 0 .1255-.1954l-.7173-3.903a.041.041 0 0 0-.0504-.0324l-.0002.0001a.068.068 0 0 0-.0505.0547l-.7035 4.182a.1698.1698 0 0 0 .2088.1928z"
        />
        <defs>
            <linearGradient id="logo_dark_paint0_linear" x1={45.4384} x2={46.9655} y1={-18.9416} y2={55.6846} gradientUnits="userSpaceOnUse">
                <stop stopColor="#fff" />
                <stop offset={0.24} stopColor="#FFFDF6" />
                <stop offset={0.32} stopColor="#FFF9E1" />
                <stop offset={0.4} stopColor="#FFF5CC" />
                <stop offset={1} stopColor="#C9509D" />
            </linearGradient>
        </defs>
    </svg>
));

export default LogoDark;
