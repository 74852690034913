import { useLocation } from "react-router-dom";
import { GameCode } from "src/core/casino/casino.model";
import { useMemo } from "react";
import { findKey } from "lodash";
import { games } from "src/games/config";

const gamesLocationRegExp = /^\/games\/(?<gameCode>[^/]+)(\/(?<identifier>[^/]+))?\/?$/

export function useGamesLocation<TGameCode extends GameCode = GameCode>() {
    const { pathname } = useLocation();

    const { gameCode, identifier } = useMemo(() => {
        const gameCode = findKey(games, config => config.path === pathname); // find gameCode for OriginalGames
        const { groups = {} } = pathname.match(gamesLocationRegExp) ?? {}; // find gameCode for CasinoGameCategory

        return {
            gameCode: (gameCode ?? groups.gameCode) as TGameCode | undefined,
            identifier: groups.identifier as string | undefined,
        }
    }, [pathname]);

    return { gameCode, identifier };
}