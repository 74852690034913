import { useState, useEffect, useRef } from "react";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import styles from "./assets/styles.scss";
import SideSlider from "src/components/side-slider/SideSlider";

import LangSwitcher from "src/components/header/LangSwitcher";
import { useAppSelector } from "src/redux/reducer";
import { Path } from "src/core/paths/paths";
import Icon from "@pg/design-system/src/components/icons/Icon";

interface ISideNavProps {
    reverse?: boolean;
}

const cx = classNames.bind(styles);

export default function SideNav({ reverse = false }: ISideNavProps) {
    const sideNavRef = useRef<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [langSwitcherOpen, setLangSwitcherOpen] = useState(false);
    const hasAffiliateStatus = useAppSelector(state => !!state.affiliate.status);

    const navItems = [
        { label: "Peergame Partners", to: hasAffiliateStatus ? Path.PartnersDashboard : Path.Partners },
        // { label: "Blog", href: "https://blog.peergame.com" },
        { label: "Fairness", to: Path.ProvablyFair },
        { label: "Help Center", href: "https://help.peergame.com/" },
        { label: "Contact", href: "mailto:contact@peergame.com" },
    ];

    useEffect(() => {
        if (!isOpen) {
            setLangSwitcherOpen(false);
        }
    }, [isOpen]);

    return (
        <>
            <button
                className={cx("header-button", { reverse })}
                type="button"
                onClick={() => setIsOpen(!isOpen)}
                aria-label="Toggle Sidebar"
            >
                <Icon.SideNav className="transition-opacity hover:opacity-75 w-[24px] md:w-[30px]" />
            </button>
            <SideSlider
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                ref={sideNavRef}
                className={cx("sidenav", { reverse })}
            >
                <div className={cx("section")}>
                    <Link to={Path.Originals} className={cx({ reverse })} aria-label="Games">
                        <Icon.Inhouse />
                        Originals
                    </Link>
                    <Link to={Path.Slots} className={cx({ reverse })} aria-label="Slots">
                        <Icon.Slots />
                        Slots
                    </Link>
                    <Link to={Path.Table} className={cx({ reverse })} aria-label="Table Games">
                        <Icon.TableGames />
                        Table Games
                    </Link>
                    <Link to={Path.Races} className={cx({ reverse })} aria-label="Races">
                        <Icon.Races />
                        Races
                    </Link>
                </div>
                <div className={cx("divider")} />
                <div className={cx("section")}>
                    {navItems.map(({ to, href, label }) => to ? (
                        <Link className={cx({ reverse })} key={label} to={to} aria-label={label}>
                            {label}
                        </Link>
                    ) : (
                        <a className={cx({ reverse })} key={label} href={href} target="_blank" rel="noopener noreferrer" aria-label={label}>
                            {label}
                        </a>
                    ))}
                    <LangSwitcher
                        isOpen={langSwitcherOpen}
                        toggleOpen={() => setLangSwitcherOpen(!langSwitcherOpen)}
                        reverse={reverse}
                        onOpened={() => sideNavRef?.current?.scrollTo({ top: 9999, behavior: "smooth" })}
                    />
                </div>
            </SideSlider>
        </>
    );
}