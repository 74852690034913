import { CryptoCurrency } from "src/core/currency/currency.model";
import useAppStore from "src/useAppStore";
import { useExchangeRates } from "src/core/currency/hooks/useExchangeRates";
import { isSet } from "@ntropy/utils/src/type-utils";
import { roundFiat } from "src/core/currency/currency.function";
import BigNumber from "bignumber.js";

export function useConvertToUsd(value: number | BigNumber | undefined | null, currency?: CryptoCurrency, { enabled = true }: { enabled?: boolean } = {}) {
    const selectedAsset = useAppStore(state => state.asset.selected);
    const currencyToUse = currency ?? selectedAsset;

    const [rates] = useExchangeRates({
        select: rates => rates[currencyToUse],
        enabled,
    })

    if (!isSet(rates) || !isSet(value) || !enabled) {
        return null;
    }

    return roundFiat(BigNumber.isBigNumber(value) ? value.multipliedBy(rates) : (value * rates));
}