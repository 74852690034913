import { axiosInstance } from "src/core/http/axios-instance";
import { WalletProvider, IWalletResponse, IWalletTopupResponse } from "src/core/wallet/wallet.model";

interface IWallet {
    id: string;
    default: boolean;
    name: WalletProvider | undefined;
    paymail?: string
}

export interface IWalletsState {
    loaded: boolean
    data: IWallet[]
    currentWallet?: IWallet
    topupUri?: string
    error?: any; // Adjust the type as needed
}

interface IGetWalletsSuccessAction {
    type: typeof GET_WALLETS_SUCCESS;
    result: IWallet[];
}

interface IGetWalletsFailAction {
    type: typeof GET_WALLETS_FAIL;
    error: any; // Adjust the type as needed
}

interface ITopupSuccessAction {
    type: typeof TOPUP_SUCCESS;
    result: { uri: string };
}

type WalletsActionTypes =
    | IGetWalletsSuccessAction
    | IGetWalletsFailAction
    | ITopupSuccessAction

const GET_WALLETS = "wallets/GET";
const GET_WALLETS_SUCCESS = "wallets/GET_SUCCESS";
const GET_WALLETS_FAIL = "wallets/GET_FAIL";
const TOPUP = "wallets/TOPUP";
const TOPUP_SUCCESS = "wallets/TOPUP_SUCCESS";
const TOPUP_FAIL = "wallets/TOPUP_FAIL";

const initialState: IWalletsState = {
    loaded: false,
    data: [],
};

export default function walletReducer(state = initialState, action: WalletsActionTypes): IWalletsState {
    switch (action.type) {
        case GET_WALLETS_SUCCESS:
            return {
                ...state,
                loaded: true,
                data: action.result,
                currentWallet: action.result.find(wallet => wallet.default),
            };
        case GET_WALLETS_FAIL:
            return {
                ...state,
                loaded: true,
                error: action.error,
            };
        case TOPUP_SUCCESS:
            return {
                ...state,
                topupUri: action.result.uri,
            };
        default:
            return state;
    }
}

export function getWallets() {
    return {
        types: [GET_WALLETS, GET_WALLETS_SUCCESS, GET_WALLETS_FAIL],
        promise: async () => {
            const { data } = await axiosInstance.get<{ data: IWalletResponse[] }>("/v1/wallets");
            return data;
        },
    };
}

export function topup() {
    return {
        types: [TOPUP, TOPUP_SUCCESS, TOPUP_FAIL],
        promise: async () => {
            const { data } = await axiosInstance.get<{ data: IWalletTopupResponse }>("/v1/wallets/topup");
            return data;
        },
    };
}