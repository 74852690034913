import classNames from "classnames/bind";
import styles from "./assets/styles.scss";
import CrashImage from "./assets/crash-page.png";

const cx = classNames.bind(styles);

const Crash = () => {
    const refreshPage = () => {
        window.location.reload();
    };

    return (
        <div className={cx("crash-container")}>
            <div className={cx("crash")}>
                <img src={CrashImage} alt="crash" />
                <h3 className={cx("crash-title")}>SOMETHING IS WRONG</h3>
                <div className={cx("crash-content")}>
                    <span>The page you requested couldn't be found.</span>
                    <span>You can visit our homepage instead.</span>
                </div>
                <button onClick={refreshPage} type="button" className={cx("bold")}>
                    REFRESH
                </button>
            </div>
        </div>
    );
};

export default Crash;
