// Generated by generate-logos script. DO NOT edit manually as it will be overwritten


import LogoDark from "./components/LogoDark";
import LogoLight from "./components/LogoLight";

export const logos = {
    LogoDark,
    LogoLight,
} as const;

export type LogoName = keyof typeof logos;
export const allLogos = Object.keys(logos) as LogoName[];
