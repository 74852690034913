import { createEnumParam } from "serialize-query-params";
import { enumToValues } from "@ntropy/utils/src/enum-utils";
import { useQueryParam } from "use-query-params";

export enum DialogContentType {
    Wallet = "wallet",
    Deposit = "deposit",
    Withdrawal = "withdrawal",
}

const DialogContentParam = createEnumParam(enumToValues(DialogContentType));

export function useWalletDialogContentParam() {
    const [walletDialog, setWalletDialog] = useQueryParam<DialogContentType | null | undefined>("wallet-dialog", DialogContentParam)

    const handleOpenChange = (open: boolean) => {
        if (!open) {
            setWalletDialog(null);
            return;
        }

        if (!walletDialog) {
            setWalletDialog(DialogContentType.Wallet);
        }
    }

    return {
        walletDialog,
        setWalletDialog,
        handleOpenChange,
    };
}