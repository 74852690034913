import classNames from "classnames/bind";
import { Link, useNavigate, Outlet, RouteObject } from "react-router-dom";
import styles from "./assets/styles.scss";
import { useMobileOnly } from "src/helpers/responsive";
import Dropdown from "src/components/dropdown/Dropdown";
import PageContainer from "src/containers/PageContainer/PageContainer";
import { useRemoveTrailingSlash } from "src/core/router/hooks/useRemoveTrailingSlash";
import { Path } from "src/core/paths/paths";
import LazyPage from "src/core/router/components/LazyPage";

const cx = classNames.bind(styles);

type StaticRouteObject = RouteObject & {
    path: Path
    title: string
    category: StaticPagesCategory
    hideTitle?: boolean
}

enum StaticPagesCategory {
    Product = "product",
    Service = "service",
}

export const staticPages: StaticRouteObject[] = [
    {
        path: Path.AboutUs,
        element: <LazyPage loader={() => import("src/static/AboutUs")} />,
        title: "About Us",
        category: StaticPagesCategory.Product,
    },
    {
        path: Path.FairnessCalculation,
        element: <LazyPage loader={() => import("src/containers/FairnessCalculation/FairnessCalculation")} />,
        title: "Fairness Calculation",
        category: StaticPagesCategory.Product,
        hideTitle: true,
    },
    {
        path: Path.ProvablyFair,
        element: <LazyPage loader={() => import("src/static/ProvablyFair")} />,
        title: "Provably Fair",
        category: StaticPagesCategory.Product,
    },
    {
        path: Path.Bsv,
        element: <LazyPage loader={() => import("src/static/BitcoinSV")} />,
        title: "Bitcoin SV",
        category: StaticPagesCategory.Product,
    },
    {
        path: Path.ResponsibleGaming,
        element: <LazyPage loader={() => import("src/static/ResponsibleGaming")} />,
        title: "Responsible Gaming",
        category: StaticPagesCategory.Product,
    },
    {
        path: Path.PrivacyPolicy,
        element: <LazyPage loader={() => import("src/static/PrivacyPolicy")} />,
        title: "Privacy Policy",
        category: StaticPagesCategory.Service,
    },
    {
        path: Path.TermsOfService,
        element: <LazyPage loader={() => import("src/static/TermsOfService")} />,
        title: "Terms of Service",
        category: StaticPagesCategory.Service,
    },
    {
        path: Path.Payments,
        element: <LazyPage loader={() => import("src/static/Payments")} />,
        title: "Payments",
        category: StaticPagesCategory.Product,
    },
    {
        path: Path.Cookies,
        element: <LazyPage loader={() => import("src/static/Cookies")} />,
        title: "Cookies",
        category: StaticPagesCategory.Service,
    },
    {
        path: Path.Complaints,
        element: <LazyPage loader={() => import("src/static/Complaints")} />,
        title: "Complaints",
        category: StaticPagesCategory.Service,
    },
    {
        path: Path.KycPolicy,
        element: <LazyPage loader={() => import("src/static/KycPolicy")} />,
        title: "AML/KYC Policy",
        category: StaticPagesCategory.Service,
    },
];

export function StaticPage() {
    const isMobileOnly = useMobileOnly();
    const navigate = useNavigate();

    const {
        finalPathname,
    } = useRemoveTrailingSlash();

    const staticPage = staticPages.find(({ path }) => path === finalPathname);

    if (!staticPage) {
        return null;
    }

    const categoryNav = staticPages.filter(({ category: _category }) => _category === staticPage?.category);

    return (
        <PageContainer pageTitle={staticPage?.title || ""} contentClass={cx("static-page")} dark>
            {isMobileOnly ? (
                <div className={cx("navigation")}>
                    <Dropdown
                        items={categoryNav.map(({ title: label, path }) => ({ label, value: path }))}
                        selectedValue={finalPathname}
                        onChange={newValue => navigate(newValue.toString())}
                    />
                </div>
            ) : (
                <div className={cx("navigation")}>
                    {categoryNav.map(({ path, title: label }) => (
                        <Link key={label} className={cx({ active: path === finalPathname })} to={path}>
                            {label}
                        </Link>
                    ))}
                </div>
            )}
            <div className={cx("content")}>
                {!staticPage?.hideTitle && <h1>{staticPage?.title}</h1>}
                <Outlet />
            </div>
        </PageContainer>
    );
}
