import { HTMLAttributes, ForwardedRef, ForwardRefExoticComponent } from "react";
import { Suspense } from "react";
import classNames from "classnames";

export interface IIconWrapperProps extends Omit<HTMLAttributes<HTMLDivElement>, "color"> {
    className?: string;
    size?: `size-${string}`;

    icon: ForwardRefExoticComponent<HTMLAttributes<SVGSVGElement> & { ref?: ForwardedRef<SVGSVGElement> }>
    iconName: string;
    /** null causes icon to use original color */
    color?: string | null
    iconRef?: ForwardedRef<SVGSVGElement>
    iconClassName?: string
}

const IconWrapper = ({
    icon: SvgIcon,
    iconName,
    className,
    iconClassName,
    iconRef,
    size = "size-icon",
    color = "currentColor",
    ...rest
}: IIconWrapperProps) => {
    if (!SvgIcon) return null;

    return (
        <div
            className={classNames("flex items-center justify-center", className, size)}
            aria-label={iconName}
            role="img"
            {...rest}
        >
            <Suspense fallback={null}>
                <SvgIcon
                    ref={iconRef}
                    color={color as string}
                    className={classNames("h-full w-full", iconClassName)}
                />
            </Suspense>
        </div>
    );
};

export default IconWrapper;