import { ComponentType } from "react";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import MyAccountMenu from "src/components/header/MyAccountMenu";
import SideNav from "src/components/header/SideNav";
import WalletMenu from "src/components/header/WalletMenu/WalletMenu";
import styles from "./assets/styles.scss";
import AuthButton from "src/feature/auth/AuthButton";
import { useMobileOnly, useMdUp, useSmUp } from "src/helpers/responsive";
import useLocationChange from "src/helpers/useLocationChange";
import { WalletDialog } from "src/feature/wallet/dialogs/WalletDialog";
import { useAuthorizedState } from "src/core/auth/hooks/useAuthorizedState";
import { WalletProvider } from "src/core/wallet/wallet.model";
import RandomizeUser from "src/components/header/components/RandomizeUser/RandomizeUser";
import { Path } from "src/core/paths/paths";
import Icon, { IIconProps } from "@pg/design-system/src/components/icons/Icon";
import Logo from "@pg/design-system/src/components/logo/Logo";

interface IHeaderProps {
    classes?: string[];
}

interface INavItem {
    path: Path;
    icon: ComponentType<IIconProps>;
    text: string;
    isVisible: boolean
}

const cx = classNames.bind(styles);

export default function Header({ classes = [] }: IHeaderProps) {
    const isMobileOnly = useMobileOnly();
    const isTabletUp = useMdUp();
    const isMobileUp = useSmUp();
    const { isAuthorized, walletProvider } = useAuthorizedState();

    useLocationChange(() => {
        document.body.scrollTo({ top: 0, behavior: "smooth" });
    });

    const navItems: INavItem[] = [
        {
            path: Path.Originals,
            icon: Icon.Inhouse,
            text: !isMobileOnly ? "Originals" : "",
            isVisible: isMobileUp,
        },
        {
            path: Path.Slots,
            icon: Icon.Slots,
            text: "Slots",
            isVisible: isTabletUp,
        },
        {
            path: Path.Table,
            icon: Icon.TableGames,
            text: "Table Games",
            isVisible: isTabletUp,
        },
        {
            path: Path.Races,
            icon: Icon.Races,
            text: "Races",
            isVisible: isTabletUp,
        },
    ];

    return (
        <>
            <header className={cx(classes)}>
                <div className={cx("left")}>
                    <SideNav reverse={!classes.includes("transparent")} />
                    <Link to={Path.Home} className={cx("logo")} aria-label="Logo">
                        <Logo dark={classes.includes("transparent")} />
                    </Link>
                </div>

                <div className={cx("center")}>
                    {navItems.map(({ icon: Icon, text, path, isVisible }) => isVisible && (
                        <Link
                            key={text}
                            to={path}
                            className={cx("header-button", { reverse: !classes.includes("transparent") })}
                            aria-label={text}
                        >
                            <Icon className="&:not(:hover):*:fill-brand-primary-100" color={null} />
                            {text}
                        </Link>
                    ))}

                </div>
                <div className={cx("right")}>
                    {isAuthorized && walletProvider === WalletProvider.WalletConnect && process.env.NODE_ENV !== "production" && <RandomizeUser />}
                    {isAuthorized &&
                        <WalletMenu reverse={!classes.includes("transparent")} forWalletConnect={walletProvider === WalletProvider.WalletConnect} />
                    }
                    {isAuthorized && walletProvider === WalletProvider.WalletConnect && <WalletDialog />}
                    {isAuthorized ? <MyAccountMenu reverse={!classes.includes("transparent")} /> : <AuthButton />}
                </div>
            </header>
        </>
    );
}