export function getValueColour(wheelSections, value) {
    if (value === 0) {
        return "GREEN";
    }
    return wheelSections.indexOf(value) % 2 === 0 ? "BLACK" : "RED";
}

// returns an array of table numbers filtered from the given layout using filterFunction
// layout only matters when working with rows/cols
export function filterNumbers(layout, filterFunction) {
    let numbers = [];

    for (let i = 0; i < layout.length; i += 1) {
        for (let j = 0; j < layout[i].length; j += 1) {
            const {value, color} = layout[i][j];
            if (
                filterFunction({
                    row: i,
                    col: j,
                    value,
                    color,
                })
            ) {
                numbers.push({
                    row: i,
                    col: j,
                    value,
                    color,
                });
            }
        }
    }
    // filter duplicate zeros, since 0 occupies 3 cells but function should only return one item
    numbers = numbers.filter((item, index) => numbers.findIndex(({value}) => value === item.value) === index);
    if (numbers.length === 1) {
        return numbers[0];
    }
    return numbers;
}

// returns an area object {type: string, details: integer} based on cell position, cell interaction and layout
export function getBet(layout, isMobile, corners, splits, row, col, directionX, directionY) {
    if (isMobile) {
        // exclude top/left/bottom edges
        if ([-1, 13].includes(row + directionY) || col + directionX === -1) {
            return null;
        }
        if (!directionX && !directionY) {
            return {type: "STRAIGHT", details: layout[row][col].value};
        }
        if (col === 2 && directionX === 1) {
            if (directionY) {
                // special corner bet
                if (row === 0 || row + directionY === 0) {
                    return {type: "CORNER", details: 0};
                }
                return {
                    type: "SIXLINE",
                    details: row - 1 + (directionY === -1 && -1),
                };
            }
            if (row > 0) {
                return {type: "STREET", details: row + 1};
            }
            return null;
        }
        if ((directionX && !directionY) || (directionY && !directionX)) {
            const splitNumbers = [layout[row][col].value, layout[row + directionY][col + directionX].value];
            const {d} = splits.find(({numbers}) => splitNumbers.every(number => numbers.includes(number)));
            return {type: "SPLIT", details: d};
        }
        if (directionY && directionX) {
            // special street bet
            if (col <= 2 && (row === 0 || row + directionY === 0)) {
                return {type: "STREET", details: col + (directionX === -1 && -1)};
            }
            const cornerNumbers = [
                layout[row][col].value,
                layout[row][col + directionX].value,
                layout[row + directionY][col].value,
                layout[row + directionY][col + directionX].value,
            ];
            const {d} = corners.find(({numbers}) => cornerNumbers.every(number => numbers.includes(number)));
            return {type: "CORNER", details: d};
        }
    } else {
        // exclude bottom/left/right edges
        if ([-1, 13].includes(col + directionX) || row + directionY === 3) {
            return null;
        }
        if (!directionX && !directionY) {
            return {type: "STRAIGHT", details: layout[row][col].value};
        }
        if (row === 0 && directionY === -1) {
            if (directionX) {
                // special corner bet
                if (col === 0 || col + directionX === 0) {
                    return {type: "CORNER", details: 0};
                }
                return {
                    type: "SIXLINE",
                    details: col - 1 + (directionX === -1 && -1),
                };
            }
            if (col > 0) {
                return {type: "STREET", details: col + 1};
            }
            // ignore col 0
            return null;
        }
        if ((directionX && !directionY) || (directionY && !directionX)) {
            const splitNumbers = [layout[row][col].value, layout[row + directionY][col + directionX].value];
            const {d} = splits.find(({numbers}) => splitNumbers.every(number => numbers.includes(number)));
            return {type: "SPLIT", details: d};
        }
        if (directionY && directionX) {
            // special street bet
            if (row >= 0 && (col === 0 || col + directionX === 0)) {
                return {type: "STREET", details: 2 - row - (directionY === 1 && 1)};
            }
            const cornerNumbers = [
                layout[row][col].value,
                layout[row][col + directionX].value,
                layout[row + directionY][col].value,
                layout[row + directionY][col + directionX].value,
            ];
            const {d} = corners.find(({numbers}) => cornerNumbers.every(number => numbers.includes(number)));
            return {type: "CORNER", details: d};
        }
    }
    return null;
}
