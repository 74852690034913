import { Card, CardFooter, CardHeader, CardTitle, CardContent } from "src/components/card/Card";

interface ISimpleCardProps {
    title: string;
    description: string;
    callToAction?: React.ReactElement;
}

export const SimpleCard = ({ callToAction, title, description }: ISimpleCardProps) => {
    return (
        <Card className="text-card bg-brand-primary-500 border-0 p-8 lg:p-10 w-full flex flex-col gap-6">
            <CardHeader className="p-0">
                <CardTitle className="text-[24px]">{title}</CardTitle>
            </CardHeader>
            <CardContent className="min-h-40 p-0">
                <p className="text-sm md:text-base font-light">{description}</p>
            </CardContent>
            {!!callToAction &&
                <CardFooter className="p-0">
                    {callToAction}
                </CardFooter>
            }
        </Card>
    )
}