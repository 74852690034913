import PageContainer from "src/containers/PageContainer/PageContainer";
import { TrendingGames } from "src/containers/Landing/components/trending-games/TrendingGames";
import { BottomLandingSections } from "src/containers/Landing/components/bottom-landing-sections/BottomLandingSections";
import { RecommendedGames } from "src/containers/Landing/components/recommended-games/RecommendedGames";
import { ContinuePlayingSection } from "src/containers/Landing/components/continue-playing-section/ContinuePlayingSection";
import { BigWinsSection } from "src/containers/Landing/components/big-wins-section/BigWinsSection";
import { StatisticsSection } from "src/containers/Landing/components/statistics-section/StatisticsSection";
import { CarouselWithSingleCategory } from "src/containers/Landing/components/carousel-with-single-category/CarouselWithSingleCategory";
import { CasinoGameCategory } from "src/core/casino/casino.model";

export const AuthorizedUserLanding = () => {
    return (
        <PageContainer showHeader showFooter dark contentClass="flex flex-col gap-10 px-4 md:px-8 lg:px-[70px] py-10">
            <ContinuePlayingSection />
            <RecommendedGames />
            <BigWinsSection />
            <TrendingGames />
            <CarouselWithSingleCategory headingTitle="Table Games" category={CasinoGameCategory.Table} />
            <CarouselWithSingleCategory headingTitle="Slots" category={CasinoGameCategory.Slots} />
            <CarouselWithSingleCategory headingTitle="Crash" category={CasinoGameCategory.Crash} />
            <StatisticsSection />
            <BottomLandingSections />
        </PageContainer>
    )
}