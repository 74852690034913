import { Link } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./styles.scss";
import { Ban } from "lucide-react";
import { cn } from "@ntropy/utils/src/cn";
import Icon from "@pg/design-system/src/components/icons/Icon";

interface IGameThumbnailProps {
    title: string;
    url: string;
    thumbnailSrc: string;
    provider: string;
    isDisabled: boolean;
    className?: string;
    userName?: string;
    userAvatar?: string;
    winAmount?: string;
    shouldAnimate?: boolean;
    animationMode?: "scaleUp" | "scaleUpMoveUp";
}

const cx = classNames.bind(styles);

export default function GameThumbnail({
    title,
    url,
    thumbnailSrc,
    provider,
    isDisabled,
    className,
    userName,
    winAmount,
    userAvatar,
    animationMode = "scaleUp",
    shouldAnimate = false,
}: IGameThumbnailProps) {
    const shouldDisplayBottomContent = userName && winAmount && userAvatar;
    const hoverAnimation = animationMode === "scaleUp" ? "scale-up" : "scale-up-move-up";

    const content = (
        <>
            <img
                src={thumbnailSrc} alt={title}
                className={cn(shouldDisplayBottomContent ? "rounded-b-none rounded-t-lg" : "rounded-lg", isDisabled && "opacity-30")}
            />
            <div className={cx("hover-overlay")}>
                <span>{title}</span>
                {isDisabled ? <Ban className="w-10 h-10" /> : <Icon.PlayCircle size="size-10" />}
                <span className={cx("small")}>{provider}</span>
            </div>
        </>
    )

    return (
        <>
            {isDisabled ?
                <div className={cx("thumbnail", "cursor-not-allowed", className)}>
                    {content}
                    <div className="absolute top-0 left-0 w-full h-full bg-brand-primary-600 opacity-50" />
                </div> :
                <Link to={url} className={cx("thumbnail", shouldAnimate && hoverAnimation, className)}>{content}</Link>
            }

            {shouldDisplayBottomContent && (
                <div
                    className="flex flex-col justify-center p-4 gap-2 w-[255px] h-[88px] bg-brand-primary-500 overflow-hidden rounded-b-lg"
                >
                    <div className="flex gap-2 max-w-max">
                        <div className="relative w-[22px] h-[22px] rounded-full overflow-hidden">
                            <img src={userAvatar} alt={`${userName} avatar`} className="absolute top-0 left-0 w-full h-auto object-cover object-center" />
                        </div>
                        <p className="font-bold text-brand-white">{userName}</p>
                    </div>
                    <p className="text-game-green-dark font-bold">$ {winAmount}</p>
                </div>
            )}
        </>
    );
}