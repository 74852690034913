import { axiosInstance } from "src/core/http/axios-instance";
import config from "src/config";
import { IHandcashGameHistoryResponse, GameHistoryStatus, ICashierGameHistoryEntry } from "src/containers/Account/hooks/useGameHistory/game-history.model";
import { GameCode, OriginalGames } from "src/core/casino/casino.model";
import { Endpoint } from "src/core/http/endpoint";

interface IHandcashGameHistoryParams {
    betStatus?: GameHistoryStatus | ""
    pageParam?: string | number | null
    count?: number
    withGameResultOnly?: boolean
}

export async function getHandcashGameHistory(
    gameCode: GameCode | null | undefined,
    {
        count,
        pageParam,
        betStatus,
        withGameResultOnly,
    }: IHandcashGameHistoryParams,
) {
    if (!gameCode) {
        return [];
    }

    const lastIdParamName = gameCode === OriginalGames.CfBattle || gameCode === OriginalGames.Blackjack ? "last_id" : "last_game_id";

    const { data } = await axiosInstance.get<IHandcashGameHistoryResponse>(Endpoint.HandcashGameHistory, {
        params: {
            asset: config.asset,
            count,
            [lastIdParamName]: pageParam,
            game_code: gameCode,
            status: betStatus || undefined,
        },
    })

    if (withGameResultOnly) {
        return data.filter(({ game_result: gameResult }) => !!gameResult);
    } else {
        return data;
    }
}

interface ICashierGameHistoryParams {
    lastWagerId?: number | null
    pageSize?: number
}

export async function getCashierGameHistory(params: ICashierGameHistoryParams) {
    return await axiosInstance.get<ICashierGameHistoryEntry[]>(Endpoint.GameHistory, { params });
}