import { ReactElement } from "react";
import { CasinoGameCategory } from "src/core/casino/casino.model";
import { Path } from "src/core/paths/paths";
import Icon from "@pg/design-system/src/components/icons/Icon";

interface ICategories {
    name: string;
    getTitle: (isMobileOnly?: boolean) => string;
    icon: ReactElement;
    path: Path
}

export const categories: ICategories[] = [
    { name: "", getTitle: (isMobileOnly: boolean) => (isMobileOnly ? "All" : "All Games"), icon: <Icon.Games className="size-8 shrink-0" />, path: Path.Games },
    { name: CasinoGameCategory.Originals, getTitle: () => "Originals", icon: <Icon.Inhouse className="size-8 shrink-0" />, path: Path.Originals },
    { name: CasinoGameCategory.Slots, getTitle: () => "Slots", icon: <Icon.Slots className="size-8 shrink-0" />, path: Path.Slots },
    { name: CasinoGameCategory.Table, getTitle: () => "Table", icon: <Icon.TableGames className="size-8 shrink-0" />, path: Path.Table },
    { name: CasinoGameCategory.Casual, getTitle: () => "Casual", icon: <Icon.Casual className="size-8 shrink-0" />, path: Path.Casual },
];