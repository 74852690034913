import isString from "lodash/isString";
import padStart from "lodash/padStart";
import camelCase from "lodash/camelCase";
import upperFirst from "lodash/upperFirst";
import snakeCase from "lodash/snakeCase";
import { isSet } from "@ntropy/utils/src/type-utils";
import startCase from "lodash/startCase";

export function stripHtml(stringWithHtmlTags: string): string {
    if (!isString(stringWithHtmlTags)) {
        throw new Error(`${stringWithHtmlTags} is not a string`);
    }

    return stringWithHtmlTags.replace(/<\/?[^>]+(>|$)/g, "");
}

export function escapeRegExp(text: string) {
    return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched text
}

export function capitalize(str: string): string {
    if (!isString(str)) {
        throw new Error(`${str} is not a string`);
    }

    if (str.length === 0) {
        return str;
    }

    return str[0].toUpperCase() + str.substr(1);
}

export function padZero(val: string | number, length = 2): string {
    return padStart(String(val), length, "0");
}

export function computeLevenshteinDistance(a: string, b: string): number {
    if (a.length === 0) return b.length;
    if (b.length === 0) return a.length;

    const matrix: number[][] = [];

    // increment along the first column of each row
    for (let i = 0; i <= b.length; i++) {
        matrix[i] = [i];
    }

    // increment each column in the first row
    for (let j = 0; j <= a.length; j++) {
        matrix[0][j] = j;
    }

    // Fill in the rest of the matrix
    for (let i = 1; i <= b.length; i++) {
        for (let j = 1; j <= a.length; j++) {
            if (b.charAt(i - 1) === a.charAt(j - 1)) {
                matrix[i][j] = matrix[i - 1][j - 1];
            } else {
                matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, // substitution
                    Math.min(matrix[i][j - 1] + 1, // insertion
                        matrix[i - 1][j] + 1)); // deletion
            }
        }
    }

    return matrix[b.length][a.length];
}

export const pascalCase = (value: string): string => upperFirst(camelCase(value));
export const upperSnakeCase = (value: string): string => snakeCase(value).toUpperCase();
export const upperWordCase = (value: string): string => value ? startCase(pascalCase(value)).replace(/-/g, " ") : ""


type IdModifier = "T" | "P" | "";

export function padFiveDigitId(id: string | number, modifier: IdModifier = "") {
    return modifier + String(id).padStart(5, "0");
}

export const generateString = () => {
    return Math.random().toString(36).substring(2, 14)
};

export function splitOnce(value: string, splitter: string): [string, string] {
    const i = value.indexOf(splitter);
    return [value.slice(0, i), value.slice(i + 1)];
}

export function kebabCase(value: string) {
    // Match a single character not present in the list, the list contains all ASCII special characters
    const matches = value.match(/[^\x00-\x2f\x3a-\x40\x5b-\x60\x7b-\x7f]+/g) ?? [];
    return matches.join("-").toLowerCase();
}

export function removeByIndex(value: string, index: number, replacement = "") {
    return removeBetween(value, index, index, replacement);
}

export function removeBetween(value: string, start: number, end: number, replacement = "") {
    if (end >= value.length || end < 0) {
        return value;
    }

    if (start >= value.length || start < 0) {
        return value;
    }

    return value.slice(0,start) + replacement + value.slice(end + 1);
}


type IndexesForWord = [number?, number?, number?];
type IndexesForName = [IndexesForWord, IndexesForWord, IndexesForWord];
export const getNextAcronym = (nameParts: string[], iteration = 0): string | false => {
    let indexesForName: IndexesForName

    switch (iteration) {
        case 0:
            indexesForName = [[0], [0], [0]];
            break;
        case 1:
            indexesForName = [[0, 1], [0], []];
            break;
        case 2:
            indexesForName = [[0], [0, 1], []];
            break;
        case 3:
            indexesForName = [[0], [0], [1]];
            break;
        case 4:
            indexesForName = [[0, 2], [0], []];
            break;
        case 5:
            indexesForName = [[0, 1, 3], [], []];
            break;
        case 6:
            indexesForName = [[0, 2, 3], [], []];
            break;
        case 7:
            indexesForName = [[0, 1, 4], [], []];
            break;
        case 8:
            indexesForName = [[0, 2, 4], [], []];
            break;
        default:
            return false;
    }

    let name = "";

    for (const [wordIndex, indexesForWord] of Object.entries(indexesForName)) {
        if (!indexesForWord.length) {
            continue;
        }

        const namePart = nameParts[Number(wordIndex)]

        if (!namePart) {
            return getNextAcronym(nameParts, iteration + 1);
        }

        for (const index of indexesForWord) {
            name += namePart[index!];
        }
    }

    return name;
}
export const calculateShortNames = (home: string, away: string) => {
    const basicShortHome = home.substring(0, 3);
    const basicShortAway = away.substring(0, 3);

    if (basicShortHome.toLowerCase() !== basicShortAway.toLowerCase()) {
        return {
            home: basicShortHome,
            away: basicShortAway,
        }
    }

    const homeSplit = home.split(" ");
    const awaySplit = away.split(" ");

    let homeShort: string | null | false;
    let awayShort: string | null | false;
    let iteration = 0;

    do {
        homeShort = getNextAcronym(homeSplit, iteration)
        awayShort = getNextAcronym(awaySplit, iteration)

        if (homeShort === false || awayShort === false) {
            return {
                home: homeShort || basicShortHome,
                away: awayShort || basicShortAway,
            }
        }

        iteration++;
    } while (!isSet(homeShort) || !isSet(awayShort) || (homeShort.toLowerCase() === awayShort.toLowerCase()))

    return {
        home: homeShort || basicShortHome,
        away: awayShort || basicShortAway,
    }
}

export const asStringOrNull = (value: string | number | undefined | null) => {
    if (typeof value === "string") return value;
    if (typeof value === "number") return String(value);

    if (!isSet(value)) {
        return null;
    }

    console.error("Unexpected value provided to asStringOrNull:", value);

    return null as never;
}

export const nonNbspWhiteSpaces = [
    "​", " ", " ", " ", " ", " ", " ", " ", " ", " ", "⠀",
];

const nonStandardWhiteSpaces = [
    " ", ...nonNbspWhiteSpaces,
]

const nonStandardWhiteSpaceRegexp = new RegExp(`(${nonStandardWhiteSpaces.join("|")})`, "g");

export const normalizeWhiteSpaces = (text: string) => text.replace(nonStandardWhiteSpaceRegexp, " ");

export const isEmptyOrNull = (value: string | null | undefined) => {
    if (value === null || value === undefined) {
        return true;
    }

    if (typeof value !== "string") {
        return false;
    }

    if (value.trim() !== "") {
        return false;
    }

    return true;
};

export const normalizeWYSIWYGText = (text: string): string => {
    return text
        ?.replace(/<\/p><p>/g, "<br />")
        ?.replace(/<\/?p>/g, "")
        ?.replace(/\\n/g, "<br />");
};

const s4 = (): string => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}

export const guid = (): string => {
    return (s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4()).toLowerCase();
}

export const getNamePrefixedOptionally = (name: string, prefix?: string | null | undefined): string => {
  if (prefix) {
    return `${prefix}${upperFirst(name ?? "")}`;
  }

  return name ?? "";
}
