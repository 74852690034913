export const Loader = () => {
    return (
        <div className="flex items-center justify-center h-full w-full min-h-[calc(100vh-160px)]">
            <div className="relative inline-block w-20 h-20">
                <div className="absolute w-full h-full border-4 border-[#3c1b63] opacity-100 rounded-full animate-loader-pulse"></div>
                <div className="absolute w-full h-full border-4 border-[#3c1b63] opacity-100 rounded-full animate-loader-pulse delay-[500ms]"></div>
            </div>
        </div>
    );
};
