import Icon from "@pg/design-system/src/components/icons/Icon";
import { CasinoGameCategory } from "src/core/casino/casino.model";
import { Path } from "src/core/paths/paths";

export const gameSections = [
    {
        title: "In-house",
        icon: <Icon.Games />,
        category: CasinoGameCategory.Originals,
        path: Path.InHouse,
        viewAll: false,
        limitSize: () => 10,
        isEmpty: () => false,
    },
    {
        title: "Slots",
        icon: <Icon.Slots />,
        category: CasinoGameCategory.Slots,
        path: Path.Slots,
        viewAll: true,
        limitSize: (isLgUp: boolean) => (isLgUp ? 10 : 9),
        isEmpty: (gamesLength: number) => !gamesLength,
    },
    {
        title: "Table",
        icon: <Icon.TableGames />,
        category: CasinoGameCategory.Table,
        path: Path.Table,
        viewAll: true,
        limitSize: (isLgUp: boolean) => (isLgUp ? 10 : 9),
        isEmpty: (gamesLength: number) => !gamesLength,
    },
] as const;