import { useCallback } from "react";
import { WalletProvider } from "src/core/wallet/wallet.model";
import useAppStore from "src/useAppStore";
import { useAuthorizedCookie } from "src/core/auth/hooks/useAuthorizedCookie";
import { useWalletProvider } from "src/core/wallet/hooks/useWalletProvider";
import { useDisconnect } from "wagmi";
import { useSessionToken } from "src/core/auth/hooks/useSessionToken";
import { CryptoCurrency } from "src/core/currency/currency.model";
import { axiosInstance } from "src/core/http/axios-instance";
import { useAppDispatch } from "src/redux/reducer";
import { logoutAffiliate } from "src/core/affiliate/affiliate.reducer";

export function useLogout() {
    const [_1, setAuthorized] = useAuthorizedCookie();
    const [_2, setToken] = useSessionToken();

    const [walletProvider, setWalletProvider] = useWalletProvider();
    const { disconnect } = useDisconnect();


    const dispatch = useAppDispatch();

    const logout = useCallback(async () => {
        if (walletProvider === WalletProvider.HandCash) {
            useAppStore.getState().setSelectedAsset(CryptoCurrency.BSV);
            await axiosInstance.post("/auth/v2/logout");
        } else {
            setToken(null);
            disconnect();
            // await axiosInstance.post(Endpoint.Logout);
        }

        dispatch(logoutAffiliate());

        setAuthorized(false);
        setWalletProvider(null);
    }, [disconnect, dispatch, setAuthorized, setToken, setWalletProvider, walletProvider])

    return logout;
}