import { useRef } from "react";
import { cn } from "@ntropy/utils/src/cn";
import Icon from "@pg/design-system/src/components/icons/Icon";
import { Button, ButtonVariant } from "@pg/design-system";
import { useTriggerAuthModal } from "src/core/router/components/NavigateToLogin";

export default function AuthButton({ className, onClick }: { onClick?(): void, className?: string }) {
    const setModalOpen = useTriggerAuthModal();
    const connectWalletRef = useRef<HTMLButtonElement | null>(null);

    return (
        <Button
            variant={ButtonVariant.SECONDARY}
            className={cn(
                "flex items-center text-brand-primary bg-brand-primary-100 px-2 py-1 rounded-[6px] hover:bg-brand-primary-200 md:pr-4 md:pl-3 md:min-h-10 md:rounded-[10px]",
                className,
            )}
            type="button"
            onClick={() => {
                setModalOpen();
                onClick?.();
            }}
            aria-label="Play Now"
            ref={connectWalletRef}
            callToAction
        >
            <Icon.PlayNowWallet color={null} className="w-8 md:mr-1" />
            <span className="hide-mobile small font-bold whitespace-nowrap">Connect</span>
        </Button>
    );
}

