import { CryptoCurrency, Chain } from "src/core/currency/currency.model";
import { useWalletAccounts } from "src/core/wallet/hooks/useWalletAccounts/useWalletAccounts";
import CurrencyListElement from "src/feature/wallet/components/currency-list-element/CurrencyListElement";
import { useCryptoChains } from "src/core/currency/hooks/useCryptoChains";
import map from "lodash/map";
import { useEffect } from "react";
import { SelectItem, Select } from "@pg/design-system";

interface ICurrencyAndChainSelectProps {
    currency: CryptoCurrency | null | undefined
    onCurrencyChange: (currency: CryptoCurrency) => void;
    onChainChange: (chain: Chain) => void;
    hideZeros?: boolean
}

export const CurrencyAndChainSelect = ({ currency, onChainChange, onCurrencyChange, hideZeros }: ICurrencyAndChainSelectProps) => {
    const accounts = useWalletAccounts();
    const chains = useCryptoChains({ enabled: !!currency });

    useEffect(() => {
        if (!!currency && chains[currency]?.length === 1) {
            onChainChange(chains[currency][0]);
        }
    }, [chains, currency, onChainChange]);

    return (
        <>
            <div className="flex flex-col gap-2 justify-center select-none">
                <h3 className="text-sm">Select your currency</h3>
                <Select
                    value={currency}
                    onChange={onCurrencyChange}
                    placeholder={<span className="inline-flex w-full text-left">Select your currency</span>}
                    className="h-11 grid items-center grid-flow-col gap-[6px] grid-cols-[1fr_auto]"
                    selectContent={accounts.map(({ currencyTicker, balance }) => (!hideZeros || !!balance) && (
                        <SelectItem key={currencyTicker} value={currencyTicker} className="h-11 py-0 grid grid-flow-col">
                            <CurrencyListElement currencyTicker={currencyTicker} balance={balance} />
                        </SelectItem>
                    ))}
                />
            </div>

            {!!currency && (chains[currency]?.length ?? 0) > 1 &&
                <div className="flex flex-col gap-2 justify-center mb-4 select-none">
                    <h3 className="text-sm">Select currency network</h3>
                    <Select
                        onChange={onChainChange}
                        placeholder={<span className="inline-flex w-full text-left">Select currency network</span>}
                        selectContent={map(chains[currency], chain => (
                            <SelectItem key={chain} value={chain}>
                                {chain}
                            </SelectItem>
                        ))}
                    />
                </div>
            }
        </>
    )
}