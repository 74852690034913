import { useAuthorizedState } from "src/core/auth/hooks/useAuthorizedState";
import { NotAuthorizedUserLanding } from "src/containers/Landing/components/not-authorized-user-landing/NotAuthorizedUserLanding";
import { AuthorizedUserLanding } from "src/containers/Landing/components/authorized-user-landing/AuthorizedUserLanding";
import config from "src/config";

export default function Landing() {
    const { isAuthorized } = useAuthorizedState()
    const shouldUseAuthorizedView = config.enableWalletConnect && isAuthorized;

    return shouldUseAuthorizedView ? <AuthorizedUserLanding /> : <NotAuthorizedUserLanding />
}