
const getDocumentElements = () => {
    const elements: (HTMLElement | Window)[] = [];

    if (typeof document !== "undefined") {
        elements.push(document.documentElement, document.body);
    }

    if (typeof window !== "undefined") {
        elements.push(window);
    }

    return elements;
}

const documentElements = getDocumentElements()

export const isDocumentElement = (element: HTMLElement | Window) => {
    return documentElements.includes(element as HTMLElement);
}