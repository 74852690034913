import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useChanged } from "@ntropy/hooks";

export function useScrollTo() {
    const {pathname} = useLocation();
    const pathnameChanged = useChanged(pathname);

    useEffect(() => {
        if (pathnameChanged) {
            window.scrollTo(0, 0);
        }
    }, [pathnameChanged]);
}