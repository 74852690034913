import { createAppKit } from "@reown/appkit/react"
import { arbitrum, mainnet } from "@reown/appkit/networks"
import { WagmiAdapter } from "@reown/appkit-adapter-wagmi"
import { WagmiProvider } from "wagmi";
import type { AppKitNetwork } from "@reown/appkit-common";

const hostToProjectMap = {
    "satocash.com": "428b9ecf64b9d839b608b217fabc82cc",
    "localweb.satocash.com": "428b9ecf64b9d839b608b217fabc82cc",
}

const emptyProjectId = "___EMPTY__EMPTY__EMPTY__EMPTY___";

const host = window.location.host;
const projectId = hostToProjectMap[host] ?? emptyProjectId;

export const isWagmiProjectIdSet = projectId !== emptyProjectId;
export const ProjectWagmiProvider = WagmiProvider; // isWagmiProjectIdSet ? WagmiProvider : ({ children }: any) => children;

const setupWagmiAdapter = () => {
    const metadata = {
        name: "ntropy-test",
        description: "AppKit Example",
        url: `https://${host}/`, // origin must match your domain & subdomain
        icons: ["https://avatars.githubusercontent.com/u/37784886"],
    }

    const networks: [AppKitNetwork, ...AppKitNetwork[]] = [mainnet, arbitrum];

    const wagmiAdapter = new WagmiAdapter({
        networks,
        projectId,
    })

    createAppKit({
        adapters: [wagmiAdapter],
        networks,
        metadata,
        projectId,
        featuredWalletIds: [
            "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
            "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
            "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa",
            "f2436c67184f158d1beda5df53298ee84abfc367581e4505134b5bcf5f46697d",
            "c03dfee351b6fcc421b4494ea33b9d4b92a984f87aa76d1663bb28705e95034a",
        ],
        enableWalletConnect: isWagmiProjectIdSet,
        enableInjected: isWagmiProjectIdSet,
        enableCoinbase: true,
        features: {
            email: false,
            socials: false,
            analytics: true,
        },
    });

    return wagmiAdapter;
}

export const wagmiAdapter = setupWagmiAdapter()