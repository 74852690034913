import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "src/core/http/axios-instance";
import { Endpoint } from "src/core/http/endpoint";
import { ICurrencyChains, CryptoCurrency, Chain } from "src/core/currency/currency.model";

interface IUseCryptoChainsInput {
    enabled?: boolean
}

type ChainsPerCrypto = Record<CryptoCurrency, Chain[]>;

export function useCryptoChains({ enabled = true }: IUseCryptoChainsInput = {}) {
    const {
        data,
    } = useQuery({
        queryKey: ["crypto-chains"],
        initialData: {} as ChainsPerCrypto,
        queryFn: async () => {
            const list = await axiosInstance.get<ICurrencyChains[]>(Endpoint.CryptoChains)

            return list.reduce((acc, {currencyTicker, chains}) => {
                return {...acc, [currencyTicker]: chains};
            }, {} as ChainsPerCrypto)
        },
        enabled,
    });

    return data;
}