import { useMutation } from "@tanstack/react-query";
import { axiosInstance } from "src/core/http/axios-instance";
import { Endpoint } from "src/core/http/endpoint";
import { CryptoCurrency, Chain } from "src/core/currency/currency.model";
import { errorToast } from "src/helpers/toast";

export interface IWithdrawRequest {
    amount: number | null
    chain: Chain | null
    address: string
}

export const useWithdraw = (currency: CryptoCurrency) => {
    const { isError, error, mutateAsync } = useMutation({
        mutationFn: (request: IWithdrawRequest) => {
            if (!request.amount || !request.chain || !request.address || !currency) {
                throw new Error("Missing request parameters");
            }

            return axiosInstance.post(Endpoint.Withdraw(currency), request);
        },
    })

    if (isError) {
        errorToast("Error when requesting for withdrawal. Please contact support.");
        console.error("useWithdrawAddress ERROR", error);
    }

    return mutateAsync;
}

