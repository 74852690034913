import classNames from "classnames/bind";
import styles from "./assets/styles.scss";

import ImgLogoBeGambleAware from "./assets/images/logos/begambleaware.png?sizes[]=120";
import ImgLogoGA from "./assets/images/logos/ga.png";

import ImgLogoProvablyFair from "./assets/images/logos/provably-fair.svg";
import ImgLogoResponsibleGaming from "./assets/images/logos/responsible-gaming.svg";
import ImgLogoSafeSecure from "./assets/images/logos/safe-secure.svg";

import ImgIconAdult from "./assets/images/icons/eighteenplus.png";
import BottomFooterLinks from "src/components/footer/components/BottomFooterLinks";
import { SocialMediaLinks } from "src/components/social-media-links/SocialMediaLinks";
import Logo from "@pg/design-system/src/components/logo/Logo";

const cx = classNames.bind(styles);

const currentYear = new Date().getFullYear();

const Footer = () => {
    return (
        <>
            <footer>
                <div className={cx("footer-bottom-section", "container")}>
                    <div className={cx("footer-left-block")}>
                        <div className={cx("footer-left-block-top")}>
                            <div className="flex-shrink-0 w-[50px] mr-4 mt-4">
                                <Logo className="size-[50px]" />
                            </div>
                            <section className="flex flex-col lg:justify-between h-full w-full">
                                <div>
                                    <h6>© Peergame, {currentYear}</h6>
                                    <p className="xsmall">
                                        PeerGame is designed to accommodate 100% fairness and transparency. To use the site, you must be 18 years of age or
                                        older. If igaming and betting are not permitted in your region, PeerGame’s services are not available for your use.
                                    </p>
                                </div>
                                <div className={cx("footer-left-block-bottom")}>
                                    <img src={ImgLogoProvablyFair} alt="Provably Fair" />
                                    <img src={ImgLogoResponsibleGaming} alt="Responsible Gaming" />
                                    <img src={ImgLogoSafeSecure} alt="Safe Secure" />
                                </div>
                            </section>
                        </div>
                    </div>
                    <div className={cx("footer-menu-block")}>
                        <BottomFooterLinks />
                    </div>

                    <div className={cx("footer-right-block")}>
                        <SocialMediaLinks className="mb-6" />
                        <section className="flex flex-col items-center">
                            <div className={cx("footer-right-block-card", "my-6")}>
                                <img src={ImgIconAdult} alt="Adult" />
                                <div>
                                    <p className="xsmall">
                                        Gambling can be addictive.
                                        <br />
                                        Play responsibly.
                                    </p>
                                </div>
                            </div>
                            <div className={cx("footer-right-block-bottom")}>
                                <a
                                    href="https://www.begambleaware.org"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Be Gambling Aware"
                                >
                                    <img src={ImgLogoBeGambleAware} alt="Be Gambling Aware icon" />
                                </a>
                                <a
                                    href="https://www.gamblersanonymous.org.uk"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Gamblers Anonymous"
                                >
                                    <img src={ImgLogoGA} alt="Gamblers Anonymous icon" />
                                </a>
                            </div>
                        </section>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
