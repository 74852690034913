import { ComponentType, lazy, Suspense } from "react";
import { Loader } from "@pg/design-system";

interface ILazyPageProps {
    loader: () => Promise<{ default: ComponentType<any> }>
}

const LazyPage = ({ loader }: ILazyPageProps) => {
    const ImportedComponent = lazy(loader);

    return (
        <Suspense fallback={<Loader />}>
            <ImportedComponent />
        </Suspense>
    )
}

export default LazyPage;