import { useCallback, useContext, useEffect, useRef } from "react";
import io from "socket.io-client";
import BigNumber from "bignumber.js";
import { games, statisticsCountOptions } from "src/games/config";
import { useGameStore } from "src/components/game/hooks/useGameStore";
import { normalize } from "src/games/utils";
import useAppStore from "src/useAppStore";
import GameCodeContext from "src/containers/Games/GameCodeContext";
import config from "src/config";
import { rewardToast } from "src/helpers/toast";
import { useAppSelector } from "src/redux/reducer";
import { useQueryClient } from "@tanstack/react-query";
import { CryptoCurrency } from "src/core/currency/currency.model";
import { OriginalGames, GameCode } from "src/core/casino/casino.model";
import { useRefFactory, useRefValue } from "@ntropy/hooks";

interface IBet {
    game: GameCode;
    timestamp: string;
    txid: string;
    amount: number;
    asset: CryptoCurrency;
    multiplier: number;
    payout: number;
    winner: boolean;
    sendAddress: string;
    player_id: number;
    area: string;
    game_result: string;
    payoutBalance: number;
}

const allGameCodes = Object.keys(games);

export default function useGame(
    onGameResult?: (game: any) => void,
    isMultiplayer = false,
    onParticipate?: (isMine: boolean, {
        amount,
        area,
        txid,
    }: {
        amount: number;
        area: string;
        txid: string;
    }) => void
) {
    const queryClient = useQueryClient();
    const gameCode = useContext(GameCodeContext);

    const pgpRate = useAppSelector(state => state.info.settings?.pgp.distributeByWagerRate);
    const pendingTransactions = useRef<string[]>([]);
    const prevPendingTransactions = useRef<string[]>([]);
    const socket = useRefFactory(() => io(config.chatEndpoint, {
        transports: ["websocket", "polling"],
        autoConnect: false,
        reconnection: true,
        reconnectionDelay: 3000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity,
    }));

    const allBetsQueue = useRef([]);
    const highrollersQueue = useRef([]);

    function updateAllBets(betData: IBet) {
        const { allBets } = useGameStore.getState();
        useGameStore.getState().setAllBets([betData, ...allBets.slice(0, allBets.length - 1)]);
    }

    function updateHighRollers(betData: IBet) {
        const { highRollers } = useGameStore.getState();
        useGameStore.getState().setHighRollers([betData, ...highRollers.slice(0, highRollers.length - 1)]);
    }

    function flushQueues() {
        // update my bets
        if (queryClient.getQueryData(["mybets", gameCode])) {
            queryClient.setQueryData(["mybets", gameCode], (oldData: IBet[]) => [
                ...allBetsQueue.current,
                ...oldData.slice(0, oldData.length - allBetsQueue.current.length),
            ]);
        }

        // flush all bets and highrollers
        allBetsQueue.current.forEach(betData => updateAllBets(betData));
        highrollersQueue.current.forEach(betData => updateHighRollers(betData));
        allBetsQueue.current = [];
        highrollersQueue.current = [];
    }

    function updateStats(result) {
        statisticsCountOptions.forEach(count => {
            if (queryClient.getQueryData(["stats", gameCode, count])) {
                queryClient.setQueryData(["stats", gameCode, count], (oldData: IBet[]) => [
                    result,
                    ...oldData.slice(0, oldData.length - 1),
                ]);
            }
        });
    }

    useEffect(() => {
        flushQueues();
    }, [gameCode]);

    useEffect(() => {
        socket.current.open();

        socket.current.on("connect", () => {
            socket.current.emit("game", gameCode);
        });
        socket.current.on(gameCode === OriginalGames.Baccarat ? "/V2/COMPLETED" : "COMPLETED", data => {
            const gameData = JSON.parse(data).value;

            if (isMultiplayer) {
                onGameResult?.(gameData);
                useAppStore.getState().loadAssetBalance();
            } else {
                setTimeout(() => {
                    if (!pendingTransactions.current.includes(gameData.txid) && !prevPendingTransactions.current?.includes(gameData.txid)) {
                        return;
                    }

                    if (pendingTransactions.current.includes(gameData.txid) && onGameResult && normalize[gameCode]) {
                        const normalizedResult = normalize[gameCode](gameData);
                        onGameResult(normalizedResult);
                    }

                    useAppStore.getState().loadAssetBalance();
                }, 1000);
            }
        });

        socket.current.on("ALLBETS_HISTORY", data => {
            const resultDatas = JSON.parse(data)
                .reverse()
                .filter(({ game }) => allGameCodes.includes(game))
                .slice(0, 10);
            useGameStore.getState().setAllBets(resultDatas);
        });

        socket.current.on("ALLBETS", data => {
            const gameData = JSON.parse(data);

            if (!allGameCodes.includes(gameData.game)) {
                return;
            }
            if (gameCode !== gameData.game) {
                updateAllBets(gameData);
            } else {
                setTimeout(() => {
                    if (pendingTransactions.current.includes(gameData.txid)) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        allBetsQueue.current.push(gameData);
                    } else {
                        updateAllBets(gameData);
                    }
                }, 1000);
            }
        });

        socket.current.on("HIGHROLLERS_HISTORY", data => {
            const resultDatas = JSON.parse(data)
                .reverse()
                .filter(({ game }) => allGameCodes.includes(game))
                .slice(0, 10);
            useGameStore.getState().setHighRollers(resultDatas);
        });

        socket.current.on("HIGHROLLERS", data => {
            const gameData = JSON.parse(data);

            if (!allGameCodes.includes(gameData.game)) {
                return;
            }
            if (gameCode !== gameData.game) {
                updateHighRollers(gameData);
            } else {
                setTimeout(() => {
                    if (pendingTransactions.current.includes(gameData.txid)) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        highrollersQueue.current.push(gameData);
                    } else {
                        updateHighRollers(gameData);
                    }
                }, 1000);
            }
        });

        socket.current.on("WITHDRAW", data => {
            const { playerTxId } = JSON.parse(data).value;
            setTimeout(() => {
                if (pendingTransactions.current?.includes(playerTxId) || prevPendingTransactions.current?.includes(playerTxId)) {
                    setTimeout(() => {
                        rewardToast("Your prize has been sent to your wallet");
                        useAppStore.getState().loadAssetBalance();
                    }, games[gameCode || ""]?.rewardToastDelay);
                }
            }, 1000);
        });

        return () => {
            socket.current?.close();
        };
    }, [gameCode]);

    const onParticipateRef = useRefValue(onParticipate);

    useEffect(() => {
        if (isMultiplayer) {
            const listener = data => {
                const participationData = JSON.parse(data).value;
                onParticipateRef.current?.(
                    pendingTransactions.current.includes(participationData.txId || participationData.txid),
                    participationData,
                );
            };
            socket.current.on("PARTICIPATE", listener);
            return () => {
                socket.current.off("PARTICIPATE", listener);
            };
        }
    }, [isMultiplayer]);

    const onBetSuccess = useCallback(
        (transactionData: { txids?: string[], txid?: string }) => {
            if (isMultiplayer) {
                useGameStore.getState().setBettingDisabled(false);
            }

            if (transactionData) {
                if (transactionData.txid) {
                    pendingTransactions.current = [...pendingTransactions.current, transactionData.txid];
                } else if (transactionData.txids) {
                    pendingTransactions.current = [...pendingTransactions.current, ...transactionData.txids];
                }
            } else {
                onGameResult?.(null);
            }
        },
        [onGameResult, isMultiplayer],
    );

    const onAnimationFinished = useCallback(
        normalizedGameData => {

            if (!isMultiplayer) {
                useGameStore.getState().setBettingDisabled(false);
            }
            // FIXME: It should not work like that, but – for WITHDRAW event to work
            prevPendingTransactions.current = [...pendingTransactions.current];
            // reset pending transactions
            pendingTransactions.current = [];
            useGameStore.getState().setBettingDisabled(false);
            if (useGameStore.getState().isRealMode) {
                if (!isMultiplayer && normalizedGameData?.betAmount) {
                    useAppStore.getState().setAssetBalance(currentBalance => ({
                        PGP: currentBalance.PGP.plus(new BigNumber(normalizedGameData.betAmount).multipliedBy(pgpRate)),
                    }));
                    updateStats(normalizedGameData);
                }
                flushQueues();
            }
        },
        [pgpRate],
    );

    return { onBetSuccess, onAnimationFinished, socket: socket.current };
}
