import { FC } from "react";
import { Navigate } from "react-router-dom";
import { useRemoveTrailingSlash } from "src/core/router/hooks/useRemoveTrailingSlash";

interface IRemoveTrailingSlashProps {
}

const RemoveTrailingSlash: FC<IRemoveTrailingSlashProps> = () => {
    const {
        hasTrailingSlash,
        finalPathname,
    } = useRemoveTrailingSlash();

    if (!hasTrailingSlash) return null;

    return (
        <Navigate to={finalPathname} replace />
    );
};

export default RemoveTrailingSlash;