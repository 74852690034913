import { toast } from "react-toastify";
import classNames from "classnames/bind";
import styles from "src/components/toast/assets/styles.scss";
import Icon from "@pg/design-system/src/components/icons/Icon";

const cx = classNames.bind(styles);

export const rewardToast = message => toast(message, { className: cx("reward"), hideProgressBar: false });
export const successToast = message => toast(
    <div className="grid grid-cols-[auto_1fr] items-center gap-2">
        <Icon.Success color={null} size="size-6" />
        <span>{message}</span>
    </div>,
    { className: cx("success"), closeButton: false },
);
export const errorToast = message => toast(
    <div className="grid grid-cols-[auto_1fr] items-center gap-2">
        <Icon.Alert color={null} size="size-6" />
        <span>{message}</span>
    </div>,
    { className: cx("error"), closeButton: false },
);
export const warningToast = message => toast(
    <div className="grid grid-cols-[auto_1fr] items-center gap-2">
        <Icon.Alert color={null} size="size-6" />
        <span>{message}</span>
    </div>,
    { className: cx("warning"), closeButton: false, pauseOnFocusLoss: true },
);