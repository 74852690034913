import { useAuthorizedState } from "src/core/auth/hooks/useAuthorizedState";
import { WalletProvider } from "src/core/wallet/wallet.model";
import config from "src/config";

export const useCurrentUsedApi = () => {
    const { isAuthorized, walletProvider } = useAuthorizedState();
    const shouldUseCashier = (walletProvider === WalletProvider.WalletConnect || !isAuthorized) && config.enableWalletConnect;
    const shouldUseHandcash = !shouldUseCashier;

    return { shouldUseCashier, shouldUseHandcash } as const;
}