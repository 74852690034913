import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import BigNumber from "bignumber.js";
import { useEffect, useRef } from "react";
import { axiosInstance } from "src/core/http/axios-instance";
import { errorToast, successToast } from "src/helpers/toast";
import useTableDeposit from "../GameBalance/useTableDeposit";
import useAppStore from "useAppStore";
import { useAppSelector } from "src/redux/reducer";
import { useAppSocket } from "src/core/application/hooks/useAppSocket";
import { useAuthorizedState } from "src/core/auth/hooks/useAuthorizedState";
import { WalletProvider } from "src/core/wallet/wallet.model";

async function getPending() {
    const { data } = await axiosInstance.get("/v1/deposit/history", {
        params: {
            asset: "BSV",
            task: "WITHDRAW",
            status: "PENDING",
        },
    });
    return data.reduce((accumulator, { amount }) => accumulator.plus(amount), new BigNumber(0));
}

async function getHolding() {
    const { data } = await axiosInstance.get("/v1/deposit/history", {
        params: {
            asset: "BSV",
            task: "WITHDRAW",
            status: "HOLDING",
        },
    });
    return data.reduce((accumulator, { amount }) => accumulator.plus(amount), new BigNumber(0));
}

async function withdraw(amount) {
    await axiosInstance.post("/v1/deposit/withdraw", { asset: "BSV", amount });
    return amount;
}

async function cancelWithdraw() {
    const { data } = await axiosInstance.post("/v1/deposit/withdrawcancel", { asset: "BSV" })
    return data;
}

export default function useCashout() {
    const queryClient = useQueryClient();
    const walletName = useRef(null);
    const currentWallet = useAppSelector(state => state.wallets.currentWallet);
    const {isAuthorized: isAuthorizedWithHandcash} = useAuthorizedState(WalletProvider.HandCash);
    const { depositBalance, setBalance } = useTableDeposit();
    const { data: pendingCashout } = useQuery({
        queryKey: ["pendingCashout"],
        queryFn: getPending,
        enabled: isAuthorizedWithHandcash,
    });
    const { data: holdingCashout, refetch: refetchHolding } = useQuery({
        queryKey: ["holdingCashout"],
        queryFn: getHolding,
        enabled: isAuthorizedWithHandcash,
    });
    const { mutateAsync: cashout, isLoading: isCashingOut } = useMutation({
        mutationFn: () => withdraw(depositBalance),
        onSuccess: amount => {
            successToast("Cashout successful");
            setBalance(0);
            queryClient.setQueryData(["pendingCashout"], amount);
        },
        onError: () => {
            errorToast("Cashout Error. Please refresh your browser and try again.");
        },
    });
    const { mutateAsync: cancelHolding, isLoading: isCancellingHolding } = useMutation({
        mutationFn: cancelWithdraw,
        onSuccess: ({ pending_amount: pendingAmount, balance }) => {
            successToast("Holding amount cancelled");
            setBalance(balance);
            queryClient.setQueryData(["holdingCashout"], new BigNumber(0));
            queryClient.setQueryData(["pendingCashout"], new BigNumber(pendingAmount));
        },
    });

    useEffect(() => {
        if (currentWallet) {
            walletName.current = currentWallet.name;
        }
    }, [currentWallet]);

    const socket = useAppSocket();

    useEffect(() => {
        if (!socket.hasListeners("WITHDRAW_COMPLETED")) {
            socket.on("WITHDRAW_COMPLETED", data => {
                const { amount } = JSON.parse(data);
                queryClient.setQueryData(["pendingCashout"], _cashout => _cashout.minus(amount));
                if (walletName.current !== "handcash") {
                    useAppStore.getState().setAssetBalance(currentBalance => ({
                        BSV: currentBalance.BSV.plus(amount),
                    }));
                }
            });
        }
    }, []);

    return {
        pendingCashout,
        holdingCashout,
        refetchHolding,
        cashout,
        cancelHolding,
        isCashingOut,
        isCancellingHolding,
    };
}
