import mapValues from "lodash/mapValues";

export enum Breakpoint {
    Xs = "xs",
    Sm = "sm",
    Md = "md",
    Lg = "lg",
    Xl = "xl",
    Xxl = "2xl",
}

// min values
export const breakpointsMin = {
    [Breakpoint.Xs]: 0,
    [Breakpoint.Sm]: 480,
    [Breakpoint.Md]: 768,
    [Breakpoint.Lg]: 1024,
    [Breakpoint.Xl]: 1280,
    [Breakpoint.Xxl]: 1536,
} satisfies Record<Breakpoint, number>

export const screens = mapValues(breakpointsMin, v => `${v}px`);