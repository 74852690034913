import { useLgUp } from "src/helpers/responsive";
import useGamesList, { IMappedGame } from "src/games/hooks/useGamesList";
import { CasinoGameCategory } from "src/core/casino/casino.model";
import { GameCardCarouselItem, ThumbnailVariant } from "src/components/game-card-carousel-item/GameCardCarouselItem";

export const useHandleGames = () => {
    const isLgUp = useLgUp();

    const limitSize = isLgUp ? 10 : 9;

    const { games: originalGames, isLoading: isOriginalGamesLoading } = useGamesList(CasinoGameCategory.Originals, limitSize);
    const { games: slotsGames, isLoading: isSlotsGamesLoading } = useGamesList(CasinoGameCategory.Slots, limitSize);
    const { games: tableGames, isLoading: isTableGamesLoading } = useGamesList(CasinoGameCategory.Table, limitSize);

    const isLoading = isOriginalGamesLoading || isSlotsGamesLoading || isTableGamesLoading;

    const getFilteredGames = (category: CasinoGameCategory) => {
        let filteredGames: IMappedGame[] = [];

        switch (category) {
            case CasinoGameCategory.Originals:
                filteredGames = originalGames;
                break;
            case CasinoGameCategory.Slots:
                filteredGames = slotsGames;
                break;
            case CasinoGameCategory.Table:
                filteredGames = tableGames;
                break;
        }
        return filteredGames;
    }

    const getGamesThumbnail = (category: CasinoGameCategory, variant: ThumbnailVariant = "small") => getFilteredGames(category).map(({
        title, thumbnailSrc, url, provider, isDisabled,
    }) => {
        return (
            <GameCardCarouselItem
                key={title}
                url={url}
                provider={provider}
                thumbnailSrc={thumbnailSrc}
                title={title}
                isDisabled={isDisabled}
                variant={variant}
            />
        );
    })

    return {
        isLoading,
        getFilteredGames,
        getGamesThumbnail,
    }
}