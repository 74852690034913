import { createEnumParam } from "serialize-query-params";
import { enumToValues } from "@ntropy/utils/src/enum-utils";

export enum CasinoGameCategory {
    Originals = "originals",
    Slots = "slots",
    Table = "table",
    Casual = "casual",
    Crash = "crash",
    Craps = "craps",
    Mines = "mines",
    Card = "card",
    Roulette = "roulette",
}

const allCasinoGameCategories = enumToValues(CasinoGameCategory);

export const isCasinoGameCategory = (value: GameCode | "" | null | undefined): value is CasinoGameCategory => {
    return allCasinoGameCategories.includes(value as any);
}

export const isExternalGameCategory = (value: GameCode | "" | null | undefined) => {
    return isCasinoGameCategory(value) && value !== CasinoGameCategory.Originals;
}

export enum OriginalGames {
    Blackjack = "blackjack",
    Dice = "dice",
    Wheel = "wheel",
    Roulette = "roulette",
    Coinflip = "coinflip",
    Baccarat = "baccarat",
    Ladder = "ladder",
    Turtle = "turtle",
    CfBattle = "cfBattle",
}

const allOriginalGames = enumToValues(OriginalGames);

export const isOriginalGame = (value: GameCode | "" | null | undefined): value is OriginalGames => {
    return allOriginalGames.includes(value as any);
}

export const DeprecatedBitto = "bitto";

export type GameCode = OriginalGames | CasinoGameCategory;

export const GameCodeParam = createEnumParam([...allOriginalGames, ...allCasinoGameCategories])