import { cloneElement, ComponentType, ReactElement, ReactNode, Key } from "react";
import isEqual from "react-fast-compare";
import { IMergedSequentialList, mergeSequentialListsByIdentifier } from "../array-utils";
import { isSet } from "../type-utils";
import { guid } from "../text-utils";
import reject from "lodash/reject";
import isNull from "lodash/isNull";
import { CommonKey } from "../typescript-helpers";

export const isReactElement = <T = any>(element: any): element is ReactElement<T> => {
    return !!element && typeof element === "object" && ("type" in element && "key" in element && "props" in element);
};

export function isChildOfType<T>(element: ReactNode, type: ComponentType<T>): element is ReactElement<T> {
    return isReactElement(element) && element.type === type;
}

export const isElementsList = (childList: any[]): childList is ReactElement[] => {
    return childList.reduce((acc, child) => acc && isReactElement(child), true) as boolean;
};

export const isReactElementsList = (childList: any): childList is ReactElement[] => {
    return Array.isArray(childList) && isElementsList(childList);
};

export const getElementKey = (element: ReactElement): CommonKey => {
    return element.key as CommonKey;
};

export const getListKeys = (list: ListWithKeys): CommonKey[] => {
    return (list as ReactElement[]).map(getElementKey);
};

export const getListProps = (list: { props: any }[]): CommonKey[] => {
    return list.map(c => c.props);
};

export const areChildrenKeysEqual = <T>(
    childrenBefore: ReactElement<T>[],
    childrenAfter: ReactElement<T>[],
    keysSelector: (list: any[]) => CommonKey[] = getListKeys,
) => {
    const keysBefore = keysSelector(childrenBefore);
    const keysAfter = keysSelector(childrenAfter);

    return isEqual(keysBefore, keysAfter);
};

export const areChildrenKeysAndPropsEqual = <T>(
    childrenBefore: ReactElement<T>[],
    childrenAfter: ReactElement<T>[],
    keysSelector: (list: any[]) => CommonKey[] = getListKeys,
    propsSelector: (list: any[]) => any[] = getListProps,
) => {
    const keysBefore = keysSelector(childrenBefore);
    const keysAfter = keysSelector(childrenAfter);
    const propsBefore = propsSelector(childrenBefore);
    const propsAfter = propsSelector(childrenAfter);

    return isEqual(keysBefore, keysAfter) && isEqual(propsBefore, propsAfter);
};

export const isChildPropsEqual = <T>(
    childA: ReactElement<T>,
    childB: ReactElement<T>,
) => {
    return isEqual(childA?.props, childB?.props);
};

export const mergeChildrenByTransitionKey = <T extends { transitionKey: string }>(
    childrenBefore: ReactElement<T>[],
    childrenAfter: ReactElement<T>[],
): IMergedSequentialList<ReactElement<T>> => {
    return mergeSequentialListsByIdentifier<ReactElement<T>>(childrenBefore, childrenAfter, getTransitionKey, cloneElement);
};

export const mergeChildren = (
    childrenBefore: ReactElement[],
    childrenAfter: ReactElement[],
): IMergedSequentialList<ReactElement> => {
    return mergeSequentialListsByIdentifier(childrenBefore, childrenAfter, getElementKey, cloneElement);
};

type ListWithKeys = { key: Key | null }[];
type ListWithTransitionKeys = { props: { transitionKey: string } }[];
type ShallowListWithTransitionKeys = { transitionKey: string }[];

export const areKeysEqual = (listWithKeys: ListWithKeys, listToCompare: ListWithKeys) => {
    const keys = getListKeys(listWithKeys);
    const keysToCompare = getListKeys(listToCompare);

    return isEqual(keys, keysToCompare);
};

export const getTransitionKey = (list: ReactElement<{ transitionKey: string }>): string => {
    return list.props.transitionKey;
};

export const getListTransitionKeys = (list: ListWithTransitionKeys): string[] => {
    return (list as ReactElement<{ transitionKey: string }>[]).map(getTransitionKey);
};

export const getListTransitionKeysShallow = (list: ShallowListWithTransitionKeys): string[] => {
    return list.map(({ transitionKey }) => transitionKey);
};

export const areTransitionKeysEqual = <T extends ListWithTransitionKeys | ShallowListWithTransitionKeys = ListWithTransitionKeys>(
    listWithKeys: T,
    listToCompare: T,
    keysSelector: (list: any[]) => string[] = getListTransitionKeys,
) => {
    const keys = keysSelector(listWithKeys);
    const keysToCompare = keysSelector(listToCompare);

    if (keys.find(key => key === undefined) || keysToCompare.find(key => key === undefined)) {
        console.error("Transitioning elements on a list should have a unique transitionKey set for lifetime of a transition");
    }

    return isEqual(keys, keysToCompare);
};

export const tryFlattenToElementsList = (childList: any): ReactElement[] | null => {
    if (Array.isArray(childList)) {
        if (isElementsList(childList)) {
            return childList;
        } else {
            let success = true;
            const childListToUse = childList.reduce((acc: ReactElement[], child) => {
                if (isReactElement(child)) {
                    return [...acc, child];
                } else if (isReactElementsList(child)) {
                    return [...acc, ...child];
                } else {
                    success = false;
                    return acc;
                }
            }, []);

            if (!success) {
                return null;
            }

            return childListToUse as ReactElement[];
        }
    }

    return null;
};

export function getFragmentChildren(
    fragment: ReactElement,
    asArray?: false,
): ReactElement | ReactElement[];
export function getFragmentChildren(fragment: ReactElement, asArray: true): ReactElement[];
export function getFragmentChildren(fragment: ReactElement, asArray?: boolean) {
    let fragmentChildren = fragment.props.children;

    fragmentChildren = Array.isArray(fragmentChildren)
        ? fragmentChildren.filter((c) => !!c && typeof c === "object")
        : fragmentChildren;
    fragmentChildren = tryFlattenToElementsList(fragmentChildren);

    if (!isReactElementsList(fragmentChildren)) {
        if (asArray && isReactElement(fragment.props.children)) {
            return [fragment.props.children];
        }

        if (asArray) {
            return [];
        }

        return fragment as ReactElement;
    }

    return fragmentChildren as ReactElement[];
}

export const getChildrenHeights = (currentWrapper: HTMLElement | null, {keys, keyPrefix}: { keys?: CommonKey[], keyPrefix?: string }): Record<CommonKey, number> => {
    if (!currentWrapper) {
        return {};
    }

    const keyPrefixRegExp = new RegExp(`^${keyPrefix}-?`);
    const currentRefChildren = (Array.from(currentWrapper.children) as HTMLElement[]).filter(e => e.nodeName !== "STYLE");

    return currentRefChildren
        .map((c, i) => {
            return {
                height: Math.round(c?.getBoundingClientRect()?.height),
                key: c.getAttribute("data-key") ?? c.id ?? keys?.[i] ?? null,
            };
        })
        .reduce((acc, { height, key }) => {
            if (!isSet(key) || height === 0) return acc;

            return { ...acc, [key.toString().replace(keyPrefixRegExp, "")]: height };
        }, {} as Record<CommonKey, number>);
};

export const isHTMLElement = (element: any): element is HTMLElement => {
    return !!element && !!element.style;
};


/*
    Get elements from the array to fit in columns
    | a1 | b1 | c1 | d1 |
    | a2 | b2 | c2 | d2 |  => [a1, b1, c1, d1, a2, b2, c2, d2, a3...]
    | a3 | b3 | c3 | d3 |
    | a4 | b4 | c4 | d4 |
*/
const getNthElementOfMLongArray = (rows: number, list: any[][]) => {
    const result: any[] = [];
    for (let i = 0; i < rows; i++) {
        const subResult: any[] = [];
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let j = 0; j < list.length; j++) {
            if (list[j][i] !== undefined) {
                subResult.push(list[j][i]);
            }
        }
        if (subResult.length > 0) {
            result.push(...subResult);
        }
    }
    return result;
};

const setEqualNumberOfEmptyElements = (list: any[], desiredNumberOfEmptyEl: number, emptyChild: React.ReactElement) => {
    if (list.length > desiredNumberOfEmptyEl) {
        return list;
    }
    return [
        ...list,
        ...[...Array(desiredNumberOfEmptyEl - list.length)].map(() => {
            return { ...emptyChild, key: guid(), props: { ...emptyChild.props } };
        }),
    ];
};

export type DescriptionColumnSize = 1 | 2 | 3 | 4;
const concatenateList = (list: any[][], resultLength: DescriptionColumnSize, emptyChild: React.ReactElement) => {
    const spacer = () => ({ ...emptyChild, key: guid(), props: { ...emptyChild?.props, empty: false } });
    switch (resultLength) {
        case 2:
            switch (list.length) {
                case 3:
                case 4:
                    const list1 = [...list[0], spacer(), ...list[1]].filter((el: any) => !el?.props?.empty);
                    const list2 = [...list[2], spacer(), ...(list[3] ?? [])].filter((el: any) => !el?.props?.empty);
                    return [
                        [...setEqualNumberOfEmptyElements(list1, list2.length, emptyChild)],
                        [...setEqualNumberOfEmptyElements(list2, list1.length, emptyChild)],
                    ];
                default:
                    return list;
            }
        case 1:
            return [
                list.reduce((_, accumulator) => {
                    return [..._, spacer, ...accumulator];
                }),
            ].map((l) => l.filter((el: any) => !el?.props?.empty));
        default:
            const max = Math.max(list[0].length, list[1].length, list[2].length, list[3].length);
            return [
                [...setEqualNumberOfEmptyElements(list[0], max, emptyChild)],
                [...setEqualNumberOfEmptyElements(list[1], max, emptyChild)],
                [...setEqualNumberOfEmptyElements(list[2], max, emptyChild)],
                [...setEqualNumberOfEmptyElements(list[3], max, emptyChild)],
            ];
    }
};

export const getListInColumns = (listOfListOfElements: (ReactElement | null)[][], columns: DescriptionColumnSize, emptyChild: React.ReactElement) => {
    const listOfListOfElementsWithoutNulls = listOfListOfElements.map((list) => reject(list, isNull));
    const concatenatedListOfListWithAddedEmptyElements = concatenateList(listOfListOfElementsWithoutNulls, columns, emptyChild);
    const max = Math.max(...concatenatedListOfListWithAddedEmptyElements.map((list) => list.length));
    const maxItemsInColumn = isNaN(max) ? concatenatedListOfListWithAddedEmptyElements.length : max;

    return getNthElementOfMLongArray(maxItemsInColumn, concatenatedListOfListWithAddedEmptyElements);
};

export const removePropWithNoName = (props: { ""?: any; [x: string]: any }) => {
    const singleCharProp = Object.keys(props).find((k) => k.length === 1);

    if (!!singleCharProp && isSet(props?.[singleCharProp])) {
        delete props?.[singleCharProp];
    }

    if (isSet(props?.[""])) {
        delete props?.[""];
    }
};
