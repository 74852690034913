import { isSet } from "@ntropy/utils/src/type-utils";
import isEqual from "react-fast-compare";

export type Predicate<T, A extends any[] = any[]> = (prev: T | undefined, next: T, ...args: A) => boolean;

export const strictEquals: Predicate<any, []> = (prev: any, next: any) => prev === next;
export const strictEqualsArray: Predicate<any[] | readonly any[] | undefined, []> = (prev, next) => !!next && !!prev?.every((prev, i) => prev === next[i]);
export const strictEqualAsTruthy = <T extends number | string | null | undefined>(prev: T | undefined, next: T) => !isSet(prev) || !isSet(next) || strictEquals(prev, next);

// Below group is mainly for usage in hooks along with useFirstMount
export const strictEqualArrayWithoutFirst = <T extends any[] | readonly any[] | undefined>(prev: T, next: T, isFirstMount: boolean) => isFirstMount || strictEqualsArray(prev, next);
export const strictEqualWithoutFirst = <T>(prev: T | undefined, next: T, isFirstMount: boolean) => isFirstMount || strictEquals(prev, next);
export const deepEqualWithoutFirst = <T>(prev: T | undefined, next: T, isFirstMount: boolean) => isFirstMount || isEqual(prev, next);
