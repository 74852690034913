import {useCookies} from "react-cookie";
import { PgCampaignCookie } from "../affiliate.conts";

export default function useAffiliateScript() {
    const [cookies] = useCookies([PgCampaignCookie]);
    const campaignCode = cookies[PgCampaignCookie];

    return campaignCode
        ? `|COMBINE|peergame.com|REFERRAL|${JSON.stringify({
            v: "0",
            d: {
                c: campaignCode,
            },
        })}`
        : "";
}
