import { Primitive, Include } from "@ntropy/utils/src/typescript-helpers";
import isNaN from "lodash/isNaN";

export type NullishValue = null | undefined;
export type NullishLikeValue = null | undefined | "null" | "undefined";
export type VoidValue = void | undefined;
export type SetValue = object | any[] | Exclude<Primitive, NullishValue>;

export const isSet = <T>(value: T): value is Include<T, SetValue> => {
    return !(value === null || value === undefined || isNaN(value));
};

export const isNotSet = (value: any): value is NullishValue => {
    return value === null || value === undefined;
};

export const isLikeNotSet = (value: any): value is NullishLikeValue => {
    return isNotSet(value) || ["null", "undefined"].includes(value);
}

export const asNotSet = (value: NullishLikeValue): NullishValue => {
    if (value === "null") return null;
    if (value === "undefined") return undefined;

    return value;
}

export const isFilled = <T>(value: T): value is Include<T, SetValue> => {
    return isSet(value) && value !== "";
};

export const isVoid = <T>(value: T): value is Include<T, VoidValue> => {
    return value === undefined;
}

export const isNotVoid = <T>(value: T): value is Exclude<T, VoidValue> => {
    return value !== undefined;
}