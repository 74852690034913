/* eslint-disable camelcase */

/*
Normalize function turns game data received from the server into a normalized structure used for all games.
It supports input from both the api OR websocket. The output object must contain the following:
{gameId: number, txId: string, seed: string, betAmount: number, playerPick: {label: string},
result: {label: string}, payout: number, payoutRate: string, payoutTxId: string, isWin: boolean}
*/

import BigNumber from "bignumber.js";
import { coinSides } from "./Coinflip/config";
import { sectionTypes } from "./Wheel/config";
import { areas, wheelSections } from "./Roulette/config";
import { getValueColour } from "./Roulette/utils";
import { games } from "src/games/config";

export const normalize = {
    common: gameData => {
        const gameId = gameData.game_id || gameData.id || gameData.players?.[0]?.game_id;
        const { seed } = gameData;
        const txId = gameData.txids?.[0] || gameData.players?.[0]?.txid || gameData.txid;
        const payoutTxId = gameData.players?.[0]?.payout_txid;
        const asset = gameData.players?.[0]?.asset || gameData.asset;

        return {
            gameId,
            seed,
            txId,
            payoutTxId,
            asset,
        };
    },
    coinflip: gameData => {
        const commonData = normalize.common(gameData);

        const betAmount = gameData.players?.[0]?.amount || gameData.result.winners?.betAmount;

        const playerPick = { value: gameData.players?.[0]?.area || (gameData.players && Object.keys(gameData.players)[0]) };
        playerPick.label = coinSides[playerPick.value]?.label;

        const result = { value: gameData.players?.[0]?.result || gameData.result?.result || gameData.result };
        result.label = coinSides[result.value]?.label;

        const isWin = playerPick.value === result.value;
        const payout = isWin ? gameData.players?.[0]?.payout || gameData.result.winners.rewardAmount : 0;
        const payoutRate = payout > 0 ? new BigNumber(payout).dividedBy(betAmount).toFixed(2) : "0.00";

        return {
            ...commonData,
            betAmount,
            playerPick,
            result,
            payout,
            payoutRate,
            isWin,
        };
    },
    wheel: gameData => {
        const commonData = normalize.common(gameData);

        const betAmount = gameData.players?.[0]?.amount || gameData.result.winners?.betAmount;

        const playerPick = { value: gameData.players?.[0]?.area || (gameData.players && Object.keys(gameData.players)[0]) };
        playerPick.label = sectionTypes[playerPick.value]?.label;

        const result = { value: gameData.players?.[0]?.result || gameData.result?.result || gameData.result };
        result.label = sectionTypes[result.value]?.label;

        const isWin = gameData.result?.winners?.rewardAmount
            || gameData.players?.[0]?.winner === "Y"
            || gameData.players?.[0]?.is_win === "Y";
        const payout = isWin ? gameData.players?.[0]?.payout || gameData.result.winners.rewardAmount : 0;
        const payoutRate = payout > 0 ? new BigNumber(payout).dividedBy(betAmount).toFixed(2) : "0.00";

        return {
            ...commonData,
            betAmount,
            playerPick,
            result,
            payout,
            payoutRate,
            isWin,
        };
    },
    roulette: gameData => {
        const commonData = normalize.common(gameData);

        let betAmount = new BigNumber(0);

        const bets = gameData.players?.map(({
            amount, area, payout, winner,
        }) => {
            if (typeof area === "string") {
                const [type, details] = area.split(":");
                area = { type, details };
            }
            betAmount = betAmount.plus(amount);
            return {
                betAmount: amount,
                area: {
                    type: area.type,
                    // eslint-disable-next-line no-restricted-globals
                    details: isNaN(area.details) ? area.details : Number(area.details),
                    label: areas[area.type].getLabel(area.details),
                },
                payout,
                payoutRate: payout ? new BigNumber(payout).dividedBy(amount).toFixed(2) : "0.00",
                isWin: winner === "Y",
                asset: commonData.asset,
            };
        });
        betAmount = betAmount.toFixed();

        const result = { label: gameData.result?.result?.toString() || gameData.result || gameData.players[0].result };
        result.value = Number(result.label);
        result.color = getValueColour(wheelSections, result.value);

        const payout = bets
            // eslint-disable-next-line no-shadow
            ?.reduce((current, { payout }) => (payout ? current.plus(payout) : current), new BigNumber(0))
            .toFixed();

        return {
            ...commonData,
            betAmount,
            bets,
            result,
            payout,
        };
    },
    dice: gameData => {
        const commonData = normalize.common(gameData);

        const betAmount = gameData.players?.[0]?.amount || gameData.result.winners?.betAmount;

        const playerPick = { value: gameData.players?.[0]?.area || (gameData.players && Object.keys(gameData.players)[0]) };
        if (typeof playerPick.value === "string") {
            playerPick.label = games.dice.formatPick(playerPick.value);
            const [roll, side] = playerPick.value.split(":");
            playerPick.value = { roll, side };
        } else {
            playerPick.label = playerPick.value && games.dice.formatPick(`${playerPick.value.roll}:${playerPick.value.side}`);
        }

        const result = { value: gameData.players?.[0]?.result || gameData.result?.result || gameData.result };
        result.value = Number(result.value);
        result.label = games.dice.formatResult(result.value);

        const isWin = gameData.result?.winners?.rewardAmount
            || gameData.players?.[0]?.winner === "Y"
            || gameData.players?.[0]?.is_win === "Y";
        const payout = isWin ? gameData.players?.[0]?.payout || gameData.result.winners.rewardAmount : 0;
        const payoutRate = payout > 0 ? new BigNumber(payout).dividedBy(betAmount).toFixed(2) : "0.00";

        return {
            ...commonData,
            betAmount,
            playerPick,
            result,
            payout,
            payoutRate,
            isWin,
        };
    },
    cfBattle: gameData => {
        const gameId = gameData.game_id;
        const roomId = gameData.room_id || gameData.roomId;
        const { status, seed } = gameData;
        const betAmount = gameData.participate_balance || gameData.amount || gameData.players?.[0].amount;

        const result = { value: gameData.result };
        result.label = coinSides[result.value]?.label;

        let players = [];
        const userInfo = gameData.user_info || gameData.userInfo;
        if (userInfo) {
            const { nickname } = userInfo;
            players.push({
                nickname,
                profilePic: userInfo.profileImageUrl || userInfo.profile_image_url,
                encryptedPaymail: userInfo.encrypt_paymail || userInfo.encryptPaymail,
                side: gameData.area,
            });
            players.push({ side: gameData.area === "H" ? "T" : "H" });
        } else if (gameData.players) {
            players = gameData.players.map(({ area, user_info, winner }) => {
                const { nickname, profile_image_url: profilePic, encrypt_paymail: encryptedPaymail } = user_info;
                return {
                    side: area,
                    nickname,
                    profilePic,
                    encryptedPaymail,
                    isWinner: winner === "Y",
                };
            });

            // add the other player
            if (players.length === 1) {
                players.push({ side: players[0].side === "H" ? "T" : "H" });
            }
        }
        const txId = gameData.txids?.[0];

        return {
            gameId,
            roomId,
            status,
            betAmount,
            players,
            result,
            seed,
            txId,
        };
    },
    ladder: gameData => {
        const { seed_hash: seed, game_id: gameId, game_result: gameResult } = gameData;

        const result = { value: gameResult };
        result.label = games.ladder.formatResult(result.value);

        return {
            gameId,
            seed,
            result,
            txId: null,
        };
    },
};

// returns a 2d array organizing the provided results into streak columns
export function getStreaksTable(results, isEqual = (a, b) => a === b) {
    const streaks = Array.from(Array(6), () => new Array(11).fill(null));
    let streakGroups = [];

    for (let i = results.length - 1; i >= 0; i -= 1) {
        if (i === results.length - 1 || !isEqual(streakGroups[streakGroups.length - 1][0], results[i].result)) {
            streakGroups.push([]);
        }
        streakGroups[streakGroups.length - 1].push(results[i].result);
    }
    streakGroups = streakGroups.slice(-11);

    // group (column)
    for (let i = 0; i < streakGroups.length; i += 1) {
        const currentGroup = streakGroups[i];
        let horizontalPointer = 0;
        let horizontalRow;

        for (let j = 0; j < currentGroup.length; j += 1) {
            if (horizontalPointer > 0) {
                if (i + horizontalPointer === streaks[horizontalRow].length) {
                    // if we need extra column, add an extra column to all rows
                    streaks.forEach(row => row.push(null));
                }
                streaks[horizontalRow][i + horizontalPointer] = currentGroup[j] + (j === currentGroup.length - 1 ? "CORNER" : "");
                horizontalPointer += 1;
            } else {
                streaks[j][i] = currentGroup[j];

                if (j === streaks.length - 1 || streaks[j + 1]?.[i]) {
                    horizontalPointer = 1;
                    horizontalRow = j;
                }
            }
        }
    }
    return streaks.map(row => row.slice(-11));
}