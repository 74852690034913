import { FC } from "react";
import { WalletConnectProgressDialog } from "src/feature/auth/dialogs/WalletConnectProgressDialog";
import AuthModal from "src/feature/auth/dialogs/AuthModal";

const ModalsLayer: FC = () => {
    return (
        <>
            <WalletConnectProgressDialog />
            <AuthModal />
        </>
    );
};

export default ModalsLayer;