import { useState, FC, useEffect } from "react";
import { Dialog, DialogDescription, DialogContent, DialogHeader, DialogTitle, SquareLoader } from "@pg/design-system";
import { useAuthorizeWalletConnect } from "src/core/wallet/hooks/useAuthorizeWalletConnect";
import { useLogout } from "src/core/auth/hooks/useLogout";
import bg from "src/feature/auth/assets/images/progress-modal-bg.png";
import errorBg from "src/feature/auth/assets/images/progress-modal-error-bg.png";
import { useMobileOnly } from "src/helpers/responsive";
import ErrorButtons from "src/feature/auth/components/error-buttons/ErrorButtons";

const WalletConnectProgressDialogContent: FC<{
    onShowModal(show: boolean): void
    handleClose(): void
    isErrorState: boolean
    onError(error: boolean): void
}> = ({ onShowModal, handleClose, onError, isErrorState }) => {
    const isMobileOnly = useMobileOnly();

    const { isError, message } = useAuthorizeWalletConnect({
        onAuthorizeStart: () => onShowModal(true),
        onAuthorizeFinish: () => onShowModal(false),
    });

    useEffect(() => {
        onError(isError);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError]);

    return (
        <DialogContent slimPadding className="max-w-xl max-h-full md:max-h-[80%] flex gap-6">
            {!isMobileOnly &&
                <img
                    className="h-full aspect-auto sm:my-[-16px] sm:ml-[-16px] rounded-[8px]"
                    src={isErrorState ? errorBg : bg}
                    alt="sign in"
                />
            }
            <div className="flex flex-col">
                <DialogHeader>
                    <DialogTitle className="text-left inline-flex flex-row text-[28px] mb-6 leading-[36px] pr-[30px]">
                        {isErrorState ?
                            "Error occurred"
                            :
                            "You have to sign in at your mobile device"
                        }
                    </DialogTitle>
                </DialogHeader>
                <DialogDescription className="font-md leading-[22px] mb-6">
                    {isErrorState ?
                        `${message ?? ""} Please try again or contact support.`.trim()
                        :
                        "Use your mobile device to sign in and start using our casino right away!"
                    }
                </DialogDescription>

                {isErrorState ?
                    <ErrorButtons onClose={handleClose} />
                    :
                    <SquareLoader className="self-center my-auto" />
                }
            </div>
        </DialogContent>
    );
}

export const WalletConnectProgressDialog = () => {
    const [showModal, setShowModal] = useState(false);
    const [isError, setError] = useState(false);

    const logout = useLogout();

    const handleClose = () => {
        setShowModal(false);
        setError(false);

        if (isError) {
            logout();
        }
    }

    return (
        <Dialog open={showModal} onOpenChange={handleClose}>
            <WalletConnectProgressDialogContent isErrorState={isError} onShowModal={setShowModal} onError={setError} handleClose={handleClose} />
        </Dialog>
    );
};
