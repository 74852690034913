import { useEffect } from "react";
import { games } from "src/games/config";
import useAppStore from "src/useAppStore";
import { CryptoCurrency } from "src/core/currency/currency.model";
import { errorToast } from "src/helpers/toast";
import { useGamesLocation } from "src/containers/Games/hooks/useGamesLocation";

export function useGameSelectedAssetCheck() {
    const { gameCode } = useGamesLocation();
    const selectedAsset = useAppStore(state => state.asset.selected);

    useEffect(() => {
        if (gameCode && games[gameCode]?.pgpDisabled) {
            if (selectedAsset === "PGP") {
                useAppStore.getState().setSelectedAsset(CryptoCurrency.BSV);
            }
            const unsubscribe = useAppStore.subscribe(
                selected => {
                    if (games[gameCode].pgpDisabled && selected === "PGP") {
                        useAppStore.getState().setSelectedAsset(CryptoCurrency.BSV);
                        errorToast(`${games[gameCode].label} does not support PGP payments`);
                    }
                },
                state => state.asset.selected,
            );
            return () => {
                unsubscribe();
                if (!games[gameCode].pgpDisabled) {
                    useAppStore.getState().setSelectedAsset(selectedAsset);
                }
            };
        }
    }, [gameCode, selectedAsset]);
}