import { CallbackFn, isSet } from "@ntropy/utils";
import { SetStateAction, Dispatch, useEffect, useCallback } from "react";
import { EventEmitter } from "eventemitter3";
import { handleStateAction } from "@ntropy/hooks/src/hooks-utils";
import { useForceUpdate } from "@ntropy/hooks/src/lifecycle/useForceUpdate";
import { usePrevious } from "@ntropy/hooks/src/changes/usePrevious";

class ObservableState {
    #eventEmitter = new EventEmitter();
    #state: Record<string, any> = {};

    setState = (key: string, setStateAction: SetStateAction<any>) => {
        this.#state[key] = handleStateAction(setStateAction, this.#state[key]);
        this.#eventEmitter.emit(key, this.#state[key]);
    }

    getState = (key: string) => this.#state[key];

    clearState = (key: string) => {
        delete this.#state[key];
    }

    on = (key: string, callback: CallbackFn<[any]>) => {
        this.#eventEmitter.on(key, callback);
    }

    off = (key: string, callback: CallbackFn<[any]>) => {
        this.#eventEmitter.off(key, callback);
    }

    count = (key: string) => this.#eventEmitter.listenerCount(key);
}

const observableState = new ObservableState();

export function useSingleState<S>(key: string, initial?: S): [S, Dispatch<SetStateAction<S>>] {
    let state: S = observableState.getState(key);

    if (state === undefined && initial !== undefined) {
        observableState.setState(key, initial);
        state = initial;
    }

    const forceUpdate = useForceUpdate();

    useEffect(() => {
        observableState.on(key, forceUpdate);

        return () => {
            observableState.off(key, forceUpdate);

            if (observableState.count(key) <= 0) {
                observableState.clearState(key);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatch: Dispatch<SetStateAction<S>> = useCallback((setStateAction: SetStateAction<S>) => {
        observableState.setState(key, setStateAction);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /// #if DEBUG
    const prevKey = usePrevious(key);

    useEffect(() => {
        if (isSet(prevKey) && prevKey !== key) {
            console.error("'key' should not change in useSingleState. First key is always used.", {prevKey, key});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key]);
    /// #endif

    return [state, dispatch];
}